.select-control.font-picker,
.select-control.weight-picker {
  &::before {
    background: $white;
    box-shadow: 0 0 0.5rem rgba($black,.05);
    border: none;
    transition: height 0.4s, box-shadow .15s linear;
  }

  &.open {
    &::before {
      box-shadow: 0 0 1rem rgba($black,.15);
      z-index: 1;
    }

    .font-picker--filter {
      opacity: 1;
      transition-delay: 0.4s;
    }
  }

  > span.selected,
  > button.selected{
    margin: 0;
    padding: 1em;
    height: auto;
    border: none;
    font-size: 0.75em;
    font-weight: normal;
    letter-spacing: normal;
    line-height: inherit;
    position: relative;
    width: 100%;
    text-align: left;
    cursor: pointer;

    .dd-icon {
      position: absolute;
      right: 0;
      top: 0;
      &:after {
        display: block;
        position: absolute;
        width: 1em;
        pointer-events: none;
        content: '\e5d7';
        right: 0.5em;
        top: 0.75em;
        font-size: 1.25em;
        font-family: 'tot-icons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &::after {
      right: 0.5em;
      top: 0.75em;
      font-size: 1.25em;
    }
  }

  > span[class="selected"],
  > button[class="selected"]{
    font-weight: 700;
  }

  .font-picker--filter {
    padding: .5em;
    opacity: 0;
    border-bottom: 1px solid $offWhite;
    transition: opacity 0.3s linear;

    input {
      width: 100%;
      border: 1px solid transparent;
      padding: .25em 1em;
      background: $offWhite;
      border-radius: 2em;
      font-size: 13px;
      outline: none;

      &:focus {
        border-color: $gray;
      }
    }
  }

  .dropdown {
    height: 16em;
    padding: 0;
  }

  ul.dropdown-options {
    li,
    button {
      border-color: $offWhite;
      width: 100%;
      display: block;
      text-align: left;

      &:hover {
        span {
          background: rgba($green, 0.15);
        }
      }

      &.is-current {
        span {
          color: $green;

          &::after {
            content: 'selected';
            display: block;
            position: absolute;
            top: 50%;
            right: 1.5em;
            padding: 0 .5em;
            border-radius: 2em;
            transform: translateY(-50%);
            font-weight: 700;
            font-size: 0.6875em;
            background: $green;
            color: $white;
          }
        }
      }

      span {
        transition: background 0.15s linear;
      }
    }
  }

  // system fonts
  .arial {
    font-family: 'Arial', sans-serif !important;
  }
  .courier {
    font-family: 'Courier', monospace !important;
  }
  .courier-new {
    font-family: 'Courier New', monospace !important;
  }
  .garamond {
    font-family: 'Garamond', serif !important;
  }
  .georgia {
    font-family: 'Georgia', serif !important;
  }
  .helvetica {
    font-family: 'Helvetica', sans-serif !important;
  }
  .times-new-roman {
    font-family: 'Times New Roman', serif !important;
  }
  .verdana {
    font-family: 'Verdana', sans-serif !important;
  }

  // google fonts
  .abel {
    font-family: 'Abel', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .abhaya-libre {
    font-family: 'Abhaya Libre', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .abril-fatface {
    font-family: 'Abril Fatface', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .alegreya {
    font-family: 'Alegreya', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .alfa-slab-one {
    font-family: 'Alfa Slab One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .amatic-sc {
    font-family: 'Amatic SC', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .amiri {
    font-family: 'Amiri', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .anonymous-pro {
    font-family: 'Anonymous Pro', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .archivo {
    font-family: 'Archivo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .archivo-narrow {
    font-family: 'Archivo Narrow', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .arimo {
    font-family: 'Arimo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .arsenal {
    font-family: 'Arsenal', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .arvo {
    font-family: 'Arvo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .asap {
    font-family: 'Asap', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .asap-condensed {
    font-family: 'Asap Condensed', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .assistant {
    font-family: 'Assistant', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .asul {
    font-family: 'Asul', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .barlow {
    font-family: 'Barlow', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .barlow-condensed {
    font-family: 'Barlow Condensed', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .bebas-neue {
    font-family: 'Bebas Neue', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .bellefair {
    font-family: 'Bellefair', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .bitter {
    font-family: 'Bitter', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .bungee {
    font-family: 'Bungee', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cabin {
    font-family: 'Cabin', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cabin-condensed {
    font-family: 'Cabin Condensed', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cardo {
    font-family: 'Cardo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .catamaran {
    font-family: 'Catamaran', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .caudex {
    font-family: 'Caudex', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .charm {
    font-family: 'Charm', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .chivo {
    font-family: 'Chivo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cinzel {
    font-family: 'Cinzel', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .corben {
    font-family: 'Corben', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cormorant-garamond {
    font-family: 'Cormorant Garamond', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .courier-prime {
    font-family: 'Courier Prime', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .crimson-pro {
    font-family: 'Crimson Pro', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .crimson-text {
    font-family: 'Crimson Text', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .cutive-mono {
    font-family: 'Cutive Mono', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .david-libre {
    font-family: 'David Libre', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .dm-sans {
    font-family: 'DM Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .dm-serif-display {
    font-family: 'DM Serif Display', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .dosis {
    font-family: 'Dosis', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .eb-garamond {
    font-family: 'EB Garamond', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .eczar {
    font-family: 'Eczar', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .exo {
    font-family: 'Exo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .fahkwang {
    font-family: 'Fahkwang', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .fanwood-text {
    font-family: 'Fanwood Text', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .fauna-one {
    font-family: 'Fauna One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .fjalla-one {
    font-family: 'Fjalla One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .gentium-basic {
    font-family: 'Gentium Basic', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .gentium-book-basic {
    font-family: 'Gentium Book Basic', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .germania-one {
    font-family: 'Germania One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .glegoo {
    font-family: 'Glegoo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .gothic-a1 {
    font-family: 'Gothic A1', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .gravitas-one {
    font-family: 'Gravitas One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .grenze {
    font-family: 'Grenze', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .heebo {
    font-family: 'Heebo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .josefin-sans {
    font-family: 'Josefin Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .josefin-slab {
    font-family: 'Josefin Slab', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .judson {
    font-family: 'Judson', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .julius-sans-one {
    font-family: 'Julius Sans One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .krub {
    font-family: 'Krub', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .kurale {
    font-family: 'Kurale', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .lato {
    font-family: 'Lato', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .libre-baskerville {
    font-family: 'Libre Baskerville', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .libre-franklin {
    font-family: 'Libre Franklin', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .literata {
    font-family: 'Literata', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .lora {
    font-family: 'Lora', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .m-plus-rounded-1c {
    font-family: 'M PLUS Rounded 1c', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .medula-one {
    font-family: 'Medula One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .megrim {
    font-family: 'Megrim', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .merriweather {
    font-family: 'Merriweather', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .merriweather-sans {
    font-family: 'Merriweather Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .metal-mania {
    font-family: 'Metal Mania', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .monda {
    font-family: 'Monda', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .montserrat {
    font-family: 'Montserrat', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .muli,
  .mulish{
    font-family: 'Mulish', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .neuton {
    font-family: 'Neuton', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .nixie-one {
    font-family: 'Nixie One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .noto-sans {
    font-family: 'Noto Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .noto-serif {
    font-family: 'Noto Serif', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .old-standard-tt {
    font-family: 'Old Standard TT', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .open-sans {
    font-family: 'Open Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .oswald {
    font-family: 'Oswald', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .overpass {
    font-family: 'Overpass', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .ovo {
    font-family: 'Ovo', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .pathway-gothic-one {
    font-family: 'Pathway Gothic One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .philosopher {
    font-family: 'Philosopher', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .pirata-one {
    font-family: 'Pirata One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .playfair-display {
    font-family: 'Playfair Display', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .poppins {
    font-family: 'Poppins', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .pragati-narrow {
    font-family: 'Pragati Narrow', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .prata {
    font-family: 'Prata', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .prompt {
    font-family: 'Prompt', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .pt-mono {
    font-family: 'PT Mono', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .pt-sans {
    font-family: 'PT Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .public-sans {
    font-family: 'Public Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .quando {
    font-family: 'Quando', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .quattrocento {
    font-family: 'Quattrocento', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .quattrocento-sans {
    font-family: 'Quattrocento Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .questrial {
    font-family: 'Questrial', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .quicksand {
    font-family: 'Quicksand', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .radley {
    font-family: 'Radley', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .rakkas {
    font-family: 'Rakkas', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .raleway {
    font-family: 'Raleway', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .red-hat-display {
    font-family: 'Red Hat Display', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .red-hat-text {
    font-family: 'Red Hat Text', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .roboto {
    font-family: 'Roboto', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .roboto-condensed {
    font-family: 'Roboto Condensed', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .roboto-slab {
    font-family: 'Roboto Slab', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .rosario {
    font-family: 'Rosario', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .rubik {
    font-family: 'Rubik', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .sansita {
    font-family: 'Sansita', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .slabo-13px {
    font-family: 'Slabo 13px', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .sorts-mill-goudy {
    font-family: 'Sorts Mill Goudy', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .source-code-pro {
    font-family: 'Source Code Pro', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .source-sans-pro {
    font-family: 'Source Sans Pro', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .space-mono {
    font-family: 'Space Mono', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .spectral {
    font-family: 'Spectral', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .staatliches {
    font-family: 'Staatliches', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .stardos-stencil {
    font-family: 'Stardos Stencil', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .teko {
    font-family: 'Teko', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .tenor-sans {
    font-family: 'Tenor Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .tomorrow {
    font-family: 'Tomorrow', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .ultra {
    font-family: 'Ultra', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .unica-one {
    font-family: 'Unica One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .unna {
    font-family: 'Unna', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .varela-round {
    font-family: 'Varela Round', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .vast-shadow {
    font-family: 'Vast Shadow', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .vesper-libre {
    font-family: 'Vesper Libre', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .vollkorn {
    font-family: 'Vollkorn', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .work-sans {
    font-family: 'Work Sans', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .yanone-kaffeesatz {
    font-family: 'Yanone Kaffeesatz', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
  .yeseva-one {
    font-family: 'Yeseva One', 'AvenirNextLTPro-Demi', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  // weights
  .is-100 {
    font-weight: 100 !important;
  }
  .is-200 {
    font-weight: 200 !important;
  }
  .is-300 {
    font-weight: 300 !important;
  }
  .is-400 {
    font-weight: 400 !important;
  }
  .is-500 {
    font-weight: 500 !important;
  }
  .is-600 {
    font-weight: 600 !important;
  }
  .is-700 {
    font-weight: 700 !important;
  }
  .is-800 {
    font-weight: 800 !important;
  }
  .is-900 {
    font-weight: 900 !important;
  }
}
