#partners-intro {
  background: $offWhite;
}

#partners-nav {
  position: relative;
  height: 2em;
  z-index: 1;

  ul {
    width: 100%;
    padding: 2em 0;
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    background: $white;
    border-bottom: 1px solid $offWhite;

    @media (min-width: $tablet-break) {
      flex-wrap: nowrap;
    }

    li {
      a {
        display: block;
        position: relative;
        margin: 0 0.25em;
        padding: 0.5em 1em;
        font-weight: normal;
        font-size: 0.75em;
        letter-spacing: 0.125em;
        text-transform: uppercase;
        text-decoration: none;
        color: $darkGray;
        border-radius: 6px;

        @media (min-width: $tablet-break) {
          margin: 0 0.75em;
        }

        @media (min-width: $large-desktop-break) {
          font-size: 0.875em;
        }
      }

      &.active {
        a {
          background: rgba($green, 0.15);
          color: $green;
        }
      }
    }
  }

  @media (min-width: $tablet-break) {
    ul {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &.stuck {
      ul {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 0 -2px 20px 0 rgba(45, 45, 45, 0.1);
      }
    }

    &.stuck-bottom {
      ul {
      }
    }
  }
}

#partners-sections {
  position: relative;
  z-index: 4;
  background: $white;
}

.partners-section {
  padding: 4rem 0;

  @media (min-width: $tablet-break) {
    padding: 8rem 0;
  }
}

.partners-feature {
  padding: 2rem 0;

  @media (min-width: $tablet-break) {
    padding: 0 3rem;
  }

  .thumbnail {
    max-width: 500px;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .feature-title {
    position: relative;
    margin-bottom: 10px;
    padding-left: 40px;

    &::before {
      counter-increment: section;
      content: counter(section);
      display: block;
      left: 0;
      top: 50%;
      position: absolute;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background: $lightGray;
      color: $white;
      border-radius: 50%;
      letter-spacing: 0;
      transform: translateY(-50%);
      margin-top: -1px;
    }
  }

  .feature-desc {
    margin-left: 40px;
  }
}

#sign-up {
  background: $offWhite;
}

.partners-form {
  position: relative;
  margin: 0 auto 40px;
  max-width: 400px;

  .partners-fields {
    transition: opacity 0.4s linear;
  }

  .partners-success-msg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px;
    text-align: center;
    background: $white;
    box-shadow: 0 0 0 2px rgba($black, 0.2);
    border-radius: 4px;
    z-index: 2;
    animation: 1.8s linear 0.3s both zoomIn;

    .success-icon {
      width: 40px;
      height: 40px;
      margin: 0 auto 20px;

      img {
        display: block;
        width: 40px;
        height: 40px;
      }
    }

    p {
      margin: 0;
    }
  }

  &.success {
    .partners-fields {
      opacity: 0;
    }

    .partners-success-msg {
      display: block;
    }
  }
}

#partners-agencies {
  padding-bottom: 2rem;

  .page-header {
    max-width: 60em;

    h1 {
      max-width: 27em;
      margin: 0 auto 0.5em;
    }
  }
}

#our-partners {
  padding: 9em 0 80px;
}

#partners-testimonials-list {
  margin-bottom: 4rem;
  text-align: center;

  @media (min-width: $tablet-break) {
    margin-bottom: 8rem;
  }

  .testimonial-logos {
    .logo-grid {
      position: relative;
      display: inline-block;

      &.regal img {
        width: 5em;
      }

      img {
        width: 9em;
      }
    }
  }
}

.partners-block {
  position: relative;
  padding: 2rem;
  text-align: center;

  .partners--wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 3rem 1.5rem;
    background: $white;
    border: 2px solid $green;
    border-radius: 8px;
    transition: all 0.2s linear;

    .partners-content {
      padding: 0 2rem 2rem;

      .show-bullets {
        text-align: left;
        margin: 3rem 0;
      }
    }

    .btn {
      margin-top: auto;
      white-space: normal;
    }
  }
}

.partners-row {
  display: block;
  position: relative;
  padding: 3rem;

  @media (min-width: $desktop-break) {
    display: flex;
    flex-direction: row;
  }

  .partners-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (min-width: $desktop-break) {
      max-width: 25em;
    }
  }
}

.partners-testimonials {
  background: $darkGray;
  color: $white;
}

.partners-testimonials .owl-item {
  padding: 2em;
  opacity: 0.4;
}

.partners-testimonials .owl-item.active.center {
  opacity: 1;
}

.testimonial-partner-content {
  margin: auto 0;
}

.partners-testimonial-item {
  display: block;
  position: relative;
  text-align: center;
  padding: 2rem 0 5rem;
  color: $white;

  @media (min-width: $desktop-break) {
    padding: 4rem 0 8rem;
  }

  p.hero {
    font-size: 1.125em;
    line-height: 1.625;

    @media (min-width: $tablet-break) {
      font-size: 2em;
      line-height: 1.75;
    }
  }

  .testimonial-image {
    position: relative;
    margin-bottom: 1rem;

    img {
      width: 5em;
      height: 5em;
      border-radius: 50%;
      margin: auto;
      object-fit: cover;
    }
  }

  .testimonial-partner-name,
  .testimonial-partner-title {
    margin: 0;
  }

  .testimonial-partner-name {
  }

  .testimonial-company {
    width: 8em;
    margin: auto auto 1em;
  }
}

#partner-sections {
  z-index: 0;
  position: relative;
}

#partner-types .form-item--control {
  border: 2px solid rgba(98, 98, 98, 0.5);
}

.partners-testimonials .owl-prev,
.partners-testimonials .owl-next {
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 2em;
  height: 2em;
  cursor: pointer;
  outline: none;
  opacity: 0.6;
  transition: opacity 0.15s linear, transform 0.1s ease;
  background: $white !important;
  border-radius: 50% !important;

  img {
    display: block;
    margin: 0 auto;
    width: 1em;
    height: auto;
  }

  &:hover {
    opacity: 1;
  }
}

.slider-counter {
  position: absolute;
  z-index: 2;
  bottom: 5rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: $white;
  pointer-events: none;

  span {
    color: $offWhite;
  }
}

.owl-carousel.partners-testimonials .owl-prev {
  margin-right: 5em !important;
}

.owl-theme .owl-nav {
  margin: 0;
  padding-bottom: 4rem;
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  [class*='owl-'] {
    margin: 5px;
    padding: 4px 7px;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
  }
}

.partner-item {
  border: 2px solid $green;
  border-radius: 4px;
  transition: all 0.2s linear;

  .partner-desc {
    padding: 2rem;
    text-align: left;

    @media (min-width: $tablet-break) {
      padding: 0 3rem;
    }
  }

  &:hover {
    border-color: $darkGray;

    .partner-title img {
      transform: translate3d(10px, 0, 0);
    }
  }

  .partner-title {
    padding: 2rem;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }
  }

  img {
    display: inline-block;
    height: 22px;
    margin-top: -5px;
    margin-left: 5px;
    transition: all 0.2s ease;
  }
}

.partners-action {
  padding: 3rem 0;
  text-align: center;
}
