.fade-top,
.fade-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  opacity: 1;
  z-index: 3;
  pointer-events: none;
  transition: all .2s .2s linear;
}

.fade-top {
  top: 0;
  background: linear-gradient(to top, rgba($offWhite,0) 0%, rgba($offWhite,0.75) 50%, rgba($offWhite,1) 100%);
  height: 150px;
}

.fade-bottom {
  bottom: 0;
  background: linear-gradient(to bottom, rgba($offWhite,0) 0%, rgba($offWhite,0.75) 50%, rgba($offWhite,1) 100%);
  height: 60px;
  z-index: 2;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-no-flexbox .slide {
  float: left;
}
.swiper-container-vertical > .slide-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.slide-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

.themes-gallery {
  position: relative;
  height: 100vh;
  width: 100%;
  background: $offWhite;

  .slide-wrapper {
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }

  .gallery-controls {
    display: none;
    position: absolute;
    right: 40px;
    top: 50%;
    width: 50px;
    text-align: center;
    transform: translate3d(100%, -50%, 0);
    z-index: 2;
    opacity: 0;
    transition: opacity .4s linear, transform .4s .4s linear;

    @media (min-width: $tablet-break) {
      display: block;
    }

    .hide-intro & {
      opacity: 1;
      transform: translate3d(0, -50%, 0);
      transition: opacity .4s linear, transform .6s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .gallery-prev,
    .gallery-next {
      display: block;
      position: relative;
      margin: 0 auto;
      padding: 0;
      width: 24px;
      height: 24px;
      cursor: pointer;
      outline: none;
      opacity: .8;
      transition: opacity .15s linear, transform .1s ease;

      &:active {
        transform: scale(.96);
      }

      &:hover {
        opacity: 1;
      }

      &.disabled {
        cursor: default;
        opacity: .2;
        transform: none;
      }

      img {
        display: block;
        width: 24px;
        height: 24px;
      }
    }

    .gallery-counter {
      margin: 15px 0;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .5px;
      color: rgba($gray, .4);

      span {
        color: rgba($gray, .8);
      }
    }
  }

  .slide {
    height: 100vh;
    opacity: .4;
    transition: opacity .4s linear;
    transition-delay: 0s;

    @media (min-width: $tablet-break) {
      height: 60vh;
    }

    &.intro {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      text-align: center;
      opacity: 0;

      .wrapper {
        max-width: 830px;
      }
    }

    &.slide-active {
      opacity: 1;
      transition-delay: .6s;

      .theme {
        .theme-screens {
          .screen {
            &.mobile {
              img {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                transition-delay: .6s;
              }
            }
          }
        }
        .theme-details {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: .8s;
        }
      }
    }
  }

  .theme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 100%;
    padding: 0 2rem;

    @media (min-width: $tablet-break) {
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding: 40px 130px 40px 40px;
    }

    .theme-screens {
      position: relative;
      width: auto;
      height: 100%;
      margin-right: 4rem;
      margin-bottom: 2rem;

      @media (min-width: $tablet-break) {
        margin: 0;
      }

      .screen {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        line-height: 1;

        &.desktop {
          img {
            box-shadow: 0 4px 40px rgba($black, .2);
            border-radius: 4px;
            overflow: hidden;
          }
        }

        &.mobile {
          position: absolute;
          top: 50%;
          transform: translate(50%,-50%);
          right: 0;
          height: 100%;
          width: auto;

          img {
            max-height: 80%;
            opacity: 0;
            transform: translate3d(0, 20px, 0);
            transition: all 450ms cubic-bezier(0.19, 1, 0.22, 1);
            transition-delay: 0s;
          }
        }
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        backface-visibility: hidden;
      }
    }

    .theme-details {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: all 450ms cubic-bezier(0.19, 1, 0.22, 1);
      transition-delay: 0s;

      @media (min-width: $tablet-break) {
        margin-left: 120px;
        width: 370px;
      }

      h1 {
        margin-top: 0;
      }

      p.secondary {
        font-size: 16px;
        line-height: 1.5;
        color: rgba($gray, .8);
      }

      .theme-actions {
        display: flex;
        margin: 0 -5px;

        .btn {
          margin: 0 5px;
        }
      }

      hr {
        width: 100%;
        border-radius: 2px;
        margin: 20px 0;
        transition: all .2s linear;
        background: rgba($lightGray, .4);
      }

      .theme-meta {
        font-size: 12px;
        letter-spacing: .5px;

        p {
          margin: 0;
          color: rgba($gray, .6);

          strong {
            color: $gray;
          }
        }
      }
    }
  }
}

#demo-window {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: $offWhite;

  .demo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: $darkGray;
    transform: translateY(-100px);
    z-index: 1;

    .demo-details {
      margin-left: 30px;

      h2 {
        position: relative;
        margin: 0;
        padding-left: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: .5px;
        text-transform: none;

        small {
          display: block;
        }

        &::before {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 2px;
          background: $green;
        }
      }

      a {
        color: rgba($lightGray, .8);
        transition: color .2s linear;

        &:hover {
          color: $white;
        }
      }
    }

    .demo-actions {
      display: flex;
      text-align: right;
      line-height: 0;
      margin: 0 -10px;
      padding: 0 30px;

      .btn {
        margin: 0 10px;
      }
    }
  }

  &.loading {
    padding: 0;

    &:after {
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
      width: 40px;
      height: 40px;
      border-color: rgba($gray, .4);
      border-top-color: $darkGray;
    }
  }
}


.demo-browser-frame {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;

  .ready & {
    opacity: 1;
    transition: opacity .4s linear;
  }
}


#demoIframe {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
