
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
  touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
  user-select: none;
-moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
}

.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement; */

.noUi-horizontal {
  height: 4px;
}

.noUi-vertical {
  width: 4px;
}

.noUi-horizontal .noUi-handle {
  margin-top: 2px;
}

.noUi-vertical .noUi-handle {
  margin-left: 2px;
}

/* Styling; */

.noUi-background {
  background: $lightGray;
}
.noUi-connect {
  background: $green;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 4px;
}
.noUi-target {
  border-radius: 4px;
}

/* Handles and cursors;
 */
.noUi-handle {
  position: relative;
  z-index: 2;
  width: 1.5em;
  height: 1.5em;
  left: -.75em;
  top: -.75em;
  border-radius: 50%;
  background: $darkGray;
  box-shadow: 0 0 .5rem rgba($black, .25);
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  transition: transform .2s ease-out;

  &:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
  }

  .noUi-origin[style*="left: 0%;"] &,
  .noUi-origin[style*="left: 100%;"] & {
    transform: scale(0.7);
  }
}

/* Handle stripes;
 */

.noUi-tooltip {
  display: block;
  position: absolute;
  background: $white;
  color: $darkGray;
  text-align: center;
  font-size: 1.125em;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: calc(100% + .15rem);
  padding: .25em;

  .noUi-origin[style*="left: 0%;"] &,
  .noUi-origin[style*="left: 100%;"] & {
    opacity: 0;
  }
}
