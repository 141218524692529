$global-guttering: 24px;
$global-font-size-h1: 32px;
$global-font-size-h2: 24px;
$global-font-size-h3: 20px;
$global-font-size-h4: 18px;
$global-font-size-h5: 16px;
$global-font-size-h6: 14px;

/*===============================
=            Choices            =
===============================*/

$choices-selector: 'choices' !default;
$choices-font-size-lg: 16px !default;
$choices-font-size-md: 14px !default;
$choices-font-size-sm: 12px !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2.5px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #f9f9f9 !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #ffffff !default;
$choices-text-color: #333333 !default;
$choices-keyline-color: #dddddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '../../icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;

.#{$choices-selector} {
  position: relative;
  margin-bottom: $choices-guttering;
  font-size: $choices-font-size-lg;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.is-disabled {
    .#{$choices-selector}__inner,
    .#{$choices-selector}__input {
      background-color: $choices-bg-color-disabled;
      cursor: not-allowed;
      user-select: none;
    }

    .#{$choices-selector}__item {
      cursor: not-allowed;
    }
  }
}

.#{$choices-selector}[data-type*='select-one'] {
  cursor: pointer;

  .#{$choices-selector}__inner {
    padding-bottom: 7.5px;
  }

  .#{$choices-selector}__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $choices-keyline-color;
    background-color: #ffffff;
    margin: 0;
  }

  .#{$choices-selector}__button {
    background-image: url($choices-button-icon-path+ '/cross-inverse.svg');
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px $choices-highlight-color;
    }
  }

  &:after {
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-color: $choices-text-color transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none;
  }

  &.is-open:after {
    border-color: transparent transparent $choices-text-color transparent;
    margin-top: -7.5px;
  }

  &[dir='rtl'] {
    &:after {
      left: 11.5px;
      right: auto;
    }

    .#{$choices-selector}__button {
      right: auto;
      left: 0;
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

.#{$choices-selector}[data-type*='select-multiple'],
.#{$choices-selector}[data-type*='text'] {
  .#{$choices-selector}__inner {
    cursor: text;
  }

  .#{$choices-selector}__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: -$choices-button-offset/2;
    margin-bottom: 0;
    margin-left: $choices-button-offset;
    padding-left: $choices-button-offset * 2;
    border-left: 1px solid darken($choices-primary-color, 10%);
    background-image: url($choices-button-icon-path+ '/cross.svg');
    background-size: $choices-button-dimension;
    width: $choices-button-dimension;
    line-height: 1;
    opacity: 0.75;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.#{$choices-selector}__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: $choices-bg-color;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid $choices-keyline-color;
  border-radius: $choices-border-radius;
  font-size: $choices-font-size-md;
  min-height: 44px;
  overflow: hidden;

  .is-focused &,
  .is-open & {
    border-color: $darkGray;
  }

  .is-open & {
  }

  .is-flipped.is-open & {
  }
}

.#{$choices-selector}__list {
  margin: -.25rem;
  padding-left: 0;
  list-style: none;
}

.#{$choices-selector}__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;

  [dir='rtl'] & {
    padding-right: 4px;
    padding-left: 16px;
  }

  .#{$choices-selector}__item {
    width: 100%;
  }
}

.#{$choices-selector}__list--multiple {
  display: inline;

  .#{$choices-selector}__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: $choices-border-radius-item;
    padding: 4px 10px;
    font-size: $choices-font-size-sm;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: $choices-primary-color;
    border: 1px solid darken($choices-primary-color, 5%);
    color: #ffffff;
    word-break: break-all;

    &[data-deletable] {
      padding-right: 5px;
    }

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 3.75px;
    }

    &.is-highlighted {
      background-color: darken($choices-primary-color, 5%);
      border: 1px solid darken($choices-primary-color, 10%);
    }

    .is-disabled & {
      background-color: darken($choices-disabled-color, 25%);
      border: 1px solid darken($choices-disabled-color, 35%);
    }
  }
}

.#{$choices-selector}__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: $choices-bg-color-dropdown;
  border: 1px solid $choices-keyline-color;
  top: 100%;
  margin: 0;
  border-bottom-left-radius: $choices-border-radius;
  border-bottom-right-radius: $choices-border-radius;
  overflow: hidden;
  word-break: break-all;

  &.is-active {
    display: block;
  }

  .is-open & {
    border-color: darken($choices-keyline-color, 15%);
  }

  .is-flipped & {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .#{$choices-selector}__list {
    position: relative;
    max-height: 8em;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  .#{$choices-selector}__item {
    position: relative;
    padding: .95em 1em;
    font-size: .75em;
    line-height: normal;
    border-bottom: 1px solid $offWhite;

    [dir='rtl'] & {
      text-align: right;
    }
  }

  .#{$choices-selector}__item--selectable {
    @media (min-width: 640px) {
      padding-right: 6em;

      &:after {
        position: absolute;
        content: attr(data-select-text);
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
        font-size: .675em;
        background: $lightGray;
        color: $darkGray;
        padding: 0.5em 1em;
        border-radius: 2em;
        opacity: 0;
      }
    }

    &.is-highlighted {
      background-color: mix(#000000, #ffffff, 5%);

      &::after {
        opacity: 1;
      }
    }
  }
}

.#{$choices-selector}__item {
  cursor: default;
}

.#{$choices-selector}__item--selectable {
  cursor: pointer;
}

.#{$choices-selector}__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.#{$choices-selector}__heading {
  font-weight: 600;
  font-size: $choices-font-size-sm;
  padding: 10px;
  border-bottom: 1px solid lighten($choices-keyline-color, 10%);
  color: lighten(#333, 30%);
}

.#{$choices-selector}__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.#{$choices-selector}__input {
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  margin: 0;
  padding: 0 .5em;

  &:focus {
    outline: 0;
  }

  [dir='rtl'] & {
    padding-right: 2px;
    padding-left: 0;
  }
}

.#{$choices-selector}__placeholder {
  opacity: 0.5;
}


.choices__inner {
  width: 100%;
  background: $offWhite;
  color: $darkGray;
  border: 2px solid rgba($gray, .5);
  border-radius: 6px;
  padding: .75em;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: .02em;
}

.choices__list--multiple .choices__item {
  position: relative;
  border: none;
  font-weight: normal;
  margin: .25rem;
  padding: .5em .5em .5em 1em;
  background: $darkGray;
  font-size: .75em;

  &.is-highlighted {
    border: none;
    background: transparent;
    color: $green;
  }
}

.choices[data-type*='select-multiple'] .choices__button, .choices[data-type*='text'] .choices__button {
  border: none;
  background: rgba($gray, .8);
  border-radius: 50%;
  width: 2em;
  height: 2em;
  padding: 0;
  margin: 0 0 0 1em;
  opacity: 1;

  &:hover {
    background: $gray;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45%;
    height: 1px;
    background: $white;
  }

  &::before {
    transform: translateX(-5px) translateY(-1px) rotate(45deg);
  }

  &::after {
    transform: translateX(-5px) translateY(-1px) rotate(-45deg);
  }
}

.choices__list--dropdown {
  border: 2px solid rgba($gray, .5);
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  z-index: 7;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: transparent;
  color: $green;
}
