*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: $circular-book;
}

body {
  font-size: 16px;
  line-height: 1.5;
  background: $white;
  color: $darkGray;
  font-weight: normal;

  &.login,
  &.register {
    height: auto;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: 2px solid $boulder;
}

.image-shadow {
  border-radius: 14px;
  box-shadow: 0 40px 40px 0 rgba(91,91,91,0.15);
}


.l-screen-reader,
.l-screen-reader-text,
.screen-reader-text,
.screen-reader {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  z-index: 99;
  font-size: .75rem;
  background-color: rgba($white, .75);
  color: black;
  padding: 4px;
  line-height: 1;
  font-family: $circular-bold;

  &.js-focus-visible,
  &.focus-visible {
    clip: unset;
  }
}

ul {
  list-style: none;
  margin: 0;

  li {

  }
}

::-moz-selection {
  color: $white;
  background: $green;
}
::selection {
  color: $white;
  background: $green;
}


.hide {
  display: none;
}

.tab {
  display: none;

  &.active {
    display: block;
  }
}
