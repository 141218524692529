.parked {
  background: $darkGray;
  
  #page-wrapper {
    background: $darkGray;
    height: 100%;
  }
}

.parked-watermark {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding-top: 30px;

  img {
    width: 50px;
    height: 50px;
  }
}

.parked-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;

  .coming-soon {
    animation: .8s linear 1s both fadeIn;

    h1 {
      color: $green;
    }
  }
}

#parked-domain {
  color: $offWhite;
  font-size: 80px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: .025em;
  animation: 1.4s cubic-bezier(0.19, 1, 0.22, 1) .4s both fadeInHero;
}