.chip {
  display: block;
  padding: 0 8px;
  text-align: center;
  font-size: 1.1249964843859863rem;
  background: #626262;
  color: #2d2d2d;
  border-radius: 4px;
  font-weight: normal;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  font-family: $circular-medium;

  &.chip-sm {
    margin-top: -1px;
    padding: 0 5px;
    font-size: 10px;
    line-height: 18px;
    height: 17px;
  }

  &.inline {
    display: inline-block;
    vertical-align: middle;
  }

  .day & {
    background: $lightGray;
    color: $white;
  }

  .night & {
    background: $gray;
    color: $darkGray;
  }

  &.primary {
    background: $green;
  }

  &.purple {
    background: #8d5ed6;
  }

  &.secondary {
    .day & {
      background: $darkGray;
    }

    .night & {
      background: $white;
    }
  }

  &.error {
    background: $errorColor;

    .day & {
      color: $white;
    }

    .night & {
      color: $darkGray;
    }
  }

  &.new {
    .day & {
      background: $offWhite;
      color: $gray;
    }
  }

  &--pending-review {
    color: $cc-ada-blue !important;
    background: $cc-light-blue !important;
  }

  &--approved {
    color: $success-green !important;
    background: $success-bg-green !important;
  }

  &--editing {
    color: $gray !important;
    background-color: $wild-sand !important;
  }

  &--taken-down {
    color: $error-red !important;
    background-color: $error-bg-red !important;
  }

  &--review-complete {
    color: $caution-yellow !important;
    background-color: $caution-bg-yellow !important;
  }
}
