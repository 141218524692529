.overlay-open,
.pull-out-open,
.pull-out-open body {
  overflow: hidden;
}

.pull-out-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  box-shadow: 2px 0 10px 0 rgba($black, .2);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $black;
    opacity: .5;
  }
}

#page-wrapper {
  position: relative;
  background: $white;

  .login &,
  .register & {
    background: none;
  }

  > section:first-of-type {
    padding-top: 12rem;

    @media (min-width: $tablet-break) {
      padding-top: 15rem;
    }
  }
}

.wrapper {
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  max-width: 1280px;

  @media (min-width: $tablet-break) {
    padding: 0;
    width: 80%;
  }

  &.narrow {
    max-width: 800px;
  }
}

.section {
  position: relative;
  padding: 2rem 0;

  &.pad-big {
    padding: 4rem 0;
  }

  &.flush-bottom {
    padding-bottom: 0;
  }

  &.edgeless {
    padding: 0;
  }

  &.alt {
    background: $offWhite;
  }

  &.dark {
    background: $darkGray;
    color: $white;
  }

  &.highlight {
    background: $green;
    color: $white;
  }

  &.has-split {
    @media (min-width: $tablet-break) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        background: $offWhite;
      }
    }
  }
}

.item-hide {
  opacity: 0;
  transition: opacity .4s linear;
}

.item-show {
  opacity: 1;
  transition: opacity .4s linear;
}
