.assist-intro {
  background: none;
  padding-bottom: 200px;

  &::before {
    display: none;
  }

  img {
    max-width: 500px;
    height: auto;
    margin: 5rem 0 2rem;
  }
}

.assist-preview {
  margin-top: -280px;
  z-index: 1;
  text-align: center;
  padding: 100px 0 !important;

  .preview-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0 2rem;
    width: 100%;

    @media (min-width: $tablet-break) {
      max-width: 70%;
      padding: 0;
    }
  }

  .preview-window {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 3rem rgba($black, .15);
    margin-bottom: 3rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  p.billboard {
    margin: 0 auto;
    max-width: 850px;

    @media (min-width: $tablet-break) {
      padding: 0 2rem;
      font-size: 24px;
    }
  }

  .preview-bg {
    background: $green url('/assets/img/ca-preview-bg.svg') 50% -1px no-repeat;
    background-size: 100% auto;
    display: block;
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}

.assist-feature {
  position: relative;
  padding-left: 90px;
  max-width: 420px;
  width: 100%;

  .assist-icon {
    display: block;
    position: absolute;
    left: 0;
    top: -28px;
    width: 60px;
    height: 60px;
  }

  p {
    margin: 0 0 4em;
  }
}

.assist-articles {
  margin-bottom: 4rem;

  @media (min-width: $tablet-break) {
    margin: 0;
    padding-left: 10rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.btn.download-ca-sample {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%,-50%,0);

  height: 60px;
  border-radius: 30px;
  line-height: 60px;
  padding: 0 40px;
  font-size: 1.2em;
}

.assist-how-to-header {
  margin-top: 4rem;
}

.assist-how-to {
  margin: 0;
  text-align: center;

  @media (min-width: $tablet-break) {
    margin: 0 -3rem;
  }

  .assist-feature {
    padding: 0 1rem;

    @media (min-width: $tablet-break) {
      padding: 0 3rem;
    }
  }

  .how-to-thumb {
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 0 0 3rem 0 rgba($black, .15);
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.unlock-tutorial {
  text-align: center;
}

.assist-faq {
  max-width: 800px;
  margin: 0 auto 20px;

  p.hero {
    margin: 50px 0 5px;
  }
}
