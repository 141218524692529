.table-length {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table {
  min-width: 100%;

  &.simple {
    border: 2px solid $lightGray;

    .day &,
    .night & {
      td,
      th {
        background: none;
        border: none;
        border-bottom: 2px solid $lightGray !important;
      }

      thead {
        td,
        th {
          background: $lightGray;
        }
      }
    }
  }

  &.sub-group {
    th,
    td {
      .night & {
        background: $darkGray !important;
        border-color: darken($darkGray, 5%) !important;
      }
    }
  }

  td,
  th {
    position: relative;
    border: 2px solid $lightGray;
    padding: 8px 10px;
    vertical-align: top;
    font-size: 0.875em;
    white-space: nowrap;

    .day & {
      border-color: $lightGray;
    }

    .night & {
      border-color: rgba($gray, 0.8);
    }

    .bleed & {
      &:first-child {
        padding-left: 20px;
        border-left: none;
      }

      &:last-child {
        padding-right: 20px;
        border-right: none;
      }
    }
  }

  th {
    text-align: left;

    &.actions {
      text-align: center;
    }
  }

  thead {
    td,
    th {
      font-weight: normal;

      .day & {
        background: rgba($lightGray, 0.5);
        color: $darkGray;
      }

      .night & {
        background: darken($darkGray, 5%);
        color: $white;
      }
    }
  }

  tbody {
    tr {
      transition: background 0.2s linear;

      &:hover {
        .day & {
          background: $offWhite;
        }

        .night & {
          background: darken($darkGray, 2%);
        }
      }
    }
  }

  td {
    border-bottom: 1px solid !important;

    .day & {
      background: rgba($lightGray, 0.5);
      border-color: $offWhite !important;
    }

    .night & {
      background: darken($darkGray, 5%);
      border-color: $darkGray !important;
    }
  }

  td.actions {
    text-align: right;
    white-space: nowrap;
    width: 1%;

    &.small {
      min-width: 100px;
    }

    .bleed & {
      padding-left: 20px;
    }

    .tot_dropdown {
      display: block;
    }

    .btn {
      vertical-align: middle;
    }

    .btn-action {
      font-size: 0.75em;
      width: 2.75em;
      height: 2.75em;
      padding: 0;
      line-height: 2.85;
      text-align: center;
      border-radius: 50%;
      margin: 0 auto;
      border: none;

      .day & {
      }

      .night & {
        background: rgba($gray, 0.6);
        color: darken($darkGray, 5%);
      }

      &:hover {
        background: $green;
      }
    }

    .open {
      .btn-action {
        background: $green;
      }
    }
  }

  td.middle {
    text-align: center;
    white-space: nowrap;
    .btn {
      vertical-align: middle;
    }
  }

  td.show-email {
    width: 1%;
    max-width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $green;
  }

  td.has-state {
    max-width: 10em;

    .chip {
      margin: 0.125em 0;
      padding: 0.5em 1em;
      border-radius: 2em;
      letter-spacing: normal;
      text-transform: none;
      font-size: 0.6875em;
      line-height: 1.5;
    }
  }

  td.has-date {
    font-weight: 700;
    font-size: 0.75em;
    color: $lightGray;
  }

  td.has-assignment {
    .is-select {
      min-width: 7em;
    }
  }

  .select-control {
    margin: 0;
  }
}

.bleed {
  margin: 0 -20px;
}

th.sorting::-moz-selection {
  background: transparent;
}
th.sorting::selection {
  background: transparent;
}

th.sorting,
th.sorting_desc,
th.sorting_asc {
  cursor: pointer;
  position: relative;
  outline: none;
}

th.sorting_desc,
th.sorting_asc {
  color: $green !important;
  border-bottom-color: $green !important;
}

th.sorting::-moz-selection,
th.sorting::selection {
  background: transparent;
}

table th {
  &.sorting,
  &.sorting_desc,
  &.sorting_asc {
    padding-left: 15px !important;
    padding-right: 27px !important;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      width: 0;
      height: 0;
      border: 4px solid transparent;
    }

    &::before {
      top: 50%;
      margin-top: -10px;

      .day & {
        border-bottom-color: rgba($gray, 0.4);
      }

      .night & {
        border-bottom-color: $gray;
      }
    }

    &::after {
      bottom: 50%;
      margin-bottom: -10px;

      .day & {
        border-top-color: rgba($gray, 0.4);
      }

      .night & {
        border-top-color: $gray;
      }
    }
  }
}

table th.sorting_desc::after {
  .day & {
    border-top-color: $darkGray;
  }

  .night & {
    border-top-color: $white;
  }
}

table th.sorting_asc::before {
  .day & {
    border-bottom-color: $darkGray;
  }

  .night & {
    border-bottom-color: $white;
  }
}

#advisors-list,
#advisorsList,
#content-list,
#content-list-admin,
#revisions-list,
#custom-content-list,
#officerReports {
  tbody {
    tr {
      .advisor-profile {
        transition: transform 0.1s ease, box-shadow 0.2s linear, margin 0.2s ease;
      }

      &:hover {
        > td {
          > .advisor-profile {
            box-shadow: 0 0 0 1px $green;

            // + span {
            //   transform: translateX(20px);
            // }
          }
        }
      }
    }

    > tr {
      &:hover {
        box-shadow: 2px 0 0 0 $green;
      }
    }
  }

  th,
  td {
    padding: 2rem 1rem;
    border: none;
  }

  th {
    background: #202020;
    border-left: 1px solid #2d2d2d;
    border-bottom: 1px solid #2d2d2d;

    &:first-child {
      border-radius: 8px 0 0 0;
      border-left: none;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
      border-right: none;
    }

    &.show-email {
      min-width: 240px;
    }

    &.has-broker {
      width: 100px;
      min-width: 100px;
    }

    &.show-date {
      width: 160px;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    .day & {
      border-color: $offWhite;
    }

    .night & {
      border-color: $darkGray;
    }
  }

  table {
    margin: 0 1rem;
    padding: 0;
    width: calc(100% - 2rem);
    min-width: 0;
  }
}

#content-list th,
#content-list-admin th,
#advisors-list th {
  position: sticky;
  top: 86px;
  z-index: 3;
}

td.content-thumb {
  width: 10%;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

td.content-title {
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: normal;
  white-space: normal;

  a.edit-content {
    display: block;
    text-decoration: none;
    color: $white;

    &:hover {
      color: $green;
    }
  }

  span.is-personas {
    display: block;
    margin-top: 0.5em;
    font-size: 0.6875em;
    font-weight: normal;
    line-height: 1.5;
    color: $lightGray;
  }
}

td.content-cat {
  font-weight: 700;
  color: $green;
}

td.content-channels {
  span {
    display: inline-block;
    margin: 0 0.25em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    line-height: 2.5;
    text-align: center;
    font-size: 0.875em;
    font-weight: normal;
    background: rgba($gray, 0.25);
    color: $gray;

    &.is-ca {
      background: rgba($green, 0.25);
      color: $green;
    }

    &.is-lp {
      background: rgba(#8d5ed6, 0.25);
      color: #8d5ed6;
    }
  }
}

.tot-table {
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid $gray;

  &--header {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    background: $gray;
    color: $white;
    font-size: 0.875em;
    font-weight: normal;
  }

  &--body {
    position: relative;
    overflow: hidden;
  }

  &--row {
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 0.5rem;
    border-bottom: 1px solid $offWhite;

    &:last-child {
      border-bottom: none;
    }
  }

  &--cell {
    position: relative;
    max-width: 100%;
    flex: 0 0 auto;
    flex-grow: 1;
    flex-basis: 0;
    padding: 1rem 0.5rem;
    text-align: center;
    overflow: hidden;
    z-index: 1;

    &.is-left {
      text-align: left;
    }

    &.is-fitted-10 {
      flex: 0 0 10rem;
      max-width: 10rem;
    }

    &.is-fitted-12 {
      flex: 0 0 12rem;
      max-width: 12rem;
    }

    &.is-fitted-15 {
      flex: 0 0 15rem;
      max-width: 15rem;
    }

    &.is-fitted-20 {
      flex: 0 0 20rem;
      max-width: 20rem;
    }
  }
}
