.explore-intro {
  padding-bottom: 120px;

  @media (min-width: $tablet-break) {
    padding-top: 150px !important;
    padding-bottom: 200px;
  }

  .page-header {
    max-width: 800px;
  }
}

.explore-app {
  margin-top: -200px;
  overflow: hidden;

  @media (min-width: $tablet-break) {
    padding-top: 6rem !important;
    margin-top: -330px;
  }
}

#app-preview {
  padding: 2rem;

  @media (min-width: $tablet-break) {
    padding: 4rem;
  }

  .browser {
    position: relative;
    margin: 0 auto;
    max-width: 800px;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) .4s both fadeInHero;
    backface-visibility: hidden;

    .base {
      position: relative;
      border-radius: 4px;
      background: $gray;
      box-shadow: 0 5px 30px rgba($black, .2);
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .hot-spots {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      animation: .5s linear 1s both fadeIn;

      @media (min-width: $tablet-break) {
        display: block;
      }

      .pin {
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $errorColor;
        box-shadow: 0 0 0 0 rgba($errorColor, .4);
        animation: pulseHot 2s infinite;

        span {
          display: block;
          position: absolute;
          left: -5px;
          top: -5px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid $errorColor;
        }
      }
    }
  }
}

@keyframes pulseHot {
  0% {
    box-shadow: 0 0 0 0 rgba($errorColor, .4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba($errorColor, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($errorColor, 0);
  }
}


.explore-screenshot {
  display: none;
  margin: 4rem 0;
  max-width: 600px;

  @media (min-width: $tablet-break) {
    display: block;
  }

  &.to-left {
    margin-right: auto;
  }

  &.to-right {
    margin-left: auto;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.customize-item {
  padding: 0 2rem;

  @media (min-width: $tablet-break) {
    display: flex;
    align-items: center;
    box-direction: normal;
    justify-content: center;
    flex-direction: row-reverse;
  }

  @media (min-width: $desktop-break) {
    padding: 0 4rem;
  }

  .desc {
    max-width: 340px;
    margin: 0 auto 2rem;
    padding: 0 2rem;
    text-align: center;

    @media (min-width: $tablet-break) {
      float: left;
      width: 65%;
      text-align: right;
    }

    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .thumbnail {
    margin: 0 auto;
    max-width: 300px;
    @media (min-width: $tablet-break) {
      float: left;
      width: 35%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.blog-thumbnail,
.archives-thumbnail {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.archives-thumbnail {
  max-width: 500px;
  margin: 0 auto;
}

.explore-feature {
  text-align: center;
  padding: 2rem;

  &--icon {
    width: 4em;
    height: 4em;
    margin: 0 auto 1rem;
    background: $offWhite;
    border-radius: 50%;
    font-size: .75em;
    padding: 1em;

    .feature-icon {
      color: rgba($gray, .8);
    }
  }
}

.feature-icon {
  color: $lightGray;
}

.publish-header {
  text-align: center;
  margin: 0 auto 6rem;
  padding: 0 3rem;
  max-width: 580px;
}

#step-overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  img {
    display: block;
    position: absolute;
    top: 30px; // account for browser bar
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity .2s .2s linear;

    &.active {
      opacity: 1;
      z-index: 2;
      transition: opacity .2s linear;
    }
  }
}

.publish-steps {
  margin: 0 auto;
  padding: 0 3rem;
  max-width: 500px;
  counter-reset: section;

  .step {
    display: block;
    opacity: 1;
    text-align: left;
    outline: none;
    transition: all .2s linear;

    @media (min-width: $tablet-break) {
      opacity: .4;
    }

    @media (min-width: $tablet-break) {
      &.active {
        opacity: 1;

        .step-title {
          &::before {
            background: $green;
          }
        }
      }
    }

    &:hover {
      opacity: 1;
    }

    .step-title {
      position: relative;
      margin-bottom: 10px;
      padding-left: 40px;

      &::before {
        counter-increment: section;
        content: counter(section);
        display: block;
        left: 0;
        top: 50%;
        position: absolute;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background: $lightGray;
        color: $white;
        border-radius: 50%;
        letter-spacing: 0;
        transform: translateY(-50%);
        margin-top: -1px;
      }
    }

    .step-desc {
      margin-left: 40px;
      color: rgba($gray, .8);
    }
  }
}
