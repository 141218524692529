.credit-logo {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  margin: 15px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  z-index: 2;
  animation: 0.4s linear 0.2s both fadeIn;

  @media (min-width: $tablet-break) {
    margin: 30px;
    width: 50px;
    height: 50px;
    margin-left: -25px;
  }

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
}

.scroll-spacer {
  position: relative;
  height: 100vh;
}

#providence-intro {
  position: relative;
  padding: 0 !important;
  height: 100vh;
  background: $offWhite;
}

.providence-intro-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $offWhite;
  text-align: center;
  z-index: 2;

  .pull-out-open & {
    position: absolute;
  }
}

.providence-logo-wrapper {
  width: 80%;
  max-width: 1400px;
}

.providence-logo {
  position: relative;
  width: 120px;
  margin: -80px auto 10px;
  animation: 1.8s linear 0.45s both zoomIn;
  transform-origin: bottom;

  @media (min-width: $tablet-break) {
    width: 280px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

#providence-eye {
  position: relative;
}

.providence-wordmark {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.4s both fadeInHero;

  svg {
    width: 100%;
    height: auto;
  }
}

.providence-hero {
  margin: 0 0 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.2;
  color: rgba($gray, 0.8);
  animation: 0.4s linear 0.5s both fadeIn;

  @media (min-width: $tablet-break) {
    margin: 0 0 30px;
    font-size: 28px;
  }
}

.providence-start {
  animation: 1.8s linear 1s both zoomIn;
}

#providence-process {
  position: relative;
  height: 100vh;
  z-index: 3;
  overflow: hidden;

  .providence-screen {
    display: none;
    position: fixed;
    top: 50%;
    height: 400px;
    margin-top: -200px;
    z-index: 3;
    cursor: pointer;

    @media (min-width: $tablet-break) {
      display: block;
    }

    @media (min-width: $desktop-break) {
      margin-top: -240px;
      height: 480px;
    }

    &.dashboard {
      left: auto !important;
      right: 100% !important;
      margin-right: 150px;

      &:hover {
        > img {
          transform: translate3d(20px, 0, 0);
        }

        .click-tip img {
          transform: translate3d(5px, 0, 0);
          transition-delay: 0.1s;
        }
      }

      @media (min-width: $large-desktop-break) {
        margin-right: 0;
      }

      .click-tip {
        left: 50%;
        margin-left: 150px;

        @media (min-width: $large-desktop-break) {
          margin-left: 0;
        }
      }
    }

    &.review {
      left: 100% !important;
      right: auto !important;
      margin-left: 150px;

      &:hover {
        > img {
          transform: translate3d(-20px, 0, 0);
        }

        .click-tip img {
          transform: translate3d(-5px, 0, 0);
          transition-delay: 0.1s;
        }
      }

      @media (min-width: $large-desktop-break) {
        margin-left: 0;
      }

      .click-tip {
        right: 50%;
        margin-right: 150px;

        @media (min-width: $large-desktop-break) {
          margin-right: 0;
        }
      }
    }

    .detatch-intro & {
      position: absolute;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 2px 12px rgba($black, 0.25);
      transition: all 0.2s ease;
    }

    .click-tip {
      position: absolute;
      top: 100%;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 2px;
      padding: 1rem 2rem;
      white-space: nowrap;

      img {
        width: 14px;
        display: inline-block;
        margin: -2px 3px 0;
        border-radius: 0;
        box-shadow: none;
        transition: all 0.1s ease;
      }
    }
  }

  .providence-process-content {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &.show {
      display: flex;
    }

    .detatch-intro & {
      position: absolute;
    }
  }

  .steps-wrapper {
    margin: 0 20px;

    @media (min-width: $tablet-break) {
      margin: 0 180px;
      padding-top: 10px; //accounts for icon pull
    }

    @media (min-width: $desktop-break) {
      margin: 0 auto;
      padding-top: 23px;
      max-width: 460px;
    }

    @media (min-width: 1270px) {
      margin: 0 420px;
    }
  }

  .step {
    position: relative;
    opacity: 0;

    .step-icon {
      position: absolute;
      top: -5px;
      left: 0;
      width: 40px;
      height: 40px;

      img {
        display: block;
        width: 40px;
        height: 40px;
      }

      @media (min-width: $tablet-break) {
        top: -8px;
        width: 50px;
        height: 50px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      @media (min-width: $desktop-break) {
        top: -23px;
        width: 80px;
        height: 80px;

        img {
          width: 80px;
          height: 80px;
        }
      }
    }

    .step-content {
      margin: 30px 0 30px 60px;

      @media (min-width: $tablet-break) {
        margin: 40px 0 40px 70px;
      }

      @media (min-width: $desktop-break) {
        margin: 40px 0 40px 100px;
      }

      p {
        margin: 0;
      }
    }
  }
}

#providence-sections {
  position: relative;
  z-index: 4;
  background: $white;
}

.providence-section {
  position: relative;
  padding: 50px 0;
  counter-reset: section;

  &.alt {
    background: $offWhite;
  }

  @media (min-width: $tablet-break) {
    padding: 80px 0;
  }
}

.providence-feature {
  padding: 2rem 0;

  @media (min-width: $tablet-break) {
    padding: 0 3rem;
  }

  .thumbnail {
    max-width: 500px;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .feature-title {
    position: relative;
    margin-bottom: 10px;
    padding-left: 40px;

    &::before {
      counter-increment: section;
      content: counter(section);
      display: block;
      left: 0;
      top: 50%;
      position: absolute;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background: $lightGray;
      color: $white;
      border-radius: 50%;
      letter-spacing: 0;
      transform: translateY(-50%);
      margin-top: -1px;
    }
  }

  .feature-desc {
    margin-left: 40px;
  }
}

#providence-request {
  background: $offWhite
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NiIgaGVpZ2h0PSIxMDAiPgoKPHBhdGggZD0iTTI4IDY2TDAgNTBMMCAxNkwyOCAwTDU2IDE2TDU2IDUwTDI4IDY2TDI4IDEwMCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDIyMCwyMjAsMjIyLDAuMjUpIiBzdHJva2Utd2lkdGg9IjIiPjwvcGF0aD4KPHBhdGggZD0iTTI4IDBMMjggMzRMMCA1MEwwIDg0TDI4IDEwMEw1NiA4NEw1NiA1MEwyOCAzNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDIyMCwyMjAsMjIyLDAuMjUpIiBzdHJva2Utd2lkdGg9IjIiPjwvcGF0aD4KPC9zdmc+")
    repeat;
}

.providence-request-form {
  position: relative;
  margin: 0 auto 40px;
  max-width: 400px;

  .request-fields {
    transition: opacity 0.4s linear;
  }

  .providence-success-msg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px;
    text-align: center;
    background: $white;
    box-shadow: 0 0 0 2px rgba($black, 0.2);
    border-radius: 4px;
    z-index: 2;
    animation: 1.8s linear 0.3s both zoomIn;

    .success-icon {
      width: 40px;
      height: 40px;
      margin: 0 auto 20px;

      img {
        display: block;
        width: 40px;
        height: 40px;
      }
    }

    p {
      margin: 0;
    }
  }

  &.success {
    .request-fields {
      opacity: 0;
    }

    .providence-success-msg {
      display: block;
    }
  }
}

#providence-firms {
  padding-bottom: 2rem;

  .page-header {
    max-width: 60em;

    h1 {
      max-width: 27em;
      margin: 0 auto 0.5em;
    }
  }
}

.providence-main-logos {
  margin: 0 auto;
  text-align: center;
  font-size: 0.75em;

  @media (min-width: $tablet-break) {
    font-size: 1em;
  }

  .logo-grid {
    position: relative;
    display: inline-block;

    &.united-planners img {
      width: 15em;
    }

    &.baird img {
      width: 10em;
    }
  }
}

.providence-logos .item {
  width: 10em;
}

.logo-grid {
  position: relative;
  display: block;
  margin: 1em 0.5em;

  img {
    display: inline-block;
    margin: auto;
    max-width: 100%;
    width: auto;
    height: auto;

    &.logo-big {
      padding: 0 1.5em;
      width: 16em;
    }

    &.logo-small {
      padding: 1em;
    }
  }
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}
