#footer {
  position: relative;
  background: $darkGray;
  color: $lightGray;
  padding: 5rem 0;
  overflow: hidden;
  z-index: 4;

  @media (min-width: $tablet-break) {
    padding: 8rem 0;
  }

  .footer--wrapper {
    padding: 0 1rem;

    @media (min-width: $tablet-break) {
      padding: 0 4rem;
    }
  }

  .footer-nav-wrapper {
    text-align: center;

    @media (min-width: $desktop-break) {
      text-align: right;
    }
  }

  ul.footer-nav {
    display: block;
    margin: 20px 0;
    padding: 0;
    list-style: none;
    vertical-align: top;
    text-align: center;

    @media (min-width: $tablet-break) {
      text-align: left;
      display: inline-block;
      margin: 0 30px;
    }

    @media (min-width: $desktop-break) {
      margin: 0 0 0 8%;
      font-size: 1em;
      line-height: 1.5;

      &:first-child {
        margin-left: 0;
      }
    }

    @media (min-width: $large-desktop-break) {
      margin: 0 2rem 0 8%;
    }

    h4 {
      font-size: 1em;
      color: $white;
      margin-bottom: 1em;
    }

    a {
      display: inline-block;
      padding: .5em 0;
      font-weight: 700;
      font-size: .875em;
      line-height: 1.5;
      color: rgba($lightGray, .8);
      transition: color .1s linear;

      .feature-icon {
        margin-left: 5px;
        font-size: .5em;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity .1s linear, transform .2s ease;
      }

      &:hover {
        color: $white;

        .feature-icon {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  .tagline-wrapper {
    text-align: center;
    margin-bottom: 3rem;

    @media (min-width: $desktop-break) {
      margin: 0;
      text-align: left;
    }
  }

  .tot-tagline {
    font-size: 4.5vw;
    color: $white;
    line-height: 1.25;

    @media (min-width: $desktop-break) {
      font-size: 2.75vw;
    }

    .accented {
      color: $green;
    }
  }
}

#legal {
  position: relative;
  padding: 2rem;
  background: darken($darkGray, 5%);
  color: $gray;
  text-align: center;
  z-index: 9;

  @media (min-width: $tablet-break) {
    text-align: left;
    padding: 4rem;
  }

  .social-links {
    margin-bottom: 1rem;
  }

  p.copyright {
    margin: 0;
    font-size: .75em;
    font-weight: 700;
    line-height: 1.85;

    br {
      @media (min-width: 375px) {
        display: none;
      }
    }
  }
}

.social-links {

  @media (min-width: $tablet-break) {
    text-align: right;
    padding-right: 60px;
    margin: 8px 0;
  }

  a {
    display: inline-block;
    margin: 0 5px;
    color: $lightGray;
    font-size: 15px;
    line-height: normal;
    text-align: center;
    transition: all .2s linear;

    &:hover {
      color: $green;
    }
  }
}
