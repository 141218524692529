@keyframes dropAnimation {
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 30, 0, 1); opacity: 0; }
  3.2% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 17.493, 0, 1); }
  6.31% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10.348, 0, 1); }
  9.41% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 6.107, 0, 1); }
  12.51% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 3.595, 0, 1); }
  18.82% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1.216, 0, 1); opacity: 1; }
  25.03% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.414, 0, 1); }
  50.05% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.005, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}






.tot_dropdown {
  display: inline-block;
  position: relative;
  line-height: 0;
  z-index: 2;

  .tot_droplist {
    display: block;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    top: 100%;
    left: -9999px;
    text-align: left;
    transform: translateX(-50%);
    z-index: 9;

    ul {
      position: relative;
      margin-top: 10px;
      padding: 6px 0;
      border-radius: 6px;
      transition: all .2s linear;
      box-shadow: 0 0 2rem rgba($black,.2);

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 100%;
        margin-left: -5px;
        background: transparent;
        border: 5px solid transparent;
        transition: all .2s linear;

        .day & {
          border-bottom-color: $darkGray;
        }

        .night & {
          border-bottom-color: $white;
        }
      }

      .day & {
        background: $darkGray;

        a {
          color: rgba($lightGray, .8);

          &:hover {
            // background: rgba($gray, .2);
            color: $white;
          }
        }
      }

      .night & {
        background: $white;
        box-shadow: 0 0 2rem rgba($black,.4);

        a {
          color: rgba($gray, .8);

          &:hover {
            // background: $offWhite;
            color: $darkGray;
          }
        }
      }
    }

    li {
      a {
        display: block;
        min-width: 145px;
        padding: 5px 15px;
        background: transparent;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: .05em;
        white-space: nowrap;
        transition: all .2s linear;
        font-family: $circular-book;

        &.delete-user {
          color: $errorColor !important;
        }
      }
    }
  }

  &.open {
    z-index: 3;

    .tot_droplist {
      left: 50%;

      &.left {
        margin-left: 1.25em;
        left: 0;
      }

      &.right {
        margin-left: -1.25em;
        left: 100%;
      }

      &.is-far-right {
        left: auto;
        transform: none;
        right: 0;

        ul::before {
          left: auto;
          right: 1.125em;
        }
      }

      ul {
        animation: dropAnimation 1000ms linear both;
      }
    }
  }
}
