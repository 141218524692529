// Button Colors
$btn-primary: $green;
$btn-inactive: rgba($gray, .4);

// Dark Button Colors (default)
$btn-dark-default: $gray;
$btn-dark-secondary: $darkGray;

// Light Button Colors
$btn-light-default: rgba($lightGray, .8);
$btn-light-secondary: $white;

.input-btn-group {
  position: relative;

  .form-control {
    &:focus + .btn {
      visibility: visible;
      opacity: 1;
    }
  }

  input.form-control:focus {
    padding-right: 76px;
  }

  .btn {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 8px;
    min-width: 64px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear;
  }

  .code-editor + .btn {
    margin: 10px;
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}


.btn-group {
  line-height: 0;

  &.is-vertical {
    .btn {
      margin: .5rem 0;
    }
  }

  .btn {
    margin: 5px;

    @media (min-width: $tablet-break) {
      margin: 0 5px;
    }
  }

  span.pipe {
    display: inline-block;
    position: relative;
    margin: 5px 0;
    vertical-align: top;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;

    .day & {
      color: rgba($gray, .4);
    }

    .night & {
      color: $gray;
    }

    @media (min-width: $tablet-break) {
      margin: 0 5px;
    }

    &:before {
      content: '|';
    }
  }
}

.btn-toggle {
  display: flex;
  flex-direction: row;

  .btn {
    flex-grow: 1;
    flex-basis: 1;
    border-radius: 0;
    background: $lightGray;
    color: $gray;
    transform: none !important;

    &.active {
      background: $gray;
      color: $white;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}

.btn.sort {
  padding-right: 15px;

  &::after {
    content: '';
    position: relative;
    right: -5px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
  }

  &.asc,
  &.desc {
    .day & {
      background: $btn-dark-default;
      color: $white;
    }

    .night & {
      background: $btn-light-default;
      color: $darkGray;
    }
  }

  &.asc {
    &::after {
      top: 9px;
      border-bottom: none;
      border-top-color: $white;
    }
  }

  &.desc {
    &::after {
      top: -8px;
      border-top: none;
      border-bottom-color: $white;
    }
  }
}


/*----- Default Button Styles -----*/

.btn {
  // base
  display: inline-block;
  position: relative;
  margin: 0;
  padding: .5em 1em;
  cursor: pointer;
  border-radius: 6px;
  user-select: none;
  appearance: none;

  // text
  font-weight: normal;
  font-size: .8125em;
  line-height: 1.5;
  letter-spacing: .025em;
  text-align: center;
  white-space: nowrap;
  transition: all .15s linear, transform .1s ease;

  &.has-icon {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    color: $gray;
  }

  // focus
  &:focus {
    outline: none;
  }

  // active
  &:active {
    transform: scale(.96);
  }

  // tipped
  &.tot_tip {
    overflow: visible;
    vertical-align: top;
  }

  background: $gray;
  color: $white;

  // colors: dark
  .night & {
    background: $lightGray;
    color: $darkGray;
  }

  // colors: white
  &.is-white {
    background: $white;
    color: $darkGray;
  }

  // colors: primary
  &.primary {
    background: $green;
    color: $white;

    .night & {
      color: $darkGray;
    }
  }

  &.fancy {
    background-color: #007D9E;
    z-index: 0;
    text-shadow: 0 0 6px rgba(15,15,16,0.55);

    &:before {
      z-index: -2;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      background: $default-primary-gradient;
      border-radius: inherit;
    }
  }

  &.insurmark {
    background: #FAB812;
  }

  // colors: secondary
  &.secondary {
    .day & {
      background: $btn-dark-secondary;
      color: $white;

      &[data-cover] {
        background: $btn-dark-secondary;
        color: $white;
      }
    }

    .night & {
      background: $btn-light-secondary;
      color: $darkGray;

      &[data-cover] {
        background: $btn-light-secondary;
        color: $darkGray;
      }
    }
  }

  // colors: danger
  &.danger {
    background: $error-bg-red;
    color: $error-red;
  }

  // colors: danger light
  &.danger-light {
    background: $error-bg-red;
    color: $error-red;

    &:hover {
      background: darken($error-bg-red, 10%);
      color: $error-red;
    }
  }

  &.pill-light {
    background: $lightGray;
    color: $tundora;

    &:hover {
      background: $gray;
      color: $white;
    }
  }

  // colors: inactive
  &.inactive {
    .day & {
      background: $btn-inactive !important;
      color: $lightGray !important;
    }

    .night & {
      background: $btn-inactive !important;
      color: $darkGray !important;
    }
  }
}


/*----- Pill Button Styles -----*/

.pill {
  border-radius: 2em;
}


/*----- Bordered Button Styles -----*/

.bordered {
  padding: calc(.5em - 2px) 1em;

  // colors: default
  .day &,
  .day-mode .tot-alert & {
    background: none;
    border: 2px solid $btn-dark-default;
    color: $btn-dark-default;

    &[data-cover] {
      background: none !important;
      color: $btn-dark-default;
    }

    &:before {
      background: $btn-dark-default;
      color: $white;
    }

    &:hover,
    &.active,
    &.loading {
      background: $btn-dark-default;
      color: $white;
    }
  }

  .night &,
  .night-mode .tot-alert & {
    background: none;
    border: 2px solid $btn-light-default;
    color: $btn-light-default;

    &[data-cover] {
      background: none !important;
      color: $btn-light-default;
    }

    &:before {
      background: $btn-light-default;
      color: $darkGray;
    }

    &:hover,
    &.active,
    &.loading {
      background: $btn-light-default;
      color: $darkGray;
    }
  }

  // colors: primary
  &.primary {
    .day & {
      background: none;
      border-color: $btn-primary;
      color: $btn-primary;

      // fancy
      &[data-cover] { color: $btn-primary; }
      &:before { background: $btn-primary; }

      &:hover,
      &.loading {
        background: $btn-primary;
        color: $white;
      }
    }

    .night & {
      background: none;
      border-color: $btn-primary;
      color: $btn-primary;

      &[data-cover] { color: $btn-primary; }
      &:before { background: $btn-primary; }

      &:hover,
      &.loading {
        background: $btn-primary;
        color: $darkGray;
      }
    }
  }

  // colors: fancy
  &.fancy {
    .day & {
      background: none;
      border-color: $green;
      border-image: linear-gradient(120deg, #08AEEA 0%, $green 100%);
      color: $btn-primary;

      // fancy

      &:hover,
      &.loading {
        background: $green;
        background: linear-gradient(120deg, #08AEEA 0%, $green 100%);
        color: $white;
      }
    }

    .night & {
      background: none;
      border-color: $green;
      border-image: linear-gradient(120deg, #08AEEA 0%, $green 100%);
      color: $btn-primary;

      &:hover,
      &.loading {
        background: $green;
        background: linear-gradient(120deg, #08AEEA 0%, $green 100%);
        color: $darkGray;
      }
    }
  }

  // colors: secondary
  &.secondary {
    .day & {
      background: none;
      border-color: $btn-dark-secondary;
      color: $btn-dark-secondary;

      // fancy
      &[data-cover] { color: $btn-dark-secondary; }
      &:before { background: $btn-dark-secondary; }

      &:hover,
      &.loading {
        background: $darkGray;
        color: $white;
      }
    }

    .night & {
      background: none;
      border-color: $btn-light-secondary;
      color: $btn-light-secondary;

      &[data-cover] { color: $btn-light-secondary; }
      &:before { background: $btn-light-secondary; }

      &:hover,
      &.loading {
        background: $btn-light-secondary;
        color: $darkGray;
      }
    }
  }

  // colors: danger
  &.danger {
    .day & {
      background: none;
      border-color: $error-red;
      color: $error-red;

      // fancy
      &[data-cover] { color: $error-red; }
      &:before { background: $error-red; }

      &:hover,
      &.loading {
        background: $error-bg-red;
        color: $error-red;
      }
    }

    .night & {
      background: none;
      border-color: $error-red;
      color: $error-red;

      &[data-cover] { color: $error-red; }
      &:before { background: $error-red; }

      &:hover,
      &.loading {
        background: $error-bg-red;
        color: $error-red;
      }
    }
  }

  // colors: inactive
  &.inactive {
    .day &,
    .night & {
      background: none !important;
      border-color: $btn-inactive !important;
      color: $btn-inactive !important;
    }
  }
}


/*----- Inactive Button Styles -----*/
.inactive {
  cursor: not-allowed;
  pointer-events: none;
}


/*----- Loading Button Styles -----*/

@include keyframes(spin) {
  from {
    @include prefix(transform, rotate(0deg));
  }
  to {
    @include prefix(transform, rotate(360deg));
  }
}

.loading {
  color: transparent !important;
  text-shadow: none !important;
  // padding-left: 31px;

  &:after {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    border: 2px solid;
    border-radius: 50%;
    @include animation(spin .6s linear infinite);
    border-color: rgba($white, .4);
    border-top-color: $white;
  }

  .night & {
    &:after {
      border-color: rgba($darkGray, .4);
      border-top-color: $darkGray;
    }
  }

  // fancy
  &[data-cover] {
    &:before {
      display: none;
    }
  }

  &.primary {
    .day & {
      &:after {
        border-color: rgba($gray, .4);
        border-top-color: $white;
      }

      &[data-cover] {
        background: $btn-primary !important;
        color: $white !important;
      }
    }

    .night & {
      &:after {
        border-color: rgba($gray, .4);
        border-top-color: $darkGray;
      }

      &[data-cover] {
        background: $btn-primary !important;
        color: $darkGray !important;
      }
    }
  }

  &.secondary {
    .day & {
      &:after {
        border-color: $gray;
        border-top-color: $white;
      }

      &[data-cover] {
        background: $btn-dark-secondary !important;
        color: $white !important;
      }
    }

    .night & {
      &:after {
        border-color: rgba($gray, .4);
        border-top-color: $darkGray;
      }

      &[data-cover] {
        background: $btn-light-secondary !important;
        color: $darkGray !important;
      }
    }
  }
}


/*----- Text Button Styles -----*/

.btn-text,
.is-text {
  padding-left: 0;
  padding-right: 0;
  background: none !important;
  color: $btn-dark-default;

  &.danger {
    color: $error-red;
  }

  &:hover {
    color: $darkGray;
  }

  &.loading {
    &::after {
      border-color: $lightGray;
      border-top-color: $darkGray;
    }
  }

  .night & {
    color: $btn-light-default;

    &.danger {
      color: $error-red;
    }

    &:hover {
      color: $white;
    }
  }
}


/*----- Button Sizes -----*/
.btn-sm {
  font-size: .6875em;
  letter-spacing: .025em;
  padding: .455em 1em;
  border-radius: 4px;
  line-height: 1.1;

  &.bordered {
    border-width: 1px;
    padding: calc(.455em - 2px) 1em;
  }

  &.loading {
    &:after {
      border-width: 1px;
    }
  }
}

.btn-lg {
  font-size: 1em;
  padding: .875em 1.75em;

  &.bordered {
    padding: calc(.875em - 2px) 1.25em;
  }

  &.pill {
    border-radius: 3em;
  }

  &:hover {
    &.pill,
    &.bordered {
      &[data-cover] {
        &:before {
          margin-top: 0;
        }
      }
    }
  }

  // fancy
  &[data-cover] {
    &:before {
      margin: -40px -30px 0;
    }
  }

  &.loading {
    &:after {
      width: 1.5em;
      height: 1.5em;
      margin-top: -.75em;
      margin-left: -.75em;
    }
  }
}

.btn-xl {
  // base
  height: 60px;
  padding: 0 40px;

  font-size: 1.2em;
  line-height: 60px;

  &.pill {
    border-radius: 30px;
  }
}

.btn-icon,
.btn-photo {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: 0;
  width: 2.4625em;
  height: 2.4625em;
  backface-visibility: hidden;

  &.right {
    margin-left: 1em;
  }

  &.left {
    margin-right: 1em;
  }

  .btn-photo,
  &.is-photo {
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.btn-lg {
    width: 40px;
    height: 40px;
    padding: 0;

    .icon {
      font-size: 22px;
      line-height: 40px;
    }
  }
}

.btn-icon {
  .icon {
    font-size: 28px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

/*----- New TOT BTN Styles -----*/

.btn {

  &--primary {
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    border: none;
    color: $white;
    text-shadow: 0 0 6px rgba(15, 15, 16, .55);
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &:after {
      z-index: -1;
      top: 2px;
      left: 2px;
      content: '';
      opacity: 0;
      transition: opacity .3s ease;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      position: absolute;
      background-color: $white;
      border-radius: 2px;
    }

    &:hover {
      color: $mine-shaft;
      text-shadow: none;

      &:after {
        opacity: 1;
      }
    }
  }

  &--default {

    background-color: #007D9E;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &:before {
      z-index: -2;
      top: 0;
      left: 0;
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      background: $default-primary-gradient;
    }
  }

  &--we {
    background-color: $we-dark-green;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &:before {
      z-index: -1;
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $we-primary-gradient;
    }
  }

  &--secondary {
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    background-color: $white;
    border: 2px solid darken($wild-sand, 10%);
    color: $tundora;
    transition: color .3s ease, background-color .3s ease;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &.selected {
      background-color: $wild-sand;
      border-color: darken($wild-sand, 15%);
    }

    &:hover {
      color: $mine-shaft;
    }

    &::after,
    &::before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      transition: .3s;
    }

    &::after {
      top: -2px;
      left: -2px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-radius: 4px;
    }

    &::before {
      bottom: -2px;
      right: -2px;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
      border-radius: 4px;
    }


    &:hover::after,
    &:hover::before {
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-color: darken($wild-sand, 15%);
    }
  }

  &--tertiary {
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
    transition: background-color .3s ease;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &:hover {
      background-color: rgba($mine-shaft, .2);
    }
  }

  &--large {
    height: 55px;
    font-size: 1.6874947265789793rem;
    line-height: 55px;
    padding: 0 20px;
    text-align: center;
    min-width: 95px;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);
  }

  &--small {
    height: 40px;
    font-size: 1.3124958984503172rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    text-align: center;
    min-width: 95px;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

  }

  &--prev,
  &--next,
  &--down {
    height: 60px;
    width: 60px;
    background-color: $white;
    border-radius: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);
  }

  &--prev,
  &--next {
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    img {
      height: 18px;
    }
  }

  &--mobile-menu {
    height: 35px;
    width: 40px;
    background-color: transparent;
    padding: 0;
    border: none;
    display: inline-block;
    justify-content: center;
    box-shadow: none;

  }

  &--close {
    height: 40px;
    width: 40px;
    background-color: $white;
    border-radius: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    img {
      transform: rotate(45deg);
    }
  }

  &--loading {
    color: transparent;
    text-shadow: none;
    min-width: 95px;
    box-shadow: 0 4px 30px 0 rgba(45, 45, 45, .05);

    &:after {
      opacity: 1;
      background-color: transparent;
      background-image: url('/assets/img/loading.svg');
      background-size: 30px auto;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      color: transparent;
      text-shadow: none;
    }
  }

  &--pill {
    border-radius: 30px;
    padding: 8px 12px;
    font-size: 1.3124958984503172rem;
    font-family: $circular-medium;
    line-height: 1;
  }

  &--outlined {
    border: 2px solid $tundora;
    color: $tundora;
    background-color: transparent;
    padding: 6px 12px;
    transition: background .3s ease, color .3s ease;

    &:hover {
      color: $white;
      background-color: $tundora;
    }

  }

  &--pill-we {
    color: $white;
    background-color: $we-dark-green;
    transition: background .3s ease;

    &:hover {
      background-color: lighten($we-dark-green, 5%);
    }

  }

  &--pill-fancy {
    position: relative;
    color: $white;
    background-color: $we-dark-green;
    text-shadow: 0 0 6px rgba(15, 15, 16, 0.55);
    transition: background .3s ease, color .3s ease, text-shadow .3s ease;
    z-index: 0;

    &:before {
      border-radius: 30px;
      z-index: -1;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: $we-primary-gradient;
    }

    &:after {
      border-radius: 30px;
      z-index: -1;
      content: '';
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      top: 2px;
      left: 2px;
      position: absolute;
      background: $white;
      opacity: 0;
      transition: opacity .3s ease;
    }

    &:hover {
      background-color: darken($we-dark-green, 5%);
      text-shadow: none;
      color: $tundora;

      &:after {
        opacity: 1;
      }
    }
  }

  &--dark {
    background-color: $tundora;
    color: $white;
    transition: background .3s ease;

    &:hover {
      background-color: darken($tundora, 5%);
    }
  }

  &--light {
    background-color: $wild-sand;
    color: $tundora;
    transition: background .3s ease;

    &:hover {
      background-color: darken($wild-sand, 5%);
    }
  }

  &--sm {
    font-size: .6875em;
    letter-spacing: .025em;
    padding: .455em 1em;
    border-radius: 4px;
    line-height: 1.1;
    background-color: $boulder;
    color: $white;

    &:hover {
      background-color: darken($boulder, 5%);
    }

    &.bordered {
      border-width: 1px;
      padding: calc(.455em - 2px) 1em;
    }

    &.loading {
      &:after {
        border-width: 1px;
      }
    }
  }

  &--danger {
    background-color: $error-bg-red;
    color: $error-red;
    transition: background .3s ease;

    &:hover {
      background-color: darken($error-bg-red, 5%);
    }
  }

  &--caution {
    background-color: $caution-bg-yellow;
    color: $caution-yellow;
    transition: background .3s ease;

    &:hover {
      background-color: darken($caution-bg-yellow, 5%);
    }
  }

  &--has-icon {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    color: $tundora;
  }

  &--icon,
  &--photo {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 0;
    width: 2.4625em;
    height: 2.4625em;
    backface-visibility: hidden;

    &.right {
      margin-left: 1em;
    }

    &.left {
      margin-right: 1em;
    }

    .btn-photo,
    &.is-photo {
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &.btn-lg {
      width: 40px;
      height: 40px;
      padding: 0;

      .icon {
        font-size: 22px;
        line-height: 40px;
      }
    }
  }

  // actions
  &--action-review,
  &.btn.primary.btn--action-review {
    letter-spacing: normal;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    border: 2px solid $cc-dark-blue;
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease, background-color .3s ease;
    box-shadow: none;
    background-color: $cc-dark-blue;
    color: $white;
    font-size: 1.3124958984503172rem;

    &:hover {
      background-color: $white;
      color: $mine-shaft;
    }
  }

  &--action-default {
    letter-spacing: normal;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    border: 2px solid $wild-sand;
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease, background-color .3s ease, border .3s ease;
    box-shadow: none;
    background-color: $wild-sand;
    color: $gray;
    font-size: 1.3124958984503172rem;

    &:hover {
      border: 2px solid $gray;
      background-color: $white;
      color: $tundora;
    }
  }

  &--action-default-outlined {
    letter-spacing: normal;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    border: 2px solid $gray;
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease, background-color .3s ease;
    box-shadow: none;
    background-color: $wild-sand;
    color: $gray;
    font-size: 1.3124958984503172rem;

    &:hover {
      background-color: $white;
      color: $mine-shaft;
    }
  }

  &--action-reject {
    letter-spacing: normal;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    border: 2px solid $error-red;
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease, background-color .3s ease;
    box-shadow: none;
    background-color: $error-red;
    color: $white;
    font-size: 1.3124958984503172rem;

    &:hover {
      background-color: white;
      color: $error-red;
    }
  }

  &--action-approve {
    letter-spacing: normal;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    border: 2px solid $we-ada-green;
    overflow: hidden;
    z-index: 0;
    transition: color .3s ease, text-shadow .3s ease, background-color .3s ease;
    box-shadow: none;
    background-color: $we-ada-green;
    color: $white;
    font-size: 1.3124958984503172rem;

    &:hover {
      background-color: $white;
      color: $we-ada-green;
    }
  }
}


/*----- Button Container New TOT -----*/

.btn-container {
  margin-top: 40px;
  display: flex;

  * {
    &:nth-child(2):not(ul):not(li) {
      margin-left: 24px;

    }

    &:nth-child(3):not(ul):not(li) {
      margin-left: 24px;

    }

    &:nth-child(4):not(ul):not(li) {
      margin-left: 24px;

    }

    &:nth-child(5):not(ul):not(li) {
      margin-left: 24px;

    }

    &:nth-child(6):not(ul):not(li) {
      margin-left: 24px;

    }
  }

  &--centered {
    align-items: center;
    justify-content: center;
  }

  &--jump-to {
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      margin-top: 20px;

    }

    * {
      &:nth-child(2):not(ul):not(li) {
        margin-left: 0;
      }

      &:nth-child(3):not(ul):not(li) {
        margin-left: 0;
      }

      &:nth-child(4):not(ul):not(li) {
        margin-left: 0;
      }
    }
  }
}
