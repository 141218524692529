.toggle-reveal {
  .reveal-icon {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $green;
    position: absolute;
    top: 1px;
    left: -30px;
    opacity: 0;
    transform: scale(.5);
    transition: opacity .2s linear, transform .3s ease;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: $green;
    }

    &::before {
      width: 8px;
      height: 1px;
    }

    &::after {
      width: 1px;
      height: 8px;
    }
  }

  &:hover {
    .reveal-icon {
      opacity: 1;
      transform: none;
    }
  }

  &.open {
    .reveal-icon {
      &::after {
        display: none;
      }
    }
  }
}

.reveal {
  display: none;
}