// Global Headers
h1 {
  margin: 0 0 0.5em;
  font-weight: normal;
  font-size: 1.875em;
  line-height: 1.2;
  color: $darkGray;
  font-family: $circular-black;

  &.hero {
    @media (min-width: $tablet-break) {
      font-size: 2.25em;
    }

    @media (min-width: $desktop-break) {
      font-size: 2.85em;
    }

    @media (min-width: $large-desktop-break) {
      font-size: 3.25em;
    }

    br {
      display: none;

      @media (min-width: $large-desktop-break) {
        display: block;
      }
    }
  }

  &.alt {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 3px;

    @media (min-width: $tablet-break) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 4px;
    }
  }

  .night & {
    color: $white;
  }

  &.green,
  &.is-highlighted,
  span.is-highlighted {
    color: $green;
  }
}

h2,
h3,
h4 {
  .day & {
    color: $darkGray;
    font-family: $circular-bold;
  }

  .night & {
    color: $white;
  }
}

// Back Specific Headers
.app,
.providence {
  h1 {
    margin: 0.5em 0;
    font-weight: normal;
    letter-spacing: normal;
    font-size: 1.5em;
    line-height: 1.5;

    &.is-hero {
      font-weight: normal;
      font-size: 3.25em;
      line-height: 1.2;
    }
  }

  h2 {
    margin: 0 0 0.5em;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.2;

    > .btn {
      margin-left: 0.75rem;
      vertical-align: middle;
    }

    > .tot_dropdown {
      margin-left: 0.75rem;
      vertical-align: middle;
      line-height: 10px;
    }

    small {
      letter-spacing: 0.2px;

      &.danger {
        color: $errorColor;
      }
    }
  }

  h3 {
    margin: 20px 0;
    font-size: 0.6875em;
    line-height: 1.1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $gray;
    font-weight: normal;

    &.section-title {
      background: rgba($lightGray, 0.5);
      padding: 1.5rem 2rem;
    }

    &.sub-section-title {
      margin-bottom: 5px;
    }

    &.is-subsection {
      margin: 0 0 0.75em;
      font-weight: normal;
      font-size: 0.875em;
      line-height: 1.2;
      text-transform: none;
      letter-spacing: normal;
    }
  }

  h4 {
    font-weight: normal;
    font-size: 1em;
    line-height: 1.2;

    a {
      color: $green;
    }
  }

  .day {
    h3 {
      color: $gray;
    }
  }

  .night {
    h3 {
      color: $lightGray;
    }
  }
}
.terms-home {
  width: 60%;
  font-weight: normal;
  font-size: 0.8em !important;
  line-height: 1.5em;
  text-align: center;
  padding: 1.5em 0 0 0;
  margin: 0 auto;
  a {
    color: #626262;
    border-bottom: 2px solid rgba(98,98,98,0.4);
  }
}
.terms {
  font-size: 0.8em;
  line-height: 1.5em;
  text-align: center;
  border-top: 1px solid $wild-sand;
  padding: 1.5em 0 0 0;
  margin: 1.5em 0 0 0;
  a {
    color: $tundora;
    text-decoration: underline;
  }
}
// Front Specific Headers
.front {
  h2 {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 1.5;

    &.is-h1-hero {
      margin: 0 0 0.5em;
      font-weight: normal;
      font-size: 2.5em;
      line-height: 1.2;

      @media (min-width: $desktop-break) {
        font-size: 2.85em;
      }

      @media (min-width: $large-desktop-break) {
        font-size: 3.25em;
      }

      br {
        display: none;

        @media (min-width: $large-desktop-break) {
          display: block;
        }
      }
    }

    small {
      letter-spacing: 0.2px;
    }
  }

  h3 {
    margin: 20px 0 5px;
    font-size: 13px;
    line-height: 1.1;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: normal;
  }

  h4 {
    margin: 0 0 0.5em;
    font-size: 1.2em;
    line-height: 1.5;
    font-weight: normal;

    &.text-center {
      text-align: center;
    }
  }
}

// Paragraphs

p {
  margin: 0 0 1.5em;

  &.is-setting-desc {
    margin: -1rem 15% 2em 0;
    font-size: 0.875em;
    font-weight: normal;
    color: $gray;
  }

  &.hero {
    font-size: 18px;
    line-height: 22px;

    br {
      display: none;
    }

    @media (min-width: $tablet-break) {
      font-size: 20px;
      line-height: 28px;

      br {
        display: block;
      }
    }
  }

  &.hero-big,
  &.is-hero {
    font-size: 1.25em;
    line-height: 1.5;

    br {
      display: none;
    }

    @media (min-width: $tablet-break) {
      font-size: 1.5em;

      br {
        display: block;
      }
    }

    .footnote {
      font-size: 13px;
      font-weight: 300;
      position: relative;
      top: -0.5em;
      left: 0.1em;
      color: $green;
    }
  }

  &.billboard {
    font-weight: 300;
    font-size: 25px;
    line-height: 1.35;

    br {
      display: none;
    }

    @media (min-width: $tablet-break) {
      font-size: 32px;

      br {
        display: block;
      }
    }
  }

  &.body {
    color: $tundora;
    font-size: 13px;
    margin: 0 12px 2px;
  }

  .day & {
    &.secondary {
      color: $tundora;
    }

    &.disabled {
      color: rgba($gray, 0.4);
    }
  }

  .night & {
    color: $white;

    &.secondary {
      color: rgba($lightGray, 0.8);
    }

    &.disabled {
      color: $gray;
    }
  }

  &.danger {
    color: $errorColor;
  }

  //- ALIGNMENT
  &.text-center {
    text-align: center;
  }

  .btn {
    vertical-align: middle;
  }
}

ul {
  &.secondary {
    color: $tundora;
  }

  &.show-bullets {
    li {
      position: relative;
      margin-left: 10px;
      margin-bottom: 10px;
      padding-left: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 2px;
        width: 4px;
        height: 9px;
        border: solid $green;
        border-width: 0 1px 1px 0;
        transform: rotate(40deg);
      }
    }
  }
}

a {
  color: inherit;
}

p,
ul,
ol {
  a {
    color: $gray;
    text-decoration: none;
  }

  a.inline {
    position: relative;
    font-weight: 400;
    border-bottom: 2px solid rgba($gray, 0.4);
    transition: all 0.1s linear;

    &:hover {
      border-color: $green;
    }

    .day & {
      color: $darkGray;
    }

    .night & {
      color: $white;
    }
  }
}

strong,
b {
  font-weight: normal;
  font-family: $circular-bold;
}



hr {
  display: block;
  border: none;
  margin: 15px 0;
  width: 30px;
  height: 2px;

  .day & {
    background: $darkGray;
  }

  .night & {
    background: $white;
  }

  &.center {
    margin: 15px auto;
  }

  &.full {
    width: 100%;
    margin: 40px auto;

    .day & {
      background: rgba($lightGray, 0.4);
    }

    .night & {
      background: rgba($gray, 0.4);
    }
  }

  @media (min-width: $tablet-break) {
    &.mobile-only {
      display: none;
    }
  }
}

code {
  font-weight: normal;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 85%;

  .day & {
    background: rgba($gray, 0.6);
    color: $white;
  }

  .night & {
    background: $lightGray;
    color: $darkGray;
  }
}

.green {
  color: $green;
}

.hero--tagline {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.5;
  color: $green;

  &::before {
    content: "";
    display: block;
    width: 2.5em;
    height: 1px;
    margin-right: 0.75em;
    background: $green;
  }
}

.heading-small-caps {
  display: block;
  color: $boulder;
  text-transform: uppercase;
  font-size: 1.4999953125146483rem;
  letter-spacing: 0.09374970703216552rem;
  line-height: 1.8749941406433104rem;
}
