/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.
 *
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * twentyoverten.com (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "CircularXXWeb-Thin";
  src: url("/assets/fonts/CircularXXWeb-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Thin";
  src: url("/assets/fonts/CircularXXWeb-Thin.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-ThinItalic";
  src: url("/assets/fonts/CircularXXWeb-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-ThinItalic";
  src: url("/assets/fonts/CircularXXWeb-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Light";
  src: url("/assets/fonts/CircularXXWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Light";
  src: url("/assets/fonts/CircularXXWeb-Light.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-LightItalic";
  src: url("/assets/fonts/CircularXXWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-LightItalic";
  src: url("/assets/fonts/CircularXXWeb-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Regular";
  src: url("/assets/fonts/CircularXXWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Regular";
  src: url("/assets/fonts/CircularXXWeb-Regular.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Italic";
  src: url("/assets/fonts/CircularXXWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Italic";
  src: url("/assets/fonts/CircularXXWeb-Italic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("/assets/fonts/CircularXXWeb-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Book";
  src: url("/assets/fonts/CircularXXWeb-Book.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "CircularXXWeb-BookItalic";
  src: url("/assets/fonts/CircularXXWeb-BookItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-BookItalic";
  src: url("/assets/fonts/CircularXXWeb-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("/assets/fonts/CircularXXWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("/assets/fonts/CircularXXWeb-Medium.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-MediumItalic";
  src: url("/assets/fonts/CircularXXWeb-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-MediumItalic";
  src: url("/assets/fonts/CircularXXWeb-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("/assets/fonts/CircularXXWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Bold";
  src: url("/assets/fonts/CircularXXWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-BoldItalic";
  src: url("/assets/fonts/CircularXXWeb-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-BoldItalic";
  src: url("/assets/fonts/CircularXXWeb-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-Black";
  src: url("/assets/fonts/CircularXXWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-Black";
  src: url("/assets/fonts/CircularXXWeb-Black.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-BlackItalic";
  src: url("/assets/fonts/CircularXXWeb-BlackItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-BlackItalic";
  src: url("/assets/fonts/CircularXXWeb-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-ExtraBlack";
  src: url("/assets/fonts/CircularXXWeb-ExtraBlack.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-ExtraBlack";
  src: url("/assets/fonts/CircularXXWeb-ExtraBlack.woff") format("woff");
}

@font-face {
  font-family: "CircularXXWeb-ExtraBlackItalic";
  src: url("/assets/fonts/CircularXXWeb-ExtraBlackItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXWeb-ExtraBlackItalic";
  src: url("/assets/fonts/CircularXXWeb-ExtraBlackItalic.woff") format("woff");
}


/* subset */

@font-face {
  font-family: "CircularXXSub-ThinSubset";
  src: url("/assets/fonts/CircularXXSub-ThinSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-ThinSubset";
  src: url("/assets/fonts/CircularXXSub-ThinSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-ThinItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ThinItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-ThinItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ThinItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-LightSubset";
  src: url("/assets/fonts/CircularXXSub-LightSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-LightSubset";
  src: url("/assets/fonts/CircularXXSub-LightSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-LightItalicSubset";
  src: url("/assets/fonts/CircularXXSub-LightItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-LightItalicSubset";
  src: url("/assets/fonts/CircularXXSub-LightItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-RegularSubset";
  src: url("/assets/fonts/CircularXXSub-RegularSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-RegularSubset";
  src: url("/assets/fonts/CircularXXSub-RegularSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-ItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-ItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BookSubset";
  src: url("/assets/fonts/CircularXXSub-BookSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BookSubset";
  src: url("/assets/fonts/CircularXXSub-BookSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BookItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BookItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BookItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BookItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-MediumSubset";
  src: url("/assets/fonts/CircularXXSub-MediumSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-MediumSubset";
  src: url("/assets/fonts/CircularXXSub-MediumSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-MediumItalicSubset";
  src: url("/assets/fonts/CircularXXSub-MediumItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-MediumItalicSubset";
  src: url("/assets/fonts/CircularXXSub-MediumItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BoldSubset";
  src: url("/assets/fonts/CircularXXSub-BoldSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BoldSubset";
  src: url("/assets/fonts/CircularXXSub-BoldSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BoldItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BoldItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BoldItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BoldItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BlackSubset";
  src: url("/assets/fonts/CircularXXSub-BlackSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BlackSubset";
  src: url("/assets/fonts/CircularXXSub-BlackSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-BlackItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BlackItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-BlackItalicSubset";
  src: url("/assets/fonts/CircularXXSub-BlackItalicSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-ExtraBlackSubset";
  src: url("/assets/fonts/CircularXXSub-ExtraBlackSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-ExtraBlackSubset";
  src: url("/assets/fonts/CircularXXSub-ExtraBlackSubset.woff") format("woff");
}

@font-face {
  font-family: "CircularXXSub-ExtraBlackItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ExtraBlackItalicSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularXXSub-ExtraBlackItalicSubset";
  src: url("/assets/fonts/CircularXXSub-ExtraBlackItalicSubset.woff") format("woff");
}

// Base Colors
$black: #000;
$darkGray: #2d2d2d;
$gray: #626262;
$lightGray: #dcdcde;
$offWhite: #fbfbfb;
$white: #fff;

// new  colors
$cod-gray: #232323;
$mine-shaft: #2d2d2d;
$tundora: #4b4b4b;
$dove-gray: #666666;
$boulder: #757575;
$wild-sand: #f4f4f4;
$alabaster: #fcfcfc;
$white: #ffffff;

// gradients
$default-primary-gradient: linear-gradient(129.42deg, #08aeea 0%, #19c18b 100%);
$default-secondary-gradient: linear-gradient(0deg, #fbfbfb 0%, #ffffff 100%);
$default-tertiary-gradient: linear-gradient(180deg, #f7f7f7 0%, #fdfdfd 100%);

$we-primary-gradient: linear-gradient(335.46deg, #04483f 0%, #19db9d 100%);
$we-secondary-gradient: linear-gradient(179.82deg, #ffffff 0%, #cae9df 100%);

$cc-primary-gradient: linear-gradient(324.87deg, #013a4f 0%, #08aeea 100%);
$cc-secondary-gradient: linear-gradient(180deg, #ffffff 0%, #d1e3e9 100%);

$lp-primary-gradient: linear-gradient(321.56deg, #271667 0%, #6743c0 100%);

$lp-secondary-gradient: linear-gradient(180deg, #ffffff 0%, #dfd5f8 100%);

// colors products
$we-brand-green: #19c18b;
$we-dark-green: #022e26;

// for use of green on light background
$we-ada-green: #007750;

$cc-brand-blue: #08aeea;
$cc-dark-blue: #03182e;
$cc-light-blue: #e0f8ff;

// for use of blue on light background
$cc-ada-blue: #0078A3;

$lp-brand-purple: #6743c0;
$lp-dark-purple: #271667;

// old
$errorColor: #e74c3c;
$successColor: #27C169; //66b34e
$success: #0AE471;
$green: $we-brand-green;

// alerts
$error-red: #C20000;
$error-bg-red: #F8E5E5;

$success-green: #007750;
$success-bg-green: #d6f7ec;

$caution-yellow: #836900;
$caution-bg-yellow: #F9F0C9;


// typography
$circular-book: 'CircularXXWeb-Book', Helvetica, Arial, sans-serif;
$circular-book-italic: 'CircularXXWeb-BookItalic', Helvetica, Arial, sans-serif;

$circular-medium: 'CircularXXWeb-Medium', Helvetica, Arial, sans-serif;
$circular-medium-italic: 'CircularXXWeb-MediumItalic', Helvetica, Arial, sans-serif;

$circular-bold: 'CircularXXWeb-Bold', Helvetica, Arial, sans-serif;
$circular-bold-italic: 'CircularXXWeb-BoldItalic', Helvetica, Arial, sans-serif;

$circular-black: 'CircularXXWeb-Black', Helvetica, Arial, sans-serif;
$circular-black-italic: 'CircularXXWeb-BlackItalic', Helvetica, Arial, sans-serif;

// shadows
$box-shadow-primary: 0 40px 40px 0 rgba(91, 91, 91, .15);
$box-shadow-secondary: 0 10px 30px 0 rgba(105, 105, 105, .15);
$box-shadow-tertiary: 0 10px 50px 0 rgba(105, 105, 105, .15);

// border-radius
$border-radius-primary: 14px;
$border-radius-secondary: 10px;

// base
$base-min-width: 100%;
$base-font-family: $circular-book;
$base-font-color: $tundora;
$base-background-color: $white;
$base-border-color: $wild-sand;
$base-border-focus-color: $dove-gray;

// Breakpoints
$mobile-break-max: 767px;
$tablet-break: 768px;
$desktop-break: 980px;
$large-desktop-break: 1150px;
