#header {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transform: translate(0,0);
  transition: transform .4s, height .3s, background .4s;

  .home & {
    animation: .4s linear .2s both fadeIn;
  }

  @media (min-width: $desktop-break) {
    &.invisible {
      transform: translate(0,-80px);
      transition: transform .2s;
    }

    &.detached {
      position: fixed;
      background: $white;
      box-shadow: 0 -2px 20px 0 rgba(45,45,45,.1);
      transition: transform .3s, height .3s, background .4s;
    }
  }

  .header-inner {
    position: relative;
  }

  .alert-banner {
    background: #8c5ed6;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: max-height 0s ease, padding 0s ease;
    &.hide-banner {
      max-height: 0;
      padding: 0;
      transition: max-height .5s ease, padding .3s ease;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      color: #ffffff;
      line-height: 1.25;
      @media (min-width: $desktop-break) {
        font-size: 16px;
        line-height: 40px;
      }
      .btn {
        margin-left: 10px;
        background: linear-gradient(-35deg, #3a2482 0%, #6743c0 100%, #8d5ed6 100%);
        color: #ffffff;
        font-size: 14px;
        @media (min-width: $desktop-break) {
          font-size: 16px;
        }
      }
    }
    &__close {
      height: 24px;
      width: 24px;
      position: absolute;
      right: 8px;
      top: 12px;
      cursor: pointer;
      transform: rotate(45deg);
      max-height: 100px;
      @media (min-width: $desktop-break) {
        right: 10px;
        top: 15px;
      }

      span {
        height: 2px;
        width: 100%;
        background: #ffffff;
        display: block;
        position: absolute;top: 50%;
        transform: translateY(-50%);
        &:nth-of-type(2) {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}

a.logo {
  display: block;
  position: relative;
  float: left;
  margin: 16px;
  width: 160px;
  height: 40px;
  font-size: 25px;
  line-height: 1;
  z-index: 2;

  &.special {
    width: 180px;
  }

  @media (min-width: $desktop-break) {
    margin: 0 3rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 1;

    @media (min-width: $desktop-break) {
      transition: opacity .3s linear;
    }

    &.inverted {
      opacity: 0;
    }
  }

  .night & {
    img {
      opacity: 0;

      &.inverted {
        opacity: 1;
      }
    }
  }
}

.login-nav,
.account-nav {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0 2.25rem;
  z-index: 2;

  @media (min-width: $desktop-break) {
    display: flex;
    align-items: center;
  }

  .btn {
    margin: 0 .75rem;
  }
}

#navigation {
  display: none;
  position: relative;
  width: 100%;
  margin: 4rem 0;
  padding: 0 17em;
  text-align: center;
  z-index: 1;

  @media (min-width: $desktop-break) {
    display: block;
  }

  ul.main-nav {
    li {
      display: inline-block;

      a {
        display: block;
        position: relative;
        margin: 0 .75em;
        padding: .25em 0;
        font-weight: normal;
        font-size: .75em;
        letter-spacing: .125em;
        text-transform: uppercase;
        text-decoration: none;
        color: $darkGray;

        @media (min-width: $large-desktop-break) {
          font-size: .875em;
        }

        @media (min-width: $desktop-break) {
          &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $green;
            background: linear-gradient(120deg, #08aeea 0%, $green 100%);
            opacity: 0;
            transform: scaleX(0);
            transform-origin: 100% 0;
            transition: transform .25s ease, opacity .1s .15s linear;
          }
        }

        &:hover {
          color: $darkGray;

          &:after {
            opacity: 1;
            transform: scaleX(1);
            transform-origin: 0 0;
            transition: transform .25s ease, opacity .1s linear;
          }
        }

        .night & {
          color: $white;

          &:hover {
            color: $white;
          }
        }

        span.show-xl {
          display: none;

          @media (min-width: $large-desktop-break) {
            display: inline;
          }
        }
      }

      &.active {
        a {
          &:after {
            transform: scaleX(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

#pull-out-nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 260px;
  height: 100%;
  background: darken($darkGray, 5%);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;

  .wrapper {
    padding: 5em 0;
  }

  .close-menu {
    span {
      background: $white;
    }
  }

  a.btn {
    margin-bottom: 10px;
  }

  ul.main-nav {
    margin-top: 2em;

    a {
      display: block;
      padding: 4px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      text-align: right;
    }
  }

  .social-links {
    margin-top: 2em;
    padding-right: 0;
    text-align: right;
  }
}

.pull-out-open #pull-out-nav {
  display: block;
}

.menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  margin: 20px;
  z-index: 9;
  cursor: pointer;

  @media (min-width: $desktop-break) {
    display: none;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    border-radius: 2px;

    .day & {
      background: $darkGray;
    }

    .night & {
      background: $white;
    }

    &:nth-child(1) {
      top: 6px;
    }

    &:nth-child(2) {
      top: 13px;
    }

    &:nth-child(3) {
      top: 20px;
    }
  }
}

.close-menu,
.close-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  margin: 20px;
  z-index: 9;
  cursor: pointer;

  &:hover {
    span {
      background: $green !important;
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    left: 0;
    border-radius: 2px;
    transition: background .2s linear;

    .day & {
      background: $darkGray;
    }

    .night & {
      background: $white;
    }


    &:nth-child(1) {
      top: 13px;
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      top: 13px;
      transform: rotate(-45deg);
    }
  }
}
