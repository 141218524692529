.account-setup {
  &__info {
    padding: 4rem;
    height: 100vh;
    background-color: #2d2d2d;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 640px);
    @media (max-width: 1040px) {
      position: relative;
      height: auto;
      width: 100%;
      padding: 2rem;
    }

    &__logo {
      display: block;
      position: relative;
      width: 4em;
      height: 4em;
      @media (max-width: 1040px) {
        width: 2.5em;
        height: 2.5em;
      }
    }

    h1 {
      color: $white;
      font-weight: normal;
      font-size: 3.25em;
      line-height: 1.2;
      margin: 26px 0;
      @media (max-width: 1040px) {
        font-size: 1.875em;
      }
    }

    p {
      color: $white;
      font-size: 1.5em;
      @media (max-width: 1040px) {
        font-size: 1.25em;
      }
    }
  }

  &__billing {
    margin-left: calc(100vw - 640px);
    width: 640px;
    background-color: $white;
    padding: 0 2rem 4rem;
    color: $black;
    @media (max-width: 1040px) {
      position: relative;
      height: auto;
      width: 100%;
      margin: 0;
    }

    &__section-title {
      margin: 3rem 0;
      background: rgba(220, 220, 222, .5);
      padding: 1.5rem 2rem;
      font-size: .6875em;
      line-height: 1.1;
      letter-spacing: .1em;
      text-transform: uppercase;
      color: #626262;
      font-weight: normal;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__plan {
      position: relative;
      margin: 0 auto;
      padding: 2.25rem;
      overflow: hidden;
      border-radius: 8px;
      background: #f6f6f7;
      border: 2px solid rgba(98, 98, 98, .5);
      font-size: 1em;
      max-width: 100%;
      text-align: center;
      transition: background .15s linear;
      @media (max-width: 480px) {
        padding: 1rem;
      }

      &.active {
        background: #2d2d2d;
        border: 2px solid #2d2d2d;
        color: $white;

        .price {
          color: rgba(255, 255, 255, .75);
        }
      }

      h2 {
        display: block;
        margin: 0;
        font-size: 1.2em;
        transition: all .15s linear;
        font-family: $circular-bold;
      }

      .price {
        display: block;
        color: $tundora;
        transition: all .15s linear;
        font-weight: 700;
        font-size: .8125em;
        line-height: 1.5;
      }

      .savings {
        display: inline-block;
        padding: 0 .5em;
        border-radius: 4px;
        background: rgba(31, 233, 174, .2);
        color: #1fe9ae;
        font-weight: 700;
        font-size: .75em;
        line-height: 1.5;
      }

      a {
        display: inline-block;
        margin-top: 1em;
        min-width: 8em;
      }
    }

    &__payment-note {
      display: block;
      text-align: center;
      font-size: .875em;
      font-weight: normal;
      color: #2d2d2d;
      margin: 20px 0 0;

      span {
        display: inline-block;
        background: rgba(45, 45, 45, .1);
        color: #2d2d2d;
        border-radius: 3em;
        padding: 0 .5em;
        font-weight: 700;
        font-size: 1em;
      }
    }

    .card-type {
      position: absolute;
      z-index: 9;
      top: 0;
      right: 0;
      height: 100%;
      width: 60px;

      .card-icon {
        height: 100%;
        width: 100%;
      }
    }

    .coupon-field {
      text-align: center;
    }

    &__errors {
      opacity: 0;
      transition: opacity .3s ease, max-height .3s ease;
      margin: 20px 0 0;
      max-height: 0;
      overflow: hidden;

      &.active {
        opacity: 1;
        max-height: 500px;
      }

      p {
        padding: 1rem;
        border-radius: 8px;
        background: rgba(231, 76, 60, .15);
        color: #e74c3c;
      }
    }

    &__success-notice {
      opacity: 0;
      visibility: hidden;
      display: none;

      p {
        font-size: 2rem;
        margin: 0;
      }

      &.show-notice {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }

  }

  #account-setup {
    visibility: visible;
    opacity: 1;

    &.hide-form {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }
}
