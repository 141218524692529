.form {
  &.is-inline {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    .form-item {
      flex: 1;
      margin-right: 1rem;
    }
  }

  &--header {
    text-align: center;
  }

  &--text {
    text-align: center;

    p {
      font-weight: normal;
      font-size: 0.8125em;
      color: rgba($gray, 0.8);
      margin: 0 0 1.5em;

      a {
        color: $green;
        text-decoration: none;
      }
    }

    .mini-action {
      display: inline-block;
      font-size: 0.75em;
      font-weight: normal;
      color: $green;
      text-decoration: none;
      padding: 0.5em 1em;
      border-radius: 2em;
      background: rgba($green, 0.15);
      transition: all 0.15s linear;
    }
  }
}

.checkbox-group {
  background: $offWhite;
  border: 2px solid rgba($gray, 0.5);
  border-radius: 6px;
  margin-bottom: 1.75rem;
  max-height: 18.5em;
  overflow: auto;

  + .form-item--helper {
    margin-top: calc(0.5em - 1.75rem);
    margin-bottom: 1.75rem;
  }

  .form-item {
    border-bottom: 1px solid $lightGray;
    margin: 0;
    padding: 1em;
  }
}

.form-item {
  position: relative;
  margin-bottom: 1.75rem;
  line-height: 1;
  text-align: left;

  &.error {
    input,
    textarea {
      border-color: $error-red;
    }
  }

  &.is-flush {
    margin-bottom: 0;
  }

  &.is-hidden {
    display: none;
  }

  &.is-center {
    text-align: center;
  }

  &.has-addons {
    display: flex;
  }

  &.is-select {
    &::after {
      content: '\e5d7';
      font-size: 1.25em;
      font-family: 'tot-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      display: block;
      position: absolute;
      right: 0.5em;
      top: 0.75em;
      width: 1em;
      pointer-events: none;
      z-index: 2;
      opacity: 0.6;
      transition: all 0.15s linear;
    }

    &.filled {
      &::after {
        opacity: 1;
      }
    }

    &.is-disabled {
      &::after {
        opacity: 0.4;
      }
    }

    &.is-small {
      &::after {
        font-size: 0.875em;
        right: 0.425em;
        top: 0.6em;
      }
    }
  }

  &.is-textarea {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0.5em;
      right: 0.5em;
      height: 1.5em;
      background: $offWhite;
      opacity: 0;
      pointer-events: none;
    }

    &.filled {
      &::before {
        opacity: 1;
      }
    }

    &.is-code {
      &::before {
        display: none;
      }
    }
  }

  &.is-url {
    &.filled {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 1.55em;
        left: 2px;
        right: 2px;
        height: calc(1.75em - 2px);
        background: $darkGray;
        opacity: 0.2;
        pointer-events: none;
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &.is-range {
    margin-bottom: 3rem;
  }

  &.is-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      margin: 0;

      .tot_tip {
        border-bottom: 2px solid $lightGray;
      }
    }

    h3 {
      margin: 0 !important;
    }

    + p.is-desc {
      margin-top: -0.75rem;
    }
  }

  &.is-card {
    .card-type {
      display: block;
      position: absolute;
      top: 1.125rem;
      right: 1.125rem;
      border-radius: 4px;
      color: $white;

      &.visa {
        background: linear-gradient(120deg, #1a1e5a 0, #122d98 100%);
      }

      &.mastercard {
        background: linear-gradient(120deg, #cc0000 0, #ff9900 100%);
      }

      &.discover {
        background: linear-gradient(120deg, #df5315 0, #f9a020 100%);
      }

      &.amex {
        background: linear-gradient(120deg, #000000 0, #4d4f53 100%);
      }

      &.diners {
        background: linear-gradient(120deg, #041e42 0, #035280 100%);
      }

      &.jcb {
        background: linear-gradient(120deg, #0b3189 0, #006ac9 100%);
      }

      svg {
        display: inline-block;
        width: 3.25em;
        height: 1.75em;
        fill: currentColor;
      }
    }
  }

  &--group {
    margin: 2rem -2rem 1.75rem;
    border-bottom: 1px solid $lightGray;

    .form-item {
      margin: 0;
      padding: 1.5rem 2rem;
      border-top: 1px solid $lightGray;
    }
  }

  &--prepend,
  &--append {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.5625em;
    background: rgba($gray, 0.4);
    opacity: 0.8;
    padding: 0 1em;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $lightGray;
  }

  &--prepend {
    border-radius: 6px 0 0 6px;

    + .form-item--control {
      border-radius: 0 6px 6px 0;
    }
  }

  &--append {
    border-radius: 0 6px 6px 0;

    + .form-item--control {
      border-radius: 6px 0 0 6px;
    }
  }

  &--label {
    display: block;
    position: absolute;
    top: 0;
    margin-top: 0.825em;
    padding: 0 1.25em;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0;
    font-family: $circular-bold;
    font-size: 0.75em;
    color: $tundora;
    transform: translateY(0.25em);
    transition: all 0.15s ease-out;

    .filled &,
    .is-categories & {
      opacity: 1;
      transform: none;
    }

    &.is-persistent {
      opacity: 1;
      transform: none;
      position: relative;
      margin: 0 0 0.5em;
    }

    .is-disabled & {
      color: rgba($gray, 0.6);
    }

    .is-checkbox & {
      display: block;
      position: relative;
      top: auto;
      width: auto;
      margin: 0;
      padding: 0 0 0 2em;
      font-weight: normal;
      font-size: 1em;
      line-height: 1.25;
      color: $darkGray;
      cursor: pointer;
      opacity: 1;
      pointer-events: auto;
      overflow: auto;
      white-space: normal;
      transform: none;

      &:hover {
        input {
          border-color: rgba($gray, 0.8);
        }
      }
    }
  }

  &--helper {
    margin: 0;
    margin-top: 0.5em;
    margin-left: 1.2em;
    font-size: 0.6875em;
    line-height: 1.5;
    color: $tundora;
    font-weight: normal;

    sup {
      top: -3px;
      vertical-align: middle;
      display: inline-block;
      position: relative;
    }

    .chip {
      font-size: 0.75em;
      padding: 0.25em 0.5em;
      line-height: normal;
      margin-left: 0.25em;
    }
  }

  &--control {
    display: block;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    background: $white;
    color: $mine-shaft;
    border: 2px solid $wild-sand;
    border-radius: 6px;
    padding: 1em 0.75em;
    height: 3.25em;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.02em;
    transition: all 0.15s ease-out;

    &::-webkit-input-placeholder {
      color: $boulder;
    }

    &::-moz-placeholder {
      color: $boulder;
    }

    &:-ms-input-placeholder {
      color: $boulder;
    }

    &:-moz-placeholder {
      color: $boulder;
    }

    &:focus {
      // border-color: $darkGray;
    }

    .is-categories &,
    .filled & {
      border-color: $darkGray;
      padding: 1.5em 0.75em 0.5em;

      &:focus {
        &::-webkit-input-placeholder {
          color: transparent;
        }

        &::-moz-placeholder {
          color: transparent;
        }

        &:-ms-input-placeholder {
          color: transparent;
        }

        &:-moz-placeholder {
          color: transparent;
        }
      }
    }

    .is-categories & {
      padding: calc(1.375em - 2px) 0.75em calc(0.375em - 2px);
    }

    .is-dark & {
      background: $darkGray;
      color: $white;

      &:focus {
        border-color: $gray;
      }
    }

    .is-darker & {
      background: darken($darkGray, 5%);

      color: $white;

      &:focus {
        border-color: $gray;
      }
    }

    .is-dark.filled &,
    .is-darker.filled & {
      border-color: $gray;
    }

    .has-error & {
      border-color: $error-red !important;
    }

    .is-disabled & {
      background: rgba($gray, 0.1);
      border-color: transparent;
      color: rgba($gray, 0.6);
      pointer-events: none;
    }

    .is-textarea & {
      resize: none;
      height: auto;
    }

    .is-textarea.is-code & {
      padding: 1em 0.75em;
      background: $darkGray;
      color: $white;
      font-weight: 700;
      white-space: pre;
    }

    .is-select & {
      display: block;
      width: 100%;
      padding: 0 2em 0 0.75em;
      outline: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    .is-checkbox & {
      -moz-appearance: none;
      -webkit-appearance: none;
      outline: none;
      background: none;
      display: block;
      position: absolute;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border: 2px solid $lightGray;
      color: $darkGray;
      height: 1.25em;
      width: 1.25em;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.15s linear;

      &:checked {
        background: $green;
        border-color: $green !important;

        &::after {
          opacity: 1;
          transform: none;
        }
      }

      &::after {
        content: '';
        display: block;
        position: relative;
        background-image: svg-url(
                        '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"><path fill="#{$white}" d="M17.76,5.571L10.478,15.5l-3.4-3.185a1.4,1.4,0,1,0-1.914,2.043l4.55,4.265c0.021,0.02.049,0.026,0.071,0.045s0.034,0.046.058,0.063a1.347,1.347,0,0,0,.225.115,1.378,1.378,0,0,0,.133.068,1.4,1.4,0,0,0,.469.088h0a1.392,1.392,0,0,0,.583-0.137,1.4,1.4,0,0,0,.169-0.118,1.345,1.345,0,0,0,.269-0.188c0.022-.023.029-0.054,0.049-0.079s0.043-.028.059-0.05l8.217-11.2A1.4,1.4,0,0,0,17.76,5.571Z"/></svg>'
        );
        background-size: 100% auto;
        width: calc(1.25em - 4px);
        height: calc(1.25em - 4px);
        border-radius: 4px;
        transform: scale(0.5);
        opacity: 0;
        transition: all 0.15s ease-out;
      }
    }

    .is-select.filled & {
      padding: 1.25em 2em 0.25em 0.75em;
    }

    .is-url.filled & {
      padding: 1.65em 0.75em 0.25em;
      font-weight: 700;
    }

    .is-categories & {
      height: auto;
      position: relative;
    }

    .is-small & {
      padding: 0 1.75em 0 0.75em !important;
      height: 2.4625em;
      border: none;
      font-size: 0.8125em;
      line-height: normal;
    }

    &.has-glow {
      border-color: transparent;
      box-shadow: 0 0 2rem rgba($darkGray, 0.08);

      &:focus,
      .is-filled & {
        border-color: $green;
      }
    }
  }

  &--actions {
    display: flex;
    flex-direction: row;
    margin: 0 -0.5rem;
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;

    .form-item--action-btn {
      position: relative;
      top: auto;
      right: auto;
      margin: 0 0.5rem;
    }
  }

  &--action-btn {
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    padding: 0.5465em 0.85em;
    border-radius: 4px;
    font-weight: normal;
    font-size: 0.6875em;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;
    background: $gray;
    color: $white;
    transition: all 0.15s linear;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($gray, 0.25);
    }

    &.is-text {
      padding: 0.5465em;
      background: none;
      color: $gray;
    }

    &.is-primary {
      background: $green;
      color: $white;
    }
  }
}

.form {
  &--note {
    position: relative;
    border-radius: 8px;
    padding: 1.5em;
    font-size:1.3124958984503172rem;
    margin-bottom: 2rem;
    background: rgba($gray, 0.25);
    color: $gray;

    &.is-caution {
      background: $caution-bg-yellow;
      color: $caution-yellow;

      h2 {
        color: $caution-yellow !important;
      }

      p,
      a {
        color: $caution-yellow;
      }

      code {
        font-family: Courier, monospace;
        font-weight: 700;
        background: darken(#fcc903, 20%);
        color: $white;
      }
    }

    &.is-warning {
      background: $error-bg-red;
      color: $error-red;

      h2 {
        color: $error-red !important;
      }

      p,
      a {
        color: $error-red;
      }
    }

    &.is-error {
      background: $error-bg-red;
      color: $error-red;

      h2 {
        color: $error-red !important;
      }

      p,
      a {
        color: $error-red;
      }
    }

    &.is-success {
      background: $success-bg-green;
      color: $success-green;

      h2 {
        color: $success-green !important;
      }

      p,
      a {
        color: $success-green;
      }
    }

    &.is-special {
      background: $green;
      background: linear-gradient(120deg, #08aeea 0%, $green 100%);
      color: $white;

      h2 {
        color: $white !important;
      }

      p,
      a {
        color: $white;
      }
    }

    &.has-icon {
      padding-left: 5.75em;
    }

    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1em;

      svg {
        width: 4em;
        height: 4em;
        fill: currentColor;
      }
    }

    h2 {
      margin: 0 0 0.25em !important;
      padding: 0 !important;
      font-size: 1.2em !important;
      color: $gray !important;
    }

    a {
      font-weight: normal;
      color: $gray;
      text-decoration: underline;
    }

    p:last-child {
      margin-bottom: 0;
    }

    + .section-title {
      margin-top: 2rem !important;
    }
  }

  &--row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }

  &--col {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    flex: 0 0 auto;

    &.is-half {
      flex-basis: 50%;
      max-width: 50%;
    }

    &.is-fourth {
      flex-basis: 25%;
      max-width: 25%;
    }

    &.is-three-fourths {
      flex-basis: 75%;
      max-width: 75%;
    }

    &.is-one-third {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }

    &.is-two-thirds {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
    }
  }
}

.intrinsic-warning {
  display: none;

  &.is-showing {
    display: block;
  }
}


// Text Input Block
.text-control {
  position: relative;
  margin: 24px 0 34px;

  &.flush-top {
    margin-top: 0;
  }

  &.flush-bottom {
    margin-bottom: 0;
  }

  > label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 10px;
    text-align: left;
    color: rgba($gray, 0.4);
    font-size: 18px;
    line-height: 28px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: transform 0.2s linear, color 0.4s linear;
  }

  &.light {
    label {
      color: rgba($gray, 0.8);
    }
  }

  &.required {
    &:after {
      content: '*';
      display: block;
      position: absolute;
      top: 14px;
      right: 10px;
      font-size: 22px;
      font-weight: normal;
      color: $green;
      z-index: 2;
      transition: all 0.2s linear;
    }

    .day & {
      &.error {
        &:after {
          color: $white !important;
        }
      }
    }

    .night & {
      &.filled,
      &.error {
        &:after {
          color: $darkGray !important;
        }
      }
    }
  }

  .error-message {
    display: none;
    background: $errorColor;
    margin-top: -2px;
    padding: 10px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 2px;
    line-height: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;

    .day & {
      color: $white;
    }

    .night & {
      color: $darkGray;
    }
  }

  &.error {
    .error-message {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }

  .copy-url {
    position: absolute;
    top: 8px;
    right: 38px;
  }
}

// Text Input Element
.form-control {
  display: block;
  position: relative;
  border: 2px solid $lightGray;
  border-radius: 2px;
  padding: 8px 10px;
  width: 100%;
  background: $offWhite;
  -webkit-appearance: none;
  outline: none;
  color: $darkGray;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  transition: all 0.2s linear;

  &.control-sm {
    padding: 4px 8px;
    font-size: 13px;
    line-height: 16px;
  }

  &.editor {
    padding: 0;

    .scroller & {
      margin: 0 -20px 0 -22px;
      width: auto;
      border-bottom: none;

      &.is-flush {
        margin-top: -22px;
      }
    }
  }

  .has-copy-btn & {
    padding-right: 112px;
  }

  .night & {
    border-color: $gray;
    background: darken($darkGray, 4%);
    color: $white;
  }

  .required & {
    border-right-width: 30px;
  }

  &:focus,
  .filled & {
    border-color: $gray;

    & + label {
      // font-weight: 600;
      color: $gray;
      transform: translate3d(3px, -28px, 0) scale3d(0.7, 0.7, 1);
    }
  }

  .night &:focus,
  .night .filled & {
    border-color: $green;

    & + label {
      // font-weight: 400;
      color: rgba($lightGray, 0.8);
    }
  }

  &.error,
  .error & {
    border-color: $errorColor !important;
  }

  &.inline-control {
    border: none;
    padding: 0 5px;
    width: 70%;
    min-height: 0;
    border-radius: 8px;
    background: $white;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    box-shadow: 0 0 10px 3px rgba($black, 0.2);
  }

  &.no-highlight {
    border-color: $lightGray;

    .night & {
      border-color: $gray;
    }
  }
}

// Custom Select Dropdown
.select-wrapper {
  margin: 0 0 1.75rem;

  &.flush-top {
    margin-top: 0;
  }

  &.flush-bottom {
    margin-bottom: 0;
  }

  &.inline {
    margin: 0;
    line-height: 0;
  }
}

.select-flush {
  .select-wrapper {
    margin-top: 0;
  }
}

.select-control {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  // margin: 0 0 20px;
  // margin: 24px 0 34px;
  text-align: left;
  z-index: 2;
  width: 100%;
  transition: z-index 0.5s step-end;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__title {
    pointer-events: none;
  }

  select {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    background: $offWhite;
    border: 2px solid $lightGray;
    // background: darken($darkGray, 4%);
    // border: 2px solid $gray;
    border-radius: 6px;
    backface-visibility: hidden;
    transform: translateY(-50%);
    transition: height 0.4s;
  }

  &:after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: $offWhite;
    // background: $black;
    opacity: 0;
    z-index: -1;
    backface-visibility: hidden;
    transition: opacity 0.3s linear, width 0s 0.3s, height 0s 0.3s;
  }

  > span.selected {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid $lightGray;
    border-radius: 6px;
    transition: opacity 0.3s 0.3s, border 0.2s, color 0.2s;

    padding: 0.75em;
    height: 3.25em;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.02em;

    &:after {
      display: block;
      position: absolute;
      right: 0.5em;
      top: 0.65em;
      width: 1em;
      pointer-events: none;
      font-size: 1.25em;
      content: '\e5d7';
      font-family: 'tot-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &.filled {
    &:before {
      border-color: $darkGray;
      // border-color: $green;
    }

    > span.selected {
      border-color: $darkGray;
      color: $darkGray;
      // border-color: $green;
      // color: $white;
    }
  }

  &.disabled {
    pointer-events: none;

    > span.selected {
      border-color: $lightGray;
      color: rgba($gray, 0.8);
    }
  }

  .dropdown {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: calc(16em - 4px);
    padding: 0 2px;
    visibility: hidden;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    border-radius: 2px;
    transform: translate3d(-50%, -50%, 0);
    z-index: 9;
  }

  .scroller {
    padding: 0 !important;

    .ps-scrollbar-y-rail {
      margin: 2px 0 !important;
    }
  }

  ul.dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;

    li,
    button {
      position: relative;
      opacity: 0;
      border-bottom: 1px solid $lightGray;
      transition: opacity 0.3s linear;

      &.font-picker--group-title {
        padding: 0.35em 1em;
        background: $lightGray;
        color: $darkGray;

        span {
          padding: 0;
          cursor: default;
          font-size: 0.55em;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          text-align: right;
          pointer-events: none;
          background: none !important;
        }
      }

      span {
        display: block;
        position: relative;
        cursor: pointer;
        padding: 0.5em 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.1s linear;
        color: $darkGray;
        // color: $lightGray;

        &.default {
          color: rgba($gray, 0.8);

          &:hover {
            color: lighten(rgba($gray, 0.8), 10%);
          }
        }

        &:hover {
          background: $white;
          color: $darkGray;
          // background: $darkGray;
          // color: $white;

          strong.font {
            box-shadow: -4px 0 10px 10px $lightGray;
          }
        }

        strong.font {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1rem;
          border-radius: 1em;
          background: darken($lightGray, 5%);
          color: rgba($gray, 0.8);
          box-shadow: -4px 0 10px 10px $offWhite;
          transition: all 0.1s linear;
          font-size: 0.5em;
          letter-spacing: 0.025em;
          line-height: 1.2;
          padding: 0.5em 1em;
        }
      }
    }
  }

  &.open {
    z-index: 3;
    transition: z-index 0s step-end;

    &:before {
      border-color: $gray;
      height: 16em;
    }

    &:after {
      width: 100%;
      height: 100%;
      opacity: 0.6;
      transition: opacity 0.3s linear, width 0s 0s, height 0s 0s;
    }

    > span.selected {
      opacity: 0;
      transition-delay: 0s;
    }

    .dropdown {
      overflow-y: auto;
      visibility: visible;
    }

    ul.dropdown-options {
      li,
      button {
        opacity: 1;
        transition-delay: 0.4s;
      }
    }
  }

  &.short {
    .dropdown {
      height: 80px;
    }

    &.open {
      &:before {
        height: 84px;
      }
    }
  }

  // DARK THEME
  .night & {
    color: rgba($gray, 0.8);

    &:before {
      background: darken($darkGray, 4%);
      border: 2px solid $gray;
    }

    &:after {
      background: $darkGray;
    }

    > span.selected {
      border: 2px solid $gray;
    }

    &.filled {
      &:before {
        border-color: $green;
      }

      > span.selected {
        border-color: $green;
        color: $white;
      }
    }

    ul.dropdown-options {
      li {
        span {
          color: $lightGray;

          strong.font {
            box-shadow: -4px 0 10px 10px darken($darkGray, 4%);
          }

          &:hover {
            background: $darkGray;
            color: $white;

            strong.font {
              box-shadow: -4px 0 10px 10px $darkGray;
            }
          }
        }
      }
    }

    &.open {
      &:before {
        border-color: $green;
      }
    }
  }

  &.select-sm {
    font-size: 11px;
    line-height: 18px;

    > span.selected {
      padding: 0 18px 0 10px;
      font-weight: 400;

      &::after {
        margin: 2px;
        font-size: 13px;
      }
    }

    ul.dropdown-options {
      li {
        span {
          padding: 8px 16px 8px 10px;
        }
      }
    }
  }
}

// Inputs grouped with text
.control-group {
  position: relative;
  padding-right: 90px;
  margin: 24px 0 34px;
  min-height: 30px;

  p {
    margin: 0;
    font-size: 13px;
    line-height: 15px;

    .day &,
    .night & {
      color: $lightGray;
    }
  }

  .toggle-switch {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Inputs toggle mode
.toggle-select {
  display: none;
}

// Upload boxes
.upload-control {
  position: relative;
  margin-bottom: 1.75rem;

  .hidden {
    display: none;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .drop {
    position: relative;
    border: 2px dashed rgba($gray, 0.5);
    text-align: center;
    height: 290px;
    display: table;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    &.hover {
      border: 2px solid $darkGray !important;
    }

    .btn-group {
      display: table-cell;
      vertical-align: middle;
    }
  }

  &.filled {
    .drop {
      border: 2px solid $darkGray;

      .btn-group {
        opacity: 0;
        visibility: hidden;
        transition: all 0.15s linear;
      }

      &.hover {
        border-color: $green;
      }

      &:hover {
        .btn-group {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .preview {
      background: $offWhite;
      background-image: linear-gradient(45deg, rgb(204, 204, 204) 25%, transparent 25%), linear-gradient(-45deg, rgb(204, 204, 204) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(204, 204, 204) 75%), linear-gradient(-45deg, transparent 75%, rgb(204, 204, 204) 75%);
      background-size: 20px 20px;
      background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
      background-repeat: repeat;
    }
  }

  &.error {
    .drop {
      border: 2px solid $errorColor;
    }
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0 auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    &.icon-preview {
      img {
        max-width: 72px;
        max-height: 72px;
      }
    }
  }

  &.short {
    .drop {
      height: 9em;
    }
  }

  // &.dz-drag-hover {
  //   border: 2px solid $darkGray;
  // }

  // .upload-control {
  //   display: table-cell;
  //   vertical-align: middle;
  // }
}

// Range Fields
.range-control {
  position: relative;
  padding: 2rem 0;
  margin: 0 1rem;

  &.simple {
    span.start,
    span.end {
      display: none;
    }

    .noUi-tooltip {
      font-size: 0.75em;
      background: none;
      width: auto;
      margin: 0;
      transform: translateX(-50%);
    }
  }

  span.start,
  span.end {
    display: block;
    position: absolute;
    top: 100%;
    margin-top: -1rem;
    text-align: center;
    font-family: Courier, monospace;
    font-weight: 700;
    font-size: 0.75em;
    color: $lightGray;
    z-index: -1;
  }

  span.start {
    left: 0;
  }

  span.end {
    right: 0;
  }

  &[data-unit='px'] {
    .noUi-tooltip,
    .start,
    .end {
      &::after {
        content: 'px';
      }
    }
  }

  &[data-unit='%'] {
    .noUi-tooltip {
      &::after {
        content: '%';
      }
    }
  }
}

.radio-control {
  display: block;
  position: relative;
  margin: 24px 0 34px;
  padding: 0.5em 0.5em 0.5em 3.15em;
  font-weight: 600;
  color: $darkGray;
  cursor: pointer;
  z-index: 1;

  &.flush-top {
    margin-top: 0;
  }

  &.flush-bottom {
    margin-bottom: 0;
  }

  &.is-locked,
  .is-locked & {
    pointer-events: none !important;
  }

  &:hover {
    span.option-bg {
      border-color: rgba($gray, 0.8);

      &::after {
        background: rgba($gray, 0.8);
      }
    }
  }

  &.is-fancy {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 12.5em;
    padding: 1em 1em 3.5em !important;
    text-align: center;
    transition: all 0.15s linear;

    &:hover {
      span.option-bg {
        border-color: rgba($gray, 0.8);

        &::after {
          background: rgba($gray, 0.8);
        }
      }
    }

    img {
      display: block;
      margin: 0 auto 1em;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }

    input {
      bottom: calc(0.5em + 1px);
      top: auto;
      left: 50%;
      transform: translateX(-50%);

      &:checked {
        + span.option-bg {
          border-color: $darkGray;

          &::after {
            background: $darkGray;
          }
        }
      }
    }

    span.option-bg {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      background: transparent;
      border: 2px solid $lightGray;
      opacity: 1;
      visibility: visible;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(2.25em - 2px);
        top: auto;
        left: 0;
        right: 0;
        height: 2px;
        width: auto;
        background: $lightGray;
        transition: all 0.15s linear;
      }
    }
  }

  span.option-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border: 2px solid $lightGray;
    z-index: -1;
    transition: all 0.15s linear;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 2.25em;
      width: 2px;
      background: $lightGray;
      transition: all 0.15s linear;
    }
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    background: none;
    display: block;
    position: absolute;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 0.65em;
    border: 2px solid $lightGray;
    color: $darkGray;
    height: 1.25em;
    width: 1.25em;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.15s linear;

    &:after {
      content: '';
      display: block;
      position: relative;
      background: $white;
      width: calc(1.25em - 4px);
      height: calc(1.25em - 4px);
      border-radius: 50%;
      transition: all 0.15s ease-out;
    }

    &:checked {
      background: $darkGray;
      border-color: $darkGray;

      &:after {
        transform: scale(0.5);
      }

      + span.option-bg {
        border-color: $darkGray;

        &::after {
          background: $darkGray;
        }
      }
    }
  }
}

// Tag Fields

.categories {
  padding: 3px 5px;

  .domains & {
    padding: 0;
    border: none;
    background: none;

    .taggle_placeholder {
      top: auto;
      bottom: 2px;
      opacity: 1 !important;
      padding: 8px 10px;
      width: 100%;
      -webkit-appearance: none;
      outline: none;
      color: rgba($gray, 0.4);
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      transition: all 0.2s linear;
    }

    &.active {
      .taggle_placeholder {
        opacity: 0 !important;
      }
    }

    .taggle_list {
      .taggle {
        float: none;
        margin: 5px 0 0;
        padding: 5px 30px 5px 10px;

        .day-mode &,
        .day & {
          background: lighten($gray, 52%);
          color: $darkGray;

          .close {
            color: $darkGray;
          }
        }

        .night-mode &,
        .night & {
          background: rgba($gray, 0.5);
          background: darken($gray, 10%);
          color: $white;

          .close {
            color: $white;
          }
        }

        &:hover {
          background: $errorColor;
          color: $white;

          .close {
            color: $white;
          }
        }
      }
    }

    .taggle_input {
      float: none;
      margin-top: 5px;
      border: 2px solid $lightGray;
      border-radius: 2px;
      padding: 8px 10px;
      width: 100% !important;
      background: $offWhite;
      -webkit-appearance: none;
      outline: none;
      color: $darkGray;
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      transition: all 0.2s linear;

      &:focus {
        border-color: $gray;
      }
    }
  }

  .error & {
    .taggle_input {
      border-color: $errorColor !important;
    }
  }

  .taggle_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -0.75rem;
    @include clearfix();
    cursor: text;
    overflow: auto;

    .taggle {
      float: left;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: relative;
      margin: 0.375rem;
      padding: 0.5em 0.5em 0.5em 1em;
      background: $darkGray;
      color: $offWhite;
      font-size: 0.875em;
      border-radius: 2em;
      white-space: nowrap;
      transition: all 0.1s linear;

      &:hover {
        background: $error-red;

        .close {
          background: $error-bg-red;
          color: $error-red;
        }
      }

      .close {
        display: block;
        cursor: pointer;
        font-size: 1em;
        line-height: 0;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        position: relative;
        margin-left: 0.75rem;
        min-height: 0;
        background: rgba($white, 0.2);
        color: $darkGray;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .taggle_placeholder {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: calc(1.375em - 2px) 0.75em calc(0.375em - 2px);
    text-align: left;
    color: rgba($gray, 0.4);
    pointer-events: none;
    transition: opacity 0.15s linear;
  }

  .taggle_input {
    float: left;
    background: none;
    width: 1em;
    max-width: 100%;
    padding: 0 0.75rem;
    outline: none;
  }
}

.taggle_sizer {
  padding: 0;
  margin: 0;
  position: absolute;
  top: -500px;
  z-index: -1;
  visibility: hidden;
}

// Code Editors
.code-editor {
  border-radius: 6px;
  height: 24em;

  .ace_gutter {
    z-index: 2;
  }

  .ace_gutter-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  // #page-settings-overlay & {
  //   textarea {
  //     top: 0 !important;
  //   }
  // }
}

.is-locked {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 10px;
    right: 10px;
    bottom: 34px;
    background: rgba($offWhite, 0.6);
    border: 2px solid $green;
    border-radius: 4px;
    border-top-right-radius: 0;
    z-index: 2;
    cursor: not-allowed;
  }

  &::after {
    content: 'Locked';
    display: block;
    position: absolute;
    bottom: 100%;
    right: 10px;
    padding: 2px 10px 0px;
    margin-bottom: -2px;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: $green;
    color: $white;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }

  &.select-wrapper {
    &::before {
      left: 0;
      right: 0;
      top: 26px;
      bottom: 0;
      z-index: 3;
      border-radius: 2px;
      border-top-right-radius: 0;
    }

    &::after {
      margin-bottom: -28px;
      right: 0;
    }
  }
}

.filter-dropdown {
  position: relative;

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 1em;
    border-radius: 4px;
    font-size: 0.875em;
    line-height: 1.5;
    cursor: pointer;
    box-shadow: 0 0 0 2px transparent;
    transition: box-shadow 0.15s linear;
    background: darken($darkGray, 5%);

    &:hover,
    &.is-open {
      box-shadow: 0 0 0 2px rgba($gray, 0.4);
    }

    .icon {
      margin-left: 0.65em;
    }
  }

  &--options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    margin-top: 1rem;
    padding: 0 1rem 1rem;
    border-radius: 8px;
    white-space: nowrap;
    z-index: 3;
    animation: dropAnimation 1000ms linear both;
    background: $offWhite;
    color: $darkGray;
    box-shadow: 0 0 2rem rgba($black, 0.4);

    &.is-open {
      display: block;
    }

    .checkbox-control {
      position: relative;
      margin: 0 -1rem;
      font-size: 1em;
      border-bottom: 1px solid $lightGray;

      label {
        display: block;
        width: 100%;
        padding: 1.25rem 1.5rem 1.25rem 3.75rem;
        cursor: pointer;
        transition: all 0.15s linear;
      }

      input {
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;
        background: none;
        display: block;
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        color: transparent;
        height: 1.25em;
        width: 1.25em;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.15s linear;
        border: 2px solid $lightGray;

        &::after {
          content: '';
          display: block;
          position: relative;
          width: calc(1.25em - 4px);
          height: calc(1.25em - 4px);
          border-radius: 4px;
          opacity: 0;
          transition: all 0.15s ease-out;
          background-image: svg-url(
                          '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"><path fill="#{$white}" d="M17.76,5.571L10.478,15.5l-3.4-3.185a1.4,1.4,0,1,0-1.914,2.043l4.55,4.265c0.021,0.02.049,0.026,0.071,0.045s0.034,0.046.058,0.063a1.347,1.347,0,0,0,.225.115,1.378,1.378,0,0,0,.133.068,1.4,1.4,0,0,0,.469.088h0a1.392,1.392,0,0,0,.583-0.137,1.4,1.4,0,0,0,.169-0.118,1.345,1.345,0,0,0,.269-0.188c0.022-.023.029-0.054,0.049-0.079s0.043-.028.059-0.05l8.217-11.2A1.4,1.4,0,0,0,17.76,5.571Z"/></svg>'
          );
          background-size: 100% auto;
        }

        &:checked {
          background: $green;
          border-color: $green;

          &::after {
            opacity: 1;
          }
        }
      }

      &--title {
        display: block;
        font-weight: 700;
        font-size: 0.75em;
      }
    }

    .btn {
      margin-top: 1rem;
    }
  }
}
