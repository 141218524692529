@keyframes showAlert {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 0;
  }
  3.2% {
    transform: matrix3d(
      0.345,
      0.098,
      0,
      0,
      0.065,
      0.345,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  4.5% {
    transform: matrix3d(
      0.485,
      0.163,
      0,
      0,
      0.107,
      0.485,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  6.41% {
    transform: matrix3d(
      0.673,
      0.242,
      0,
      0,
      0.158,
      0.673,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  9.01% {
    transform: matrix3d(
      0.88,
      0.288,
      0,
      0,
      0.188,
      0.88,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  12.71% {
    transform: matrix3d(
      1.064,
      0.241,
      0,
      0,
      0.159,
      1.064,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  13.51% {
    transform: matrix3d(
      1.087,
      0.221,
      0,
      0,
      0.146,
      1.087,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  17.92% {
    transform: matrix3d(
      1.139,
      0.105,
      0,
      0,
      0.07,
      1.139,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  18.92% {
    transform: matrix3d(
      1.137,
      0.082,
      0,
      0,
      0.055,
      1.137,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    opacity: 1;
  }
  25.23% {
    transform: matrix3d(
      1.075,
      -0.001,
      0,
      0,
      -0.001,
      1.075,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  29.03% {
    transform: matrix3d(
      1.032,
      -0.015,
      0,
      0,
      -0.01,
      1.032,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  31.43% {
    transform: matrix3d(
      1.012,
      -0.017,
      0,
      0,
      -0.011,
      1.012,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  34.63% {
    transform: matrix3d(
      0.994,
      -0.014,
      0,
      0,
      -0.01,
      0.994,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  40.14% {
    transform: matrix3d(
      0.985,
      -0.007,
      0,
      0,
      -0.005,
      0.985,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  56.46% {
    transform: matrix3d(1, 0.001, 0, 0, 0.001, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  62.36% {
    transform: matrix3d(
      1.002,
      0.001,
      0,
      0,
      0,
      1.002,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  81.48% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  84.68% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes showFancyAlert {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
    opacity: 0;
  }
  5.31% {
    transform: matrix3d(0.563, 0, 0, 0, 0, 0.563, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  10.51% {
    transform: matrix3d(0.888, 0, 0, 0, 0, 0.888, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  15.82% {
    transform: matrix3d(1.024, 0, 0, 0, 0, 1.024, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  21.02% {
    transform: matrix3d(1.052, 0, 0, 0, 0, 1.052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
    opacity: 1;
  }
  35.34% {
    transform: matrix3d(1.01, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  49.55% {
    transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  78.18% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
      translateY(-50%);
  }
}

@keyframes vex-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes vex-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes vex-fadeout-fancy {
  0% {
    opacity: 1;
    transform: translateY(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }
  20% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.vex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 9999;
}

.vex-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.vex-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: vex-fadein 0.4s;
  -webkit-backface-visibility: hidden;
  background: rgba($darkGray, 0.8);

  .vex.vex-closing & {
    animation: vex-fadeout 0.4s;
    -webkit-backface-visibility: hidden;
  }
}

.vex-content {
  position: relative;
  width: 100%;
  max-width: 350px;
  margin: 1rem;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  // animation: showAlert 750ms linear both;
  animation: slideIn 0.4s cubic-bezier(0.19, 1, 0.22, 1) both;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  background: $white;
  box-shadow: 0 0 2rem rgba($black, 0.5);

  .vex.vex-closing & {
    animation: vex-fadeout 0.4s;
    -webkit-backface-visibility: hidden;
  }

  h2 {
    .app &,
    .providence & {
      margin: 0 0 0.25em;
      padding: 0;
      font-size: 1.25em;
      font-weight: normal;
      letter-spacing: normal;
      color: $darkGray;
    }
  }

  p {
    margin: 0 0 1em;
    font-size: 1em;
    line-height: 1.5;
    color: $darkGray;
  }

  .vex-dialog-message {
    padding: 1rem 0;
  }

  .vex-dialog-buttons {
    display: flex;
    margin: 0 -2rem;
    padding: 2rem 1rem 0;
    border-top: 1px solid rgba($darkGray, 0.15);
  }

  .btn {
    margin: 0 1rem;
    flex-grow: 1;
    flex-basis: 1;

    &.secondary {
      background: $darkGray;
      color: $white;
    }

    &.is-text {
      color: $gray;
    }
  }

  .fancy & {
    background: $white;
    color: $darkGray !important;
    max-width: 330px;
    padding: 2rem 2.5rem;
    -webkit-backface-visibility: hidden;

    .vex-dialog-message {
      &::before {
        content: " ";
        display: block;
        position: relative;
        width: 140px;
        height: 180px;
        margin: 0 auto;
        background: url("/assets/img/icon-published.svg") center center
          no-repeat;
        background-size: 100% auto;
      }
    }

    h1 {
      margin-bottom: 0;
      font-size: 2em;
      color: $darkGray;
    }

    p {
      font-size: 1em;
      line-height: 1.5;
    }

    .vex-dialog-buttons {
      display: block;
      margin: 0;
      padding-top: 0;
      border-top: 0;
    }

    .btn {
      &.secondary {
        display: block;
        margin: 0 auto 1rem;
        background: $green;
        color: $white;
      }

      &.btn-text {
        color: rgba($gray, 0.8);

        &:hover {
          color: $gray;
        }
      }
    }
  }
}

.vex-loading-spinner {
  position: fixed;
  z-index: 9999;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2em;
  width: 2em;
  background: $lightGray;
}

body.vex-open {
  overflow: hidden;
}
