.popup {
  display: none;
  position: fixed;
  bottom: -1rem;
  left: 50%;
  width: 90%;
  max-width: 700px;
  margin: 0;
  padding: 2rem 20rem 3rem 9rem;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: $white;
  box-shadow: 0 -2px 20px 0 rgba($black, .1);
  z-index: 8;
  opacity: 0;
  transform: translateX(-50%) translateY(100%);

  @media (max-width: $mobile-break-max) {
    display: none !important;
  }

  &.active {
    display: block;
  }

  &.show {
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity .2s linear, transform 1s ease;
  }

  &:hover {
    transform: translateX(-50%) translateY(-1rem);
    transition: all .25s ease-out;

    .close-wrapper {
      transform: none;
      opacity: 1;
      transition: opacity .2s .3s linear, transform .4s .3s ease;
    }
  }

  .close-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1.5rem 0;
    transform: scale(.85);
    opacity: 0;
    transition: opacity .2s linear, transform .4s ease;

    .close-popup {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      text-align: center;
      background: $darkGray;
      color: $white;
      font-size: 1em;
      border-radius: 50%;
      cursor: pointer;
      transition: all .2s linear;

      &:hover {
        background: $green;
        animation: .4s linear both pulse;
      }

      span.icon {
        line-height: 30px;
      }
    }
  }

  .icon-main {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 2rem;
    transform: translateY(-50%);
    margin-top: calc(-.5rem + 2px);

    img {
      display: block;
      width: 5rem;
      height: 5rem;
    }
  }

  .popup-content {
    position: relative;
    width: auto;
    height: 100%;

    h2 {
      margin: .1em 0 .25em;
      font-weight: normal;
      line-height: 1.2;
      font-size: 1.125em;
      text-transform: none;
      letter-spacing: normal;

    }

    h4.date {
      margin: 0;
      font-size: .75em;
      font-weight: 700;
    }

    .time {
      &::before {
        content: '•';
        display: inline-block;
        vertical-align: middle;
        margin: 0 1.5em;
        font-size: .65em;
        color: rgba($gray, .4);
      }
    }
  }

  .popup-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2.25rem 2rem 2rem;
    min-width: 180px;
    height: 100%;
    text-align: center;
  }
}
