.tabs-content {
  position: relative;
}

.tab-pane {
  position: absolute;
  padding: .5rem 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 20px, 0);
  transition: all 450ms cubic-bezier(.19, 1, .22, 1);

  &.is-active {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.premiums {
  &--more {
    margin: 2rem 0 10rem;
    text-align: center;

    p {
      margin: 0;
    }
  }

  &--contact {
    margin-top: 2rem;

    @media (min-width: $tablet-break) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      text-align: left;
    }

    &-item {
      @media (min-width: $tablet-break) {
        padding: 0 2rem;
      }
    }
  }

  &--nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    button {
      display: block;
      padding: .65em 2em;
      background: $offWhite;
      font-weight: 600;
      color: $darkGray;
      outline: none;
      cursor: pointer;
      transition: all .15s linear;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:hover {
        background: darken($offWhite, 2%);
      }

      &.is-active {
        background: $green;
        color: $white;
        box-shadow: 0 0 1.5rem rgba($darkGray, .2);
      }
    }
  }
}

.add-ons {
  &--header {
    text-align: center;
    margin: 0 0 6rem;

    .btn {
      margin-top: 1rem;
    }
  }

  &--list {
    @media (min-width: $tablet-break) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 860px;
      justify-content: center;
    }
  }
}

.add-on {
  padding: 2rem;
  text-align: center;

  @media (min-width: $tablet-break) {
    flex: 0 1 auto;
    flex-basis: 50%;
    max-width: 50%;
    max-width: 400px;
  }

  @media (min-width: $large-desktop-break) {
    flex-basis: 50%;
    max-width: 50%;
    max-width: 430px;
  }

  &--wrapper {
    padding: 3rem 1.5rem;
    border: 2px solid $green;
    border-radius: 8px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &--title {
    width: 100%;

    h2 {
      margin: 0 0 2rem;
      font-size: 2.5em;
      font-weight: normal;

      small {
        display: block;
        font-size: .325em;
        font-weight: 700;
        color: rgba($gray, .8);
      }
    }
  }

  &--cost {
    width: 100%;

    .price {
      margin: 0 -1.5rem;
      padding: 2rem;
      background: $green;
      font-size: 2em;
      font-weight: normal;
      color: $white;

      span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        line-height: 1;

        &.currency {
          font-size: .5em;
          margin-right: .1em;
        }

        &.note {
          display: block;
          font-size: .5em;
          font-weight: 700;
          line-height: 1;
          color: rgba($white, .8);
        }
      }
    }
  }

  &--includes {
    text-align: left;
    margin: 3rem 0;
  }

  .btn {
    margin-top: auto;
  }
}

.bundles--wrapper {
  margin-bottom: 8rem;
}

.bundle {
  margin: 4rem 0;

  &--content {
    display: block;
    position: relative;
    padding: 3rem 0;
    border-radius: 8px;
    border: 2px solid $green;
    transition: all .4s ease;

    @media (min-width: 1140px) {
      display: flex;
      flex-direction: row;
      padding: 3rem 1.75em;
    }

    &::before {
      content: attr(data-number);
      display: block;
      position: absolute;
      top: 0;
      right: 50%;
      width: 40px;
      height: 40px;
      border: 2px solid $green;
      border-radius: 50%;
      text-align: center;
      line-height: 36px;
      font-size: 1.2em;
      font-weight: 600;
      background: $white;
      transform: translateX(50%) translateY(-50%);

      @media (min-width: 1140px) {
        top: 50%;
        right: calc(100% + 1px);
      }
    }
  }

  &--discount {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;

    &::before {
      content: attr(data-discount);
      position: absolute;
      top: 25px;
      right: -30px;
      transform: rotate(45deg);
      width: 130px;
      line-height: 20px;
      background: $green;
      color: $white;
      font-size: .65em;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &--title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 13em;
    padding: 0 1.75em 3rem;
    text-align: center;

    @media (min-width: 1140px) {
      padding: 0;
      text-align: left;
    }

    h2 {
      margin: 0;
      font-size: 2.5em;
      line-height: 1.2;
      font-weight: normal;

      small {
        display: block;
        margin-top: .5em;
        font-size: .375em;
        font-weight: 700;
        color: rgba($gray, .8);
      }
    }
  }

  &--details {
    display: block;
    position: relative;
    padding-left: 2.5rem;
    margin: 0;
    border-left: none;
    border-right: none;
    border-top: 2px solid $green;
    border-bottom: 2px solid $green;
    background: rgba($green, .05);
    color: $green;
    overflow: hidden;

    @media (min-width: 1140px) {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: calc(-3rem + -1px) 0;
      border-top: none;
      border-bottom: none;
      border-left: 2px solid $green;
      border-right: 2px solid $green;
    }

    &::before {
      content: 'Includes';
      position: absolute;
      top: 50%;
      left: -8rem;
      transform: rotate(-90deg);
      transform-origin: 50% 0;
      width: 16rem;
      font-size: .65em;
      font-weight: 700;
      line-height: 2.5;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      color: $green;
      border-bottom: none;
    }
  }

  &--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex: 1;
    min-height: 2.5em;
    padding: 0 1.75em;
    font-weight: 700;
    line-height: 1;
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    border-left: 2px solid $green;
    transition: all .15s linear;
    &:last-of-type {
      border-bottom: none;
      @media (min-width: 1140px) {
        border-bottom: 1px solid $green;
      }
    }

    span {
      text-align: center;
    }

    &:hover {
      background: rgba($green, .1);
      background: linear-gradient(to right, rgba($green, .15), $white);

      .bundle--item-action {
        opacity: 1;
      }
    }

    &-title {
      a {
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        margin-left: .5em;
        opacity: .6;
        font-weight: 400;
      }
    }

    &-action {
      opacity: 0;
      transition: all .15s linear;
    }
  }

  &--row-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex: 1;
    min-height: 2.5em;
    padding: 0 .75em;
    color: #2d2d2d;
    font-weight: 700;
    line-height: 1;
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    border-left: 2px solid $green;
    transition: all .15s linear;
    &:nth-of-type(1) {
      border-top: none;
      @media (min-width: 1140px) {
        border-top: 1px solid $green;
      }
    }
  }

  &--cost {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0 0;
    text-align: center;
    font-weight: normal;

    @media (min-width: 1140px) {
      padding: 0 0 0 3rem;
    }

    .price {
      font-size: 2em;
      color: $darkGray;

      span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        line-height: 1;

        &.currency {
          font-size: .5em;
          margin-right: .1em;
        }

        &.note {
          display: block;
          font-size: .5em;
          line-height: 1;
          margin-top: .5em;
          color: rgba($darkGray, .6);
        }
      }
    }
  }

  &--disclaimer {
    text-align: right;
    font-size: .85em;
    font-weight: normal;
    color: rgba($gray, .8);
    margin-top: 1rem;

    &.is-center {
      text-align: center;
    }
  }
}


.premiums {
  .add-on-open & {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  &-overlay {
    .add-on-open & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.premium {
  &--left,
  &--right {
    .add-on-open & {
      transform: none;
    }
  }

  &--title {
    margin-top: .2em;
    margin-bottom: .2em;
    font-weight: normal;
    font-size: 5em;

    @media (min-width: $tablet-break) {
      margin-top: 0;
      margin-bottom: .2em;
      font-size: 7em;
    }
  }
}
