.toggle-group {
  width: 100%;
  display: flex;
  align-items: center;

  p {
    flex: 1;
    margin: 0 1em 0 0;
    font-size: .75em;
    line-height: 1.5;
    font-weight: 700;
    color: $tundora;
  }

  .tot_tip {
    border-bottom: 2px solid $wild-sand;
  }
}

.toggle-switch {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;

  label {
    text-align: right;
    font-size: 12px;
    line-height: 27px;
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0;
  }

  input {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    position: absolute !important;
    width: 1px;

    + label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      user-select: none;
      padding: 0 10px;
      width: 70px;
      height: 30px;
      border: 2px solid $wild-sand;
      background: $wild-sand;
      color: $tundora;
      border-radius: 15px;
      transition: all 0.3s ease;

      .night & {
        border: 2px solid $gray;
        background: $gray;
        color: $darkGray;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.3s ease;
      }

      &:before {
        content: attr(data-checked);
        left: 2px;
        width: 38px;
        text-align: center;
        color: transparent;
        pointer-events: none;
      }

      &:after {
        top: 2px;
        left: 2px;
        bottom: 2px;
        width: 22px;
        background: $white;
        box-shadow: 0 0 5px rgba($black, .1);
        border-radius: 12px;
      }
    }

    &.focus-visible + label {
      outline: 2px solid #757575;
    }

    &:checked + label,
    &.on + label {
      border-color: $success-bg-green;
      background: $success-bg-green;
      color: transparent;

      // .night & {
      //   background: none;
      //   border-color: $green;
      // }

      &:before {
        color: $success-green;
      }

      &:after {
        margin-left: 40px;
      }
    }
  }

  &.tab-switch {
    input {
      + label {
        width: 50px;
        border: 2px solid $gray;
        background: rgba($lightGray, .8);
      }

      &:checked + label,
      &.on + label {
        &:after {
          margin-left: 20px;
        }
      }
    }
  }
}
