// page styles
.full-page-modal {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: fixed;
  background-color: rgba($white, .98);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  visibility: visible;
  opacity: 1;
  transition: opacity .3s ease, visibility 0s ease .3s;
  @media (max-width: 1024px) {
    display: block;
    overflow: scroll;
  }

  &__close {
    z-index: 12;
    position: absolute;
    top: 30px;
    right: 30px;

  }

  &__left {
    width: 40%;
    flex-basis: 40%;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(0deg, #fbfbfb 0%, #ffffff 100%);
    @media (max-width: 1024px) {
      width: 100%;
      padding: 100px 40px 40px;
    }
    @media (max-width: 480px) {
      padding: 100px 20px 20px;
    }

    &--scroll {
      overflow: scroll;
      align-items: flex-start;
      padding: 180px 60px 60px;
      @media (max-width: 1024px) {
        padding: 100px 40px 40px;
      }
      @media (max-width: 480px) {
        padding: 100px 20px 20px;
      }

    }

    &:before {
      content: '';
      height: 100%;
      width: 40%;
      position: fixed;
      left: 0;
      top: 0;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url('/assets/img/waves-default-hero.png');
      @media (max-width: 1024px) {
        width: 100%;
        position: absolute;
        z-index: 0;
      }
    }

    &--we {
      background: $we-secondary-gradient;

      &:before {
        background-image: url('/assets/img/we-modal-waves.png');
      }
    }

    &__heading {
      text-align: left;
      position: absolute;
      top: 30px;
      left: 60px;
      padding: 0;
      width: auto;
      @media (max-width: 1024px) {
        left: 40px;
      }
      @media (max-width: 480px) {
        left: 50%;
        transform: translateX(-50%);
        min-width: 80%;
        text-align: center;
      }

      img {
        max-width: 200px;
        @media (max-width: 480px) {
          max-width: 130px;
        }
      }

      span {
        margin: 0 10px;
      }
    }

    &__content {
      width: 100%;
      @media (max-width: 1024px) {
        position: relative;
        z-index: 1;
      }

      h1 {
        font-size: 4.874999847656254rem;
        line-height: 6.374999800781256rem;
        color: #2d2d2d;
        font-weight: 800;
        @media (max-width: 480px) {
          font-size: 2.8124912109649656rem;
          line-height: 1.2;
          text-align: center;
        }
      }

      h2 {
        font-size: 2.249999929687502rem;
        line-height: 3.3749998945312534rem;
        color: $tundora;
        font-weight: normal;
        font-family: $circular-book;
        @media (max-width: 480px) {
          font-size: 1.8749941406433104rem;
          line-height: 1.5;
        }
      }
    }


  }

  &__right {
    position: relative;
    width: 60%;
    flex-basis: 60%;
    height: 100%;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    @media (max-width: 1024px) {
      display: block;
      width: 100%;
      height: auto;
      padding: 60px 40px 200px;
    }
    @media (max-width: 480px) {
      padding: 20px 20px 200px;
    }

    &--scroll {
      overflow: scroll;
      align-items: flex-start;
      padding: 0 0 60px 0;
      @media (max-width: 1024px) {
        padding: 60px 40px 200px;
      }
      @media (max-width: 480px) {
        padding: 20px 20px 200px;
      }

    }


    &__content {
      padding: 0;
      position: relative;
      width: 95%;
      max-width: 768px;
      min-height: 100vh;
      display: flex;
      align-items: center;

      .form--note {
        font-size: 1.3124958984503172rem;

        &.is-special {
          margin-top: 40px;
        }
      }

      .form--note.is-error {
        display: none;
        padding: 10px !important;
        overflow: visible !important;
        height: auto !important;
        margin: 0 0 15px 0 !important;
      }

      .form--note.is-success {
        display: none;
      }

      .form {
        width: 340px;
        margin: 0 auto;

        .form-item {
          margin-bottom: 30px;

          &.error {
            .form-item--control {
              border: 2px solid red;
              background-color: #ffffff;
              min-width: 340px;
            }
          }

          label {
            display: block;
            color: #2d2d2d;
            opacity: .85;
            font-weight: 500;
            font-size: 1.3124958984503172rem;
            line-height: 1.2;
            margin: 0 0 8px 0;
            letter-spacing: 0;
            text-align: left;
            font-family: $circular-medium;
          }

          .form-item--control {
            border: 2px solid darken($wild-sand, 10%);
            background-color: #ffffff;
            min-width: 100%;
            color: #4b4b4b;
          }

          .toggle-group {
            p {
              color: #2d2d2d;
            }

            .toggle-switch input {
              display: block;
              clip: rect(1px, 1px, 1px, 1px);
              height: 1px;
              position: absolute !important;
              width: 1px;
            }

            .toggle-switch input.focus-visible + label {
              outline: 2px solid #757575;
            }

            .toggle-switch input + label {
              width: 100%;
              border: 2px solid #f4f4f4;
              background-color: #ffffff;
              border-radius: 4px;
              height: 52px;
              padding: 10px 20px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              span {
                width: 50%;
                text-align: center;
                display: inline-block;
                z-index: 9;
                position: relative;
              }
            }

            .toggle-switch input + label:after {
              top: 8px;
              left: 8px;
              bottom: 8px;
              width: 50%;
              background: #F4F4F4;
              box-shadow: none;
              border-radius: 4px;
              transition: left .3s ease;
              z-index: 0;
            }

            .toggle-switch input:checked + label:after,
            .toggle-switch input.on + label:after {
              left: calc(50% - 8px);
              margin: 0;
            }

            .toggle-switch input + label:before {
              display: none;
            }


          }

          &.is-select::after {
            top: 1.85em;
          }

          &.is-select.filled .form-item--control {
            padding: 0 15px;
          }

          .btn {
            width: 100%;
          }
        }

        .form--text {
          text-align: right;
          color: #4b4b4b;

          a {
            color: #4b4b4b;
            text-decoration: underline;
            font-size: 1.3124958984503172rem;
          }
        }

        .terms {
          color: #4b4b4b;
          font-size: 1.3124958984503172rem;
          border: none;

          margin: 0;
          padding: 0;

          a {
            text-decoration: underline;
          }
        }

        #brokerDealer {
          display: block;
          visibility: hidden;
          margin: 0;
          max-height: 0;
          overflow: hidden;
          transition: max-height .3s ease, margin .3s ease, visibility 0s .3s;

          &.show {
            visibility: visible;
            overflow: initial;
            margin-bottom: 30px;
            max-height: 300px;
            transition: max-height .3s ease, margin .3s ease, visibility 0s 0s;
          }
        }

      }

      .small-note-container {
        background-color: #f4f4f4;
        border-radius: 10px;
        margin: 20px 0 40px;
        padding: 20px;

      }

      .settings-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        @media (max-width: 480px) {
          flex-direction: column;
        }

        .btn-group {
          @media (max-width: 480px) {
            margin-top: 60px;
          }

          .btn-text {
            box-shadow: none;
          }

          .btn-lg {
            margin-left: 40px;
          }
        }

      }
    }

    &__bottom {
      border-top: 2px solid #f4f4f4;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 40px;
      text-align: center;
      width: 100%;
      color: #4b4b4b;
      background-color: #ffffff;

      @media (max-width: 1024px) {
        position: fixed;
      }

      @media (max-width: 480px) {
        padding: 20px 20px 40px;
      }

      p {
        margin: 0;

        a {
          text-decoration: underline;
          color: #4b4b4b;
        }
      }

    }
  }
}
