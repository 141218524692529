$color_1: #fff;
$color_2: #eee;
$color_3: inherit;
$color_4: rgba(255,255,255,0.5);
$color_5: rgba(255,255,255,0.95);
$color_6: #3f4458;
$color_7: rgba(255,255,255,0.3);
$font_family_1: inherit;
$border_color_1: rgba(0,0,0,0.3);
$border_color_2: #eee;
$border_color_3: #80cbc4;
$border_color_4: transparent;
$border_top_color_1: #3f4458;
$border_top_color_3: #fff;
$border_top_color_4: rgba(255,255,255,0.95);
$border_bottom_color_1: #3f4458;
$border_bottom_color_3: #fff;
$border_bottom_color_4: rgba(255,255,255,0.95);

.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  margin-top: calc(1rem - 2px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: $darkGray;
  box-shadow: 0 0 2rem rgba($black, .4);

  .hasWeeks {
    .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }

  .hasTime {
    .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:before {
    display: none;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
    border-width: 5px;
    margin: 0 -5px;
  }

  &:after {
    display: none;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
    border-width: 4px;
    margin: 0 -4px;
  }

  &:focus {
    outline: 0;
  }
}

.flatpickr-calendar.open {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px;
  display: block;
  position: relative;
  // top: 2px;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.hasWeeks {
  width: auto;
}

.flatpickr-calendar.showTimeInput.hasTime {
  .flatpickr-time {
    height: 40px;
    border-top: 1px solid #3f4458;
  }
}

.flatpickr-calendar.noCalendar.hasTime {
  .flatpickr-time {
    height: auto;
  }
}

.flatpickr-calendar.rightMost {
  &:before {
    left: auto;
    right: 22px;
  }

  &:after {
    left: auto;
    right: 22px;
  }
}

.flatpickr-calendar.arrowTop {
  &:before {
    bottom: 100%;
    border-bottom-color: $border_bottom_color_1;
  }

  &:after {
    bottom: 100%;
    border-bottom-color: $darkGray;
  }
}

.flatpickr-calendar.arrowBottom {
  &:before {
    top: 100%;
    border-top-color: $border_top_color_1;
  }

  &:after {
    top: 100%;
    border-top-color: $darkGray;
  }
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-month {
  // background: transparent;
  // color: $color_1;
  // fill: #fff;
  height: 38px;
  // line-height: 1;
  position: relative;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  user-select: none;
  overflow: hidden;

  fill: $lightGray;
  padding: 5px;
  text-align: center;
  position: relative;
}

.flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px calc(3.57% - 1.5px);
  z-index: 3;

  i {
    position: relative;
  }

  &:hover {
    color: $green;
    svg {
      fill: $green;
    }
  }

  svg {
    width: 14px;
    path {
      -webkit-transition: fill 0.1s;
      transition: fill 0.1s;
      fill: inherit;
    }
  }
}

.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px calc(3.57% - 1.5px);
  z-index: 3;

  i {
    position: relative;
  }

  &:hover {
    color: $green;
    svg {
      fill: $green;
    }
  }

  svg {
    width: 14px;
    path {
      -webkit-transition: fill 0.1s;
      transition: fill 0.1s;
      fill: inherit;
    }
  }
}

.flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.numInputWrapper {
  position: relative;
  height: auto;

  input {
    display: inline-block;
    width: 100%;
  }

  span {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    border: 1px solid rgba(255,255,255,0.05);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:hover {
      background: rgba(192,187,167,0.1);
    }
    &:active {
      background: rgba(192,187,167,0.2);
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 33%;
    }
    svg {
      width: inherit;
      height: auto;
      path {
        fill: rgba(255,255,255,0.5);
      }
    }
  }
  span.arrowUp {
    top: 0;
    border-bottom: 0;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid rgba(255,255,255,0.6);
    }
  }
  span.arrowDown {
    top: 50%;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(255,255,255,0.6);
    }
  }
  &:hover {
    background: rgba(192,187,167,0.05);
    span {
      opacity: 1;
    }
  }
}

.flatpickr-current-month {
  // font-size: 135%;
  // line-height: inherit;
  // font-weight: 300;
  // color: $color_3;
  // padding: 6.16px 0 0 0;
  // line-height: 1;
  // height: 28px;

  display: inline-block;
  position: absolute;
  width: 75%;
  left: 12.5%;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: $offWhite;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);

  .cur-month {
    display: inline-block;
    padding-left: 10px;
    margin-right: 2px;
    text-align: right;
    font-weight: 600;
    color: $offWhite;
  }

  .cur-year {
    display: inline-block;
    max-width: 65px;
    margin-left: 2px;
    padding: 2px 5px;
    border-radius: 4px;
    background: transparent;
    color: $offWhite;
    outline: none;
    min-height: 0;
    transition: background .2s linear;

    &:hover {
      background: rgba($gray, .2);
    }

    &:focus {
      outline: 0;
    }
  }

  .numInputWrapper {
    display: inline-block;

    &:hover {
      background: none;
    }

    span {
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      padding: 0;
      right: 5px;
      border: none;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      cursor: pointer;

      &::after {
        display: none;
      }

      &.arrowUp {
        top: 5px;
        border-bottom: 5px solid $gray;

        &:hover {
          border-bottom: 5px solid $lightGray;
        }
      }

      &.arrowDown {
        bottom: 5px;
        border-top: 5px solid $gray;

        &:hover {
          border-top: 5px solid $lightGray;
        }
      }
    }
  }
}

.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: $color_1;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;

  line-height: 30px;
  font-size: .85em;
  letter-spacing: .075em;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba($lightGray, .8);
}

.dayContainer {
  padding: 1px 0 0 0;
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 307.875px;

  &:focus {
    outline: 0;
  }
}

.flatpickr-calendar.animate {
  .dayContainer.slideLeft {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
  }
  .dayContainer.slideLeftNew {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .dayContainer.slideRight {
    -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px);
  }
  .dayContainer.slideRightNew {
    -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.flatpickr-day {
  background: none;
  border: none;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $color_5;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  transition: all .2s linear;
  font-weight: 700;
  font-size: .875em;

  &:hover {
    cursor: pointer;
    outline: 0;
    background: $gray;
  }

  &:focus {
    cursor: pointer;
    outline: 0;
    background: $gray;
  }
}

.flatpickr-day.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 rgba(100,108,140,0.98), 5px 0 0 rgba(0,0,0,0.3);
  box-shadow: -5px 0 0 rgba(100,108,140,0.98), 5px 0 0 rgba(0,0,0,0.3);
}

.flatpickr-day.prevMonthDay.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
}

.flatpickr-day.nextMonthDay.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
}

.flatpickr-day.today.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
}

.flatpickr-day.prevMonthDay.today.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
}

.flatpickr-day.nextMonthDay.today.inRange {
  cursor: pointer;
  outline: 0;
  background: rgba(0,0,0,0.3);
  border-color: $border_color_1;
}

.flatpickr-day.prevMonthDay {
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;

  &:hover {
    cursor: pointer;
    outline: 0;
    background: rgba(0,0,0,0.3);
    border-color: $border_color_1;
  }

  &:focus {
    cursor: pointer;
    outline: 0;
    background: rgba(0,0,0,0.3);
    border-color: $border_color_1;
  }
}

.flatpickr-day.nextMonthDay {
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;

  &:hover {
    cursor: pointer;
    outline: 0;
    background: rgba($gray, .8);
    border-color: $border_color_1;
  }

  &:focus {
    cursor: pointer;
    outline: 0;
    background: rgba(0,0,0,0.3);
    border-color: $border_color_1;
  }
}

.flatpickr-day.today {
  border-color: $border_color_2;
  &:hover {
    border-color: $border_color_2;
    background: #eee;
    color: $color_6;
  }
  &:focus {
    border-color: $border_color_2;
    background: #eee;
    color: $color_6;
  }
}

.flatpickr-day.selected {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;

  &:focus {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }

  &:hover {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }
}

.flatpickr-day.startRange {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_1;
  border-color: $border_color_3;

  &:focus {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }

  &:hover {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }
}

.flatpickr-day.endRange {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $color_1;
  border-color: $border_color_3;
  &:focus {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }
  &:hover {
    background: #80cbc4;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $color_1;
    border-color: $border_color_3;
  }
}
.flatpickr-day.selected.inRange {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.startRange.inRange {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.endRange.inRange {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.selected.prevMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.startRange.prevMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.endRange.prevMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.selected.nextMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.startRange.nextMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.endRange.nextMonthDay {
  background: $green;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $white;
  border-color: $border_color_3;
}
.flatpickr-day.selected.startRange {
  border-radius: 50px 0 0 50px;
  &+.endRange {
    -webkit-box-shadow: -10px 0 0 #80cbc4;
    box-shadow: -10px 0 0 #80cbc4;
  }
}
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
  &+.endRange {
    -webkit-box-shadow: -10px 0 0 #80cbc4;
    box-shadow: -10px 0 0 #80cbc4;
  }
}
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
  &+.endRange {
    -webkit-box-shadow: -10px 0 0 #80cbc4;
    box-shadow: -10px 0 0 #80cbc4;
  }
}
.flatpickr-day.selected.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.disabled {
  pointer-events: none;
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;
  &:hover {
    pointer-events: none;
    color: $color_7;
    background: transparent;
    border-color: $border_color_4;
    cursor: default;
  }
}
.flatpickr-day.notAllowed {
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;
}
.flatpickr-day.notAllowed.prevMonthDay {
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;
}
.flatpickr-day.notAllowed.nextMonthDay {
  color: $color_7;
  background: transparent;
  border-color: $border_color_4;
  cursor: default;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
  box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
}
.rangeMode {
  .flatpickr-day {
    margin-top: 1px;
  }
}
.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
  .flatpickr-weeks {
    padding: 0 12px;
    -webkit-box-shadow: 1px 0 0 #3f4458;
    box-shadow: 1px 0 0 #3f4458;
  }
  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: 28px;
  }
  span.flatpickr-day {
    display: block;
    width: 100%;
    max-width: none;
  }
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .numInputWrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 40%;
    height: 40px;
    float: left;
    span.arrowUp {
      &:after {
        border-bottom-color: $border_bottom_color_4;
      }
    }
    span.arrowDown {
      &:after {
        border-top-color: $border_top_color_4;
      }
    }
  }
  input {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    cursor: pointer;
    color: $color_5;
    font-size: 14px;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:focus {
      outline: 0;
      border: 0;
    }
  }
  input.flatpickr-hour {
    font-weight: bold;
  }
  input.flatpickr-minute {
    font-weight: 400;
  }
  input.flatpickr-second {
    font-weight: 400;
  }
  .flatpickr-time-separator {
    height: inherit;
    display: inline-block;
    float: left;
    line-height: inherit;
    color: $color_5;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .flatpickr-am-pm {
    height: inherit;
    display: inline-block;
    float: left;
    line-height: inherit;
    color: $color_5;
    font-weight: bold;
    width: 2%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    &:hover {
      background: rgba(109,118,151,0.98);
    }
    &:focus {
      background: rgba(109,118,151,0.98);
    }
  }
}
.flatpickr-time.hasSeconds {
  .numInputWrapper {
    width: 26%;
  }
}
.flatpickr-time.time24hr {
  .numInputWrapper {
    width: 49%;
  }
}
.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
            transform: translate3d(-100%, 0px, 0px);
  }
}
@keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
            transform: translate3d(-100%, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
            transform: translate3d(0, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }
}
@keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
            transform: translate3d(0, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
            transform: translate3d(100%, 0px, 0px);
  }
}
@-webkit-keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
            transform: translate3d(-100%, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
            transform: translate3d(0, 0, 0px);
  }
}
@keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
            transform: translate3d(-100%, 0, 0px);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
            transform: translate3d(0, 0, 0px);
  }
}
@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fpFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fpFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
