.open-crop {
  display: block;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 6px;
  z-index: 2;

  .day & {
    background: $darkGray;
    color: $white;
  }

  .night & {
    background: $white;
    color: $darkGray;
  }

  .icon {
    line-height: 28px;
  }
}

.crop-presets {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  a {
    display: block;
    line-height: 0;
    margin: 0 .5em;
    padding: .25em;
    border-radius: 4px;
    color: $gray;
    transition: all .15s linear;

    &:hover {
      background: rgba($green, .15);
      color: $green;
    }
  }
}

.crop-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  line-height: 0;
  width: 100%;

  .image-ratio-tools {
    display: flex;
  }

  .icon {
    font-size: 1.375em;
    line-height: 1;
  }
}
