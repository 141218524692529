/* perfect-scrollbar v0.6.10 */
.ps-container {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
  
@supports (-ms-overflow-style: none) {

  .ps-container {
    overflow: auto !important;
  }

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .ps-container {
    overflow: auto !important;
  }

}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}
  
.ps-container.ps-in-scrolling {
  pointer-events: none;
}


// X-Axis
.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  border-radius: 3px;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  
  bottom: 0;
  height: 5px;
}
    
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  border-radius: 4px;
  transition: background-color .2s linear;
  
  bottom: 0;
  height: 5px;
}

// Y-Axis
.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  border-radius: 3px;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  
  right: 1px;
  width: 5px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  border-radius: 3px;
  transition: background-color .2s linear;
  
  right: 1px;
  width: 5px;
}


// Default Colors

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background: $gray;
}


// ON SCROLL AREA HOVER

// scrollbar (rail)
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: .2;
}


// ON SCROLLBAR HOVER

// scrollbar (rail)
.ps-container:hover > .ps-scrollbar-x-rail:hover,
.ps-container:hover > .ps-scrollbar-y-rail:hover,
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail,
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  opacity: .4;
}

// scrollbar (dragger)
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x,
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {

}