body.providence {
  .table-length {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .table {
    min-width: 100%;
    border-radius: 14px;
    box-shadow: 0 2px 12px 0 rgba(#adadad, 0.15);
    background-color: $white;

    &.simple {
      border: 2px solid $lightGray;

      .day &,
      .night & {
        td,
        th {
          background: none;
          border: none;
          border-bottom: 2px solid $lightGray !important;
        }

        thead {
          td,
          th {
            background: $lightGray;
          }
        }
      }
    }

    &.sub-group {
      th,
      td {
        .night & {
          background: $darkGray !important;
          border-color: darken($darkGray, 5%) !important;
        }
      }
    }

    td,
    th {
      position: relative;
      border: 2px solid $lightGray;
      padding: 8px 10px;
      vertical-align: top;
      font-size: 1.4999953125146483rem;
      white-space: nowrap;

      .day & {
        border-color: $lightGray;
      }

      .night & {
        border-color: rgba($gray, 0.8);
      }

      .bleed & {
        &:first-child {
          padding-left: 20px;
          border-left: none;
        }

        &:last-child {
          padding-right: 20px;
          border-right: none;
        }
      }
    }

    th {
      text-align: left;

      &.actions {
        text-align: center;
      }
    }

    thead {
      td,
      th {
        font-weight: normal;

        .day & {
          background: rgba($lightGray, 0.5);
          color: $darkGray;
        }

        .night & {
          background: darken($darkGray, 5%);
          color: $white;
        }
      }
    }

    tbody {
      tr {
        transition: background 0.3s ease, opacity .3s ease;
        background-color: white;

        &:last-of-type {
          border-radius: 0 0 14px 14px;
          overflow: hidden;

          td {
            border-bottom: none !important;

            &:first-child {
              border-radius: 0 0 0 14px;
            }

            &:last-child {
              border-radius: 0 0 14px 0;
            }
          }
        }
      }

      &:hover {
        tr {
          background-color: lighten($wild-sand, 2%);
        }
      }
    }

    td {
      border-bottom: 2px solid #F0F0F0 !important;
    }

    td.actions {
      text-align: right;
      white-space: nowrap;
      width: 1%;

      &.small {
        min-width: 100px;
      }

      .bleed & {
        padding-left: 20px;
      }

      .tot_dropdown {
        display: block;

        .tot_droplist ul {
          background-color: $white;
        }
      }

      .btn {
        vertical-align: middle;
      }

      .btn-action {
        font-size: 0.9374970703216552rem;
        width: 2.75em;
        height: 2.75em;
        padding: 0;
        line-height: 2.85;
        text-align: center;
        border-radius: 50%;
        margin: 0 auto;
        border: none;
        background-color: $wild-sand;
        color: $gray;

        &:hover {
          background: $cc-light-blue;
          color: $cc-ada-blue;
        }
      }

      .open {
        .btn-action {
          background: $cc-light-blue;
          color: $cc-ada-blue;
        }
      }
    }

    td.middle {
      text-align: center;
      white-space: nowrap;

      .btn {
        vertical-align: middle;
      }
    }

    td.show-email {
      width: 1%;
      max-width: 360px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $cc-ada-blue;
      font-size: 1.3124958984503172rem;

      span.copy-text::after {
        box-shadow: none;
      }

      span.copy-text.is-red {
        color: $error-red;
      }
    }

    td.has-state {
      max-width: 13em;

      .chip {
        padding: 0.5em 1em;
        border-radius: 2em;
        letter-spacing: normal;
        text-transform: none;
        font-size: 1.1249964843859863rem;
        line-height: 1.5;
        width: 100%;
        max-width: 185px;
        margin: 0 auto;
      }
    }

    td.has-date {
      font-weight: normal;
      font-size: 1.3124958984503172rem;
      color: $mine-shaft;
    }

    td.has-assignment {
      .is-select {
        min-width: 7em;

        .form-item--control {
          background-color: $wild-sand;
          color: $gray;
          border-radius: 4px;
        }
      }
    }

    .select-control {
      margin: 0;
    }
  }

  .bleed {
    margin: 0 -20px;
  }

  th.sorting::-moz-selection {
    background: transparent;
  }

  th.sorting::selection {
    background: transparent;
  }

  th.sorting,
  th.sorting_desc,
  th.sorting_asc {
    cursor: pointer;
    position: relative;
    outline: none;
  }

  th.sorting_desc,
  th.sorting_asc {
    color: $mine-shaft !important;
    border-bottom-color: $cc-brand-blue !important;
  }

  th.sorting::-moz-selection,
  th.sorting::selection {
    background: transparent;
  }

  table th {
    &.sorting,
    &.sorting_desc,
    &.sorting_asc {
      padding-left: 15px !important;
      padding-right: 27px !important;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 10px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
      }

      &::before {
        top: 50%;
        margin-top: -10px;

        .day & {
          border-bottom-color: rgba($gray, 0.4);
        }

        .night & {
          border-bottom-color: $gray;
        }
      }

      &::after {
        bottom: 50%;
        margin-bottom: -10px;

        .day & {
          border-top-color: rgba($gray, 0.4);
        }

        .night & {
          border-top-color: $gray;
        }
      }
    }
  }

  table th.sorting_desc::after {
    .day & {
      border-top-color: $darkGray;
    }

    .night & {
      border-top-color: $white;
    }
  }

  table th.sorting_asc::before {
    .day & {
      border-bottom-color: $darkGray;
    }

    .night & {
      border-bottom-color: $white;
    }
  }

  #advisors-list,
  #advisorsList,
  #content-list,
  #content-list-admin,
  #revisions-list,
  #custom-content-list,
  #officerReports {
    tbody {
      tr {
        .advisor-profile {
          transition: transform 0.1s ease, box-shadow 0.2s linear, margin 0.2s ease;
        }

        &:hover {
          > td {
            > .advisor-profile {
              box-shadow: 0 0 0 1px $cc-brand-blue;

              // + span {
              //   transform: translateX(20px);
              // }
            }
          }
        }
      }

      > tr {
        &:hover {
          box-shadow: none;
          background-color: $white;
        }
      }
    }

    th,
    td {
      padding: 2rem 1rem;
      border: none;
      vertical-align: middle;
    }

    th {
      background: $white;
      border-left: 2px solid #F0F0F0;
      border-bottom: 2px solid #F0F0F0;
      font-family: $circular-medium;
      transition: border-bottom-color .3s ease;

      &:first-child {
        border-radius: 8px 0 0 0;
        border-left: none;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
        border-right: none;
      }

      &.show-email {
        min-width: 240px;
      }

      &.has-broker {
        width: 100px;
        min-width: 100px;
      }

      &.show-date {
        width: 160px;
      }

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      .day & {
        border-color: $offWhite;
      }

      .night & {
        border-color: $darkGray;
      }
    }

    table {
      margin: 0 1rem;
      padding: 0;
      width: calc(100% - 2rem);
      min-width: 0;
    }
  }

  #content-list th,
  #content-list-admin th,
  #advisors-list th {
    position: sticky;
    top: 86px;
    z-index: 3;
  }

  td.content-thumb {
    width: 10%;

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }

  td.content-title {
    font-size: 1.25em;
    line-height: 1.2;
    font-weight: normal;
    white-space: normal;

    a.edit-content {
      display: block;
      text-decoration: none;
      color: $white;

      &:hover {
        color: $cc-brand-blue;
      }
    }

    span.is-personas {
      display: block;
      margin-top: 0.5em;
      font-size: 0.6875em;
      font-weight: normal;
      line-height: 1.5;
      color: $lightGray;
    }
  }

  td.content-cat {
    font-weight: 700;
    color: $cc-ada-blue;
  }

  td.content-channels {
    span {
      display: inline-block;
      margin: 0 0.25em;
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      line-height: 2.5;
      text-align: center;
      font-size: 1.3124958984503172rem;
      font-weight: normal;
      background: rgba($gray, 0.25);
      color: $gray;

      &.is-ca {
        background: rgba($cc-brand-blue, 0.25);
        color: $cc-brand-blue;
      }

      &.is-lp {
        background: rgba(#8d5ed6, 0.25);
        color: #8d5ed6;
      }
    }
  }

  .tot-table {
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid $gray;

    &--header {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 0.5rem;
      background: $gray;
      color: $white;
      font-size: 0.875em;
      font-weight: normal;
    }

    &--body {
      position: relative;
      overflow: hidden;
    }

    &--row {
      position: relative;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 0.5rem;
      border-bottom: 1px solid $offWhite;

      &:last-child {
        border-bottom: none;
      }
    }

    &--cell {
      position: relative;
      max-width: 100%;
      flex: 0 0 auto;
      flex-grow: 1;
      flex-basis: 0;
      padding: 1rem 0.5rem;
      text-align: center;
      overflow: hidden;
      z-index: 1;

      &.is-left {
        text-align: left;
      }

      &.is-fitted-10 {
        flex: 0 0 10rem;
        max-width: 10rem;
      }

      &.is-fitted-12 {
        flex: 0 0 12rem;
        max-width: 12rem;
      }

      &.is-fitted-15 {
        flex: 0 0 15rem;
        max-width: 15rem;
      }

      &.is-fitted-20 {
        flex: 0 0 20rem;
        max-width: 20rem;
      }
    }
  }

}
