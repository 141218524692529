.compare {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition: all .25s linear;

  .compare-open & {
    opacity: 1;
    visibility: visible;
    transition-delay: .3s;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: 0;
    visibility: hidden;
    z-index: 97;
    transform: scaleY(0);
    transform-origin: 0 100%;
    pointer-events: none;
    transition: opacity .1s .8s linear, visibility .1s .8s linear, transform .5s .4s cubic-bezier(0.19, 1, 0.22, 1);

    .compare-open & {
      opacity: 1;
      visibility: visible;
      transform: none;
      transition-delay: 0s;
    }
  }

  &--inner {
    padding: 2rem;

    @media (min-width: $desktop-break) {
      padding: 4rem;
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @media (min-width: $tablet-break) {
      justify-content: space-between;
    }
  }

  &--logo {
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: $tablet-break) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      flex: 1 0 auto;
      margin: 0;
      width: auto;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 180px;

      @media (min-width: $tablet-break) {
        margin: 0;
      }
    }

    &-subhead {
      display: block;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: .75em;
      letter-spacing: .25em;
      font-weight: normal;
      color: $green;

      @media (min-width: $tablet-break) {
        margin-left: 1em;
      }

    }
  }

  &--intro {
    margin-bottom: 4rem;
  }

  &--plan {
    &-title {
      margin: 3rem 0;
      font-size: 2em;
      letter-spacing: normal;
      line-height: 1.1;
    }

    &-value {

      &.is-empty {
        font-size: 1.5em;
        line-height: .75;

        .is-basic & {
          color: rgba($gray, .6);
        }

        .is-highlighted & {
          color: rgba($green, .4);
        }
      }

      &.is-price {
        font-size: 1em;

        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: .1em;
          font-size: 2em;
          font-weight: 600;

          @media (min-width: $desktop-break) {
            font-size: 2.5em;
          }
        }
      }

      &.is-setup {
        font-size: .65em;
        font-weight: 400;
        opacity: .5;
      }

      .tot-icon {
        margin-top: -.25em;
        font-size: .75em;
        color: $green;

        .is-basic & {
          color: $darkGray;
        }

        &.is-green {
          color: $green;
        }
      }
    }

    &-action {
      padding: 1rem .5rem;
      font-size: .75em;

      @media (min-width: $desktop-break) {
        padding: 1rem;
      }

      @media (min-width: $large-desktop-break) {
        padding: 2rem;
        font-size: 1em;
      }
    }
  }

  &--table {
    padding: 0 0 2rem;
    overflow: hidden;

    @media (min-width: $desktop-break) {
      padding: 0 0 12rem;
    }

    &-header,
    &-footer,
    &-row {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;

      @media (min-width: $desktop-break) {
        margin: 0 -.5rem;
      }
    }

    &-header {
      display: none;

      @media (min-width: $desktop-break) {
        display: flex;
      }

      .compare--table-cell {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        &.is-descriptor {
          display: none;

          @media (min-width: $desktop-break) {
            display: block;
          }
        }

        &.is-basic {
          border-top: 2px solid $lightGray;
        }

        &.is-highlighted {
          // margin-top: -4rem;
          // padding-top: 5rem;
          border-top: 2px solid $green;

          &::before {
            display: none;
          }

          &::after {
            content: 'Fast Turnaround';
            position: absolute;
            top: 35px;
            left: -30px;
            transform: rotate(-45deg);
            width: 150px;
            line-height: 20px;
            background: $green;
            color: $white;
            font-size: .5em;
            font-weight: normal;
            letter-spacing: .1em;
            text-transform: uppercase;
            text-align: center;
          }
        }

        &.is-dark {
          border-top: 2px solid darken($darkGray, 25%);
        }
      }
    }

    &-footer {
      display: none;

      @media (min-width: $desktop-break) {
        display: flex;
      }

      .compare--table-cell {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &.is-descriptor {
          display: none;

          @media (min-width: $desktop-break) {
            display: block;
          }
        }

        &.is-basic {
          @media (min-width: $desktop-break) {
            border-bottom: 2px solid $lightGray;
          }
        }

        &.is-highlighted {
          @media (min-width: $desktop-break) {
            // margin-bottom: -4rem;
            // padding-bottom: 5rem;
            border-bottom: 2px solid $green;
          }
        }

        &.is-dark {
          @media (min-width: $desktop-break) {
            border-bottom: 2px solid darken($darkGray, 25%);
          }
        }
      }
    }

    &-body {
      position: relative;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      overflow: hidden;
    }

    &-row {
      position: relative;
      background: $lightGray;
      border: 2px solid $darkGray;
      max-width: 30em;
      margin: 0 auto 2rem;
      border-radius: 8px;
      overflow: hidden;

      @media (min-width: $desktop-break) {
        border: none;
        background: $offWhite;
        margin: 0 -.5rem;
        border-radius: 0;
        max-width: none;
        overflow: visible;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, .05);
        opacity: 0;
        transition: all .15s linear;
      }

      &:hover {
        &::before {
          opacity: 1;
        }

        .compare--table-cell {
          &::before {
            opacity: 1;
          }
        }
      }

      &.is-section-title {
        display: none;
        position: relative;

        @media (min-width: $desktop-break) {
          display: flex;
        }

        &::before,
        .compare--table-cell::before {
          display: none;
        }

        .compare--table-cell {
          z-index: auto;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 2rem;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          background: rgba($black, .15);
          z-index: 1;
        }

        span {
          display: inline-block;
          position: relative;
          padding-right: 1.5rem;
          z-index: 2;
          text-transform: uppercase;
          font-size: .75em;
          letter-spacing: .15em;
          line-height: 2.5;
          background: $offWhite;
        }
      }
    }

    &-cell {
      position: relative;
      max-width: 100%;
      flex: 0 0 auto;
      flex-grow: 1;
      flex-basis: 0;
      margin: 0 1px;
      padding: 1rem .5rem;
      font-size: .875em;
      text-align: center;
      background: $white;
      overflow: hidden;
      z-index: 1;

      @media (min-width: $desktop-break) {
        background: $offWhite;
        margin: 0 .5rem;
        padding: 1rem;
        font-size: 1em;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, .05);
        opacity: 0;
        z-index: -1;
        transition: all .15s linear;
      }

      &.is-corner {
        background: none !important;
        box-shadow: none;
      }

      &.is-descriptor {
        flex-basis: 100%;
        text-align: center;
        margin: 0;
        padding: .5em;
        background: $darkGray;
        color: $white;

        @media (min-width: $desktop-break) {
          flex-basis: 0;
          margin: 0 .5rem;
          padding: 1rem;
          padding-left: 1.5rem;
          text-align: left;
          background: $offWhite;
          color: $darkGray;
        }

        p {
          margin: 0;
        }
      }

      &.is-mobile-helper {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
        background: $lightGray;
        font-weight: 700;

        @media (min-width: $desktop-break) {
          display: none;
        }

        span {
          position: relative;
          max-width: 100%;
          flex: 0 0 auto;
          flex-grow: 1;
          flex-basis: 0;
          font-size: .75em;
          margin: 0 1px;
          padding: .5em;
          background: $offWhite;
        }
      }

      &.is-basic {
        @media (min-width: $desktop-break) {
          background: $white;
          color: $darkGray;
          border-left: 2px solid $lightGray;
          border-right: 2px solid $lightGray;
        }
      }

      &.is-highlighted {
        @media (min-width: $desktop-break) {
          background: lighten($green, 45%);
          color: $green;
          border-left: 2px solid $green;
          border-right: 2px solid $green;
        }
      }

      &.is-dark {
        @media (min-width: $desktop-break) {
          background: lighten($darkGray, 5%);
          color: $white;
          border-left: 2px solid darken($darkGray, 25%);
          border-right: 2px solid darken($darkGray, 25%);
        }
      }
    }
  }

  &--mobile-actions {
    margin-bottom: 4rem;

    @media (min-width: $desktop-break) {
      display: none;
    }
  }

  &--feature {
    a {
      color: $white;
      border-bottom: 2px solid rgba($white, .2);

      @media (min-width: $desktop-break) {
        color: $darkGray;
        border-bottom: 2px solid rgba($darkGray, .2);
      }
    }
  }
}

.value-helper {
  display: inline-block;
  cursor: pointer;
}

.compare-helper {
  display: inline-block;
  font-size: .5em;
  line-height: 1;
  vertical-align: middle;
  color: rgba($white, .8);
  cursor: pointer;
  transition: color .15s linear;

  @media (min-width: $desktop-break) {
    color: rgba($darkGray, .8);

    &:hover {
      color: $darkGray;
    }
  }

  &--content {
    position: relative;
    display: none;
    max-height: 24em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .tippy-popper & {
      display: block;
    }

    h2 {
      letter-spacing: normal;
      text-transform: none;
      font-weight: 800;
      margin: 0 0 .1em;
    }

    p {
      margin: 0 0 1.5rem;

      &:last-child {
        margin: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 1.5rem 0;
      border-radius: 6px;
    }

    a:not(.btn) {
      color: $green;
      text-decoration: none;
      border-bottom: 1px solid rgba($green, .3);
      transition: border .15s linear;

      &:hover {
        border-color: $green;
      }
    }
  }

  &--action {
    margin: 2rem .5rem;
    text-align: center;

    @media (min-width: $desktop-break) {
      margin: 3rem 0 1rem;
    }
  }
}

.tippy-tooltip.tot-theme {
  font-size: 1em;
  padding: 2rem;
  text-align: left;
  box-shadow: 0 0 80px -5px rgba($black, .15);
  background-color: $darkGray;
  color: $white;

  .tippy-roundarrow {
    color: $darkGray;
  }

  .tippy-content {
    color: $white;
  }

  &.slim-theme {
    font-size: .85em;
    padding: .3em .65em;
    font-weight: 400;
  }

  a:not(.btn) {
    color: $green;
    text-decoration: none;
    border-bottom: 1px solid rgba($green, .3);
    transition: border .15s linear;

    &:hover {
      border-color: $green;
    }
  }
}
