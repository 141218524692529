@font-face {
  font-family: 'tot-icons';
  src:
    url('../../fonts/tot-icons.ttf?ezvovz') format('truetype'),
    url('../../fonts/tot-icons.woff?ezvovz') format('woff'),
    url('../../fonts/tot-icons.svg?ezvovz#tot-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tot-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy:before {
  content: "\e14e";
}
.icon-download:before {
  content: "\e885";
}
.icon-delete:before {
  content: "\e873";
}
.icon-error:before {
  content: "\e001";
}
.icon-new-change:before {
  content: "\e031";
}
.icon-play:before {
  content: "\e039";
}
.icon-cloud-upload:before {
  content: "\e066";
}
.icon-redirect-alt:before {
  content: "\e0d7";
}
.icon-link:before {
  content: "\e157";
}
.icon-interactive:before {
  content: "\e326";
}
.icon-flip-vertical:before {
  content: "\e3e8";
}
.icon-flip-horizontal:before {
  content: "\e3e9";
}
.icon-zoom-in:before {
  content: "\e402";
}
.icon-rotate-left:before {
  content: "\e419";
}
.icon-rotate-right:before {
  content: "\e41a";
}
.icon-duplicate:before {
  content: "\e53c";
}
.icon-person_pin:before {
  content: "\e55a";
}
.icon-close-small:before {
  content: "\e5cd";
}
.icon-drag:before {
  content: "\e5d4";
}
.icon-show-more:before {
  content: "\e5d7";
}
.icon-facebook:before {
  content: "\e607";
}
.icon-twitter:before {
  content: "\e612";
}
.icon-linkedin:before {
  content: "\e61d";
}
.icon-reset:before {
  content: "\e627";
}
.icon-redirect:before {
  content: "\e629";
}
.icon-pop-out:before {
  content: "\e85b";
}
.icon-home:before {
  content: "\e88a";
}
.icon-new-window:before {
  content: "\e8e4";
}
.icon-inactive:before {
  content: "\e8f5";
}
.icon-arrow-expand:before {
  content: "\e900";
}
.icon-arrow-shrink:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-chat:before {
  content: "\e903";
}
.icon-trash:before {
  content: "\e904";
}
.icon-blog:before {
  content: "\e905";
}
.icon-cog:before {
  content: "\e906";
}
.icon-zoom-out:before {
  content: "\e907";
}
.icon-file-unknown:before {
  content: "\e924";
}
.icon-file-audio:before {
  content: "\e928";
}
.icon-file-video:before {
  content: "\e92a";
}
.icon-file-zip:before {
  content: "\e92b";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-filter:before {
  content: "\e429";
}

.social-icon,
.feature-icon,
.tot-icon {
  vertical-align: middle;
  width: 2em;
  height: 2em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
