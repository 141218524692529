.pricing {

  &--paths {
    margin: 0 auto 2rem;
    padding: 0 2rem;
    max-width: 65em;
    justify-content: center;
  }
}

.path {
  position: relative;
  padding: 2rem 0;
  text-align: center;

  @media (min-width: $tablet-break) {
    padding: 2rem;
  }

  &--wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 0;
    border-radius: 8px;
    background: $offWhite;
    border: 2px solid rgba($gray, .5);
    transition: all .15s linear;

    .is-active & {
      box-shadow: 0 0 2rem rgba($black, .1);
      background: $darkGray;
      border: none;
    }
  }

  &--content {
    flex: 1;
    padding: 3rem 3rem 0;

    .is-active & {
      color: $white;
    }
  }

  &--cta {
    padding: 0 3rem 4rem;
    width: 100%;
    text-align: center;
    line-height: 0;
    transition: all .2s linear;

    .btn {
      .is-active & {
        background: $white;
        color: $darkGray;
      }
    }
  }
}

.diy-plan {
  margin: 0 auto 4rem;
  padding: 3rem;
  max-width: 45em;
  background: $white;
  box-shadow: 0 0 3rem rgba($black, .15);
  border-radius: 8px;
  text-align: center;

  &--actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .btn {
      margin: 0 1rem;
    }
  }
}

.plans,
.premiums {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  text-align: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition: all .1s .5s linear;

  .plan-open & {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($darkGray, .7);
    opacity: 0;
    visibility: hidden;
    z-index: 97;
    pointer-events: none;
    transition: all .4s linear;

    .plan-open & {
      opacity: 1;
      visibility: visible;
    }
  }
}

.plan,
.premium {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 0;

  @media (min-width: $tablet-break) {
    height: auto;
  }

  &.is-active {
    height: auto;
    opacity: 1;
    visibility: visible;

    .plan-open &,
    .add-on-open & {
      pointer-events: auto;
    }
  }

  .btn.is-mobile {
    @media (min-width: $tablet-break) {
      display: none;
    }
  }

  .btn.is-desktop {
    display: none;

    @media (min-width: $tablet-break) {
      display: inline-block;
    }
  }

  &--left,
  &--right {
    padding: 2rem;
    text-align: left;
    transition: all .5s cubic-bezier(0.19, 1, 0.22, 1);

    @media (min-width: $tablet-break) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      width: 50%;
      top: 0;
      min-height: 100%;
      padding: 3rem;
    }

    .plan-open & {
      transform: none;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      @media (min-width: $tablet-break) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 3rem;
      }
    }
  }

  &--left {
    left: 0;
    background: $darkGray;
    transform: translate3d(-100%,0,0);
  }

  &--right {
    right: 0;
    background: $white;
    transform: translate3d(100%,0,0);
  }

  &--title {
    font-size: 6em;
    line-height: .85;
    margin-bottom: .5em;

    span {
      display: inline-block;
      margin-left: -.025em;
      padding: 0 .025em;
      background-repeat: repeat-x;
      background-position: 0 93%;
      background-size: 100% .125em;
      background-image: linear-gradient(to right, rgba($green, .2) 0%, rgba($green, .2) 100%);

      color: $green;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $green;
    }
  }

  &--tagline {
    max-width: 22em;
    font-size: 1.25em;
    line-height: 1.5;
    color: $lightGray !important;
  }

  &--disclaimer {
    font-size: .9em;
    font-weight: 600;
    color: $green;

    @media (min-width: $tablet-break) {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 3rem;
    }
  }

  &--details {
    padding: 1rem 0 0;

    @media (min-width: $tablet-break) {
      position: absolute;
      top: 10rem;
      left: 0;
      width: 100%;
      bottom: 0;
      padding: 2rem 4rem 4rem;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    &-title {
      text-transform: uppercase;
      font-size: .75em;
      letter-spacing: .25em;
      font-weight: 600;
      color: $darkGray;
    }

    h2 {
      margin: 0 -1rem 4rem;
      text-transform: none;
      letter-spacing: normal;
      font-size: 3em;
      line-height: normal;
    }

    p,
    ul {
      margin: 0 0 3rem;
    }

    a:not(.btn) {
      color: $darkGray;
      border-bottom: 2px solid $green;
      font-weight: 600;
    }
  }

  &--step {
    padding-left: 3rem;
    padding-bottom: 3rem;
    border-left: 2px solid $offWhite;

    &.is-last {
      border-left: none;
    }

    &-title {
      position: relative;
      margin-top: 0 !important;
      font-size: 1.5em !important;
      letter-spacing: normal !important;
      text-transform: none !important;
      color: $green !important;

      &::before {
        content: attr(data-number);
        display: block;
        position: absolute;
        top: 50%;
        right: calc(100% + 1px);
        transform: translateX(50%) translateY(-50%);
        margin-right: 3rem;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid $offWhite;
        background: $white;
        color: $darkGray;
        font-size: .75em;
        font-weight: 700;
        line-height: 35px;
      }
    }
  }

  &--note {
    // background: rgba($green, .15);
    // color: $green;
    background: rgba($gray, .25);
    color: $gray;
    border-radius: 6px;
    padding: 2rem 2.5rem;
    margin: 0 0 3.5rem 0;
    font-size: .85em;
    line-height: 1.5;

    p, h4 {
      color: $gray;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &.is-important {
      background: rgba($errorColor, .1);
      color: $errorColor;

      p, h4 {
        color: $errorColor;
      }
    }

    &.is-caution {
      background: rgba(#fcc903, .5);
      color: darken(#fcc903, 20%);

      p, h4 {
        color: darken(#fcc903, 20%);
      }
    }

    &.is-success {
      background: rgba(#1DCAA5, .2);
      color: #1DCAA5;

      p, h4 {
        color: #1DCAA5;
      }
    }
  }
}
