.tot_tip {
  position: relative;
  cursor: pointer;

  &.is-contained {
    &::after {
      width: 17em;
      white-space: normal;
      text-align: center;
    }
  }

  &::before,
  &::after {
    position: absolute;
    opacity: 0;
    z-index: 9999;
    pointer-events: none;
    transition: all 0.2s ease;
  }

  &::before {
    content: '';
  }

  &::after {
    content: attr(data-content);
    padding: .325em .825em;
    border-radius: 4px;
    font-size: .6875em;
    font-weight: normal;
    line-height: 1.65;
    text-transform: none;
    white-space: nowrap;

    .day & {
      background: $darkGray;
      color: $white;
    }

    .night & {
      background: $white;
      color: $gray;
    }
  }

  &:hover {
    &::before,
    &::after {
      opacity: 1;
      pointer-events: auto;
    }
  }

  // Top
  &.top {
    &::before,
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 6px);
      transform-origin: top;
    }

    &::before {
      height: 5px;
      width: 15px;
      margin-bottom: 6px;

      .day & {
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"%3E%3Cpath fill="%232d2d2d" transform="rotate(0)" d="M2.56,0H33.44C27.65,0,22.22,12,18,12S8.29,0,2.56,0Z"/%3E%3C/svg%3E') no-repeat;
        background-size: 100% auto;
      }

      .night & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="#{$white}" transform="rotate(0)" d="M2.56,0H33.44C27.65,0,22.22,12,18,12S8.29,0,2.56,0Z"/></svg>') no-repeat;
        background-size: 100% auto;
      }
    }

    &::after {
      margin-bottom: 10px;
    }

    &:hover {
      &::before,
      &::after {
        transform: translate(-50%, 0);
      }
    }

    // Top (Left)
    &.top-left {
      &::after {
        left: auto;
        right: -.5em;
        transform: translate(0, 6px);
      }

      &:hover {
        &::after {
          transform: translate(0,0);
        }
      }
    }

    // Top (Right)
    &.top-right {
      &::after {
        left: -10px;
        transform: translate(0, 6px);
      }

      &:hover {
        &::after {
          transform: translate(0,0);
        }
      }
    }
  }

  // Bottom
  &.bottom {
    &::before,
    &::after {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -6px);
      transform-origin: bottom;
    }

    &::before {
      height: 5px;
      width: 15px;
      margin-top: -3px;

      .day & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="#{$darkGray}" transform="rotate(0)" d="M33.44,12H2.57C8.35,12,13.78,0,18,0S27.71,12,33.44,12Z"/></svg>') no-repeat;
        background-size: 100% auto;
      }

      .night & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="#{$white}" transform="rotate(0)" d="M33.44,12H2.57C8.35,12,13.78,0,18,0S27.71,12,33.44,12Z"/></svg>') no-repeat;
        background-size: 100% auto;
      }
    }

    &::after {
      margin-bottom: 0;
      margin-top: 2px;
    }

    &:hover {
      &::before,
      &::after {
        transform: translate(-50%, 0);
      }
    }

    // Bottom (Left)
    &.bottom-left {
      &::after {
        left: auto;
        right: -10px;
        transform: translate(0, -6px);
      }

      &:hover {
        &::after {
          transform: translate(0,0);
        }
      }
    }

    // Bottom (Right)
    &.bottom-right {
      &::after {
        left: -10px;
        transform: translate(0, -6px);
      }

      &:hover {
        &::after {
          transform: translate(0,0);
        }
      }
    }
  }

  // Left
  &.left {
    &::before {
      bottom: 50%;
      right: 100%;
      left: auto;
      transform: translate(0, 50%);
      transform-origin: middle;
      height: 15px;
      width: 5px;
      margin-bottom: 0;

      .day & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="#{$darkGray}" transform="rotate(0)" d="M0,33.4L0,2.6C0,8.3,12,13.8,12,18S0,27.7,0,33.4z"/></svg>') no-repeat;
        background-size: 100% auto;
      }

      .night & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="#{$white}" transform="rotate(0)" d="M0,33.4L0,2.6C0,8.3,12,13.8,12,18S0,27.7,0,33.4z"/></svg>') no-repeat;
        background-size: 100% auto;
      }
    }

    &::after {
      bottom: 50%;
      right: 100%;
      transform: translate(0, 50%);
      transform-origin: middle;
      left: auto;
      margin: 0 5px 0 0;
    }

    &:hover {
      &::before,
      &::after {
        transform: translate(-6px, 50%);
      }
    }
  }

  // Right
  &.right {
    &::before {
      bottom: 50%;
      left: 100%;
      right: auto;
      transform: translate(0, 50%);
      transform-origin: middle;
      height: 15px;
      width: 5px;
      margin-bottom: 0;

      .day & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="#{$darkGray}" transform="rotate(0)" d="M0,18c0-4.2,12-9.7,12-15.4v30.8C12,27.7,0,22.2,0,18z"/></svg>') no-repeat;
        background-size: 100% auto;
      }

      .night & {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="#{$white}" transform="rotate(0)" d="M0,18c0-4.2,12-9.7,12-15.4v30.8C12,27.7,0,22.2,0,18z"/></svg>') no-repeat;
        background-size: 100% auto;
      }
    }

    &::after {
      bottom: 50%;
      left: 100%;
      transform: translate(0, 50%);
      transform-origin: middle;
      right: auto;
      margin: 0 0 0 5px;
    }

    &:hover {
      &::before,
      &::after {
        transform: translate(6px, 50%);
      }
    }
  }

  // large button adjustments
  &.btn-lg {
    &.bottom {
      &::before,
      &::after {
        top: 120%;
      }
    }
  }
}
