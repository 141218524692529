.jump-start {
  padding: 3rem 2rem;
  max-width: 70em;
  margin: 0 auto;
  text-align: center;

  p {
    font-size: 1.25em;
    color: $gray;

    &.hero {
      font-size: 2.25em;
      margin: 0;
      line-height: 1.5;
      color: $darkGray;
    }
  }

  .form {
    max-width: 50em;
    margin: 0 auto 1rem;

    .form-errors {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      text-align: left;
      margin-top: .75em;
      font-weight: 700;
      font-size: .75em;
      color: $errorColor;
      opacity: 0;
      transition: all .15s linear;

      &.is-showing {
        opacity: 1;
      }
    }
  }
}

// Intro Section
#home-intro {
  position: relative;
  padding: 30px 0;
  background: $darkGray;
  background: radial-gradient(ellipse at bottom, lighten($darkGray, 12%) 0%, darken($darkGray, 8%) 95%);
  text-align: center;
  overflow: hidden;

  @media (min-width: $tablet-break) {
    padding: 20px 0 0;
  }

  .btn-group {
    margin: 2rem 0;

    .btn {
      min-width: 13.5em;
    }
  }

  p {
    br {
      display: none;

      @media (min-width: 1260px) {
        display: block;
      }
    }
  }
}

.home-content {
  max-width: 60em;
  margin: 4rem auto 0;
  animation: 1.4s cubic-bezier(.19, 1, .22, 1) .4s both fadeInHero;

  @media (min-width: $tablet-break) {
    margin: 0 auto;
  }
}

.intro-screens {
  position: relative;
  margin: 0 auto;

  @media (min-width: $tablet-break) {
    width: 700px;
    height: 320px;
    left: -100px;
    transform-origin: bottom;
  }

  @media (max-width: $desktop-break - 1) {
    transform: scale(.75) !important;
  }

  @media (min-width: $desktop-break) {
    left: -60px;
    height: 400px;
    transform: none;
    transform-origin: center;
  }

  .screen {
    display: none;
    position: absolute;
    bottom: -50px;
    height: auto;
    animation: 1.4s linear .6s both bounceInUp;
    box-shadow: 0 0 20px rgba($black, .25);

    img {
      display: block;
      width: 100%;
      height: auto;
      pointer-events: none;
    }

    .rotate {
      position: absolute;
      overflow: hidden;
      box-shadow: 0 0 0 1px $lightGray;

      .slide {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        transition: width .6s cubic-bezier(.645, .045, .355, 1.000);

        &.active {
          width: 100%;
          z-index: 2;
        }

        &.prev {
          width: 100%;
          z-index: 1;
        }
      }
    }

    &.desktop {
      background: $gray;
      border-radius: 5px;
      animation-delay: .5s;
      z-index: 1;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 22px;
        background: $white;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 12px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $lightGray;
        box-shadow: 0 0 0 2px $lightGray, 14px 0 0 2px $lightGray, 28px 0 0 2px $lightGray;
      }

      .rotate {
        top: 22px;
        bottom: 0;
        left: 0;
        right: 0;
      }

      @media (min-width: $tablet-break) {
        display: block;
        width: 700px;
        height: 422px;
      }

      @media (min-width: $desktop-break) {
      }
    }

    &.tablet {
      background: $white;
      border-radius: 10px;
      animation-delay: .7s;
      z-index: 2;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        left: 50%;
        margin-left: -2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: rgba($gray, .7);
      }

      .rotate {
        top: 38px;
        bottom: 38px;
        left: 10px;
        right: 10px;
      }

      @media (min-width: $tablet-break) {
        display: block;
        width: 250px;
        height: 336px;
        right: -125px;
      }

      @media (min-width: $desktop-break) {
      }
    }

    &.phone {
      background: $white;
      border-radius: 15px;
      animation-delay: .9s;
      z-index: 3;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -18px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $lightGray;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 50%;
        margin-left: -10px;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background: rgba($gray, .7);
      }

      .rotate {
        top: 34px;
        bottom: 34px;
        left: 5px;
        right: 5px;
      }

      @media (min-width: $tablet-break) {
        display: block;
        width: 140px;
        height: 248px;
        right: -195px;
      }

      @media (min-width: $desktop-break) {
      }
    }
  }
}

.theme-indicator {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2rem;
  padding: .75em 1em .65em 11.5em;
  background: rgba($black, .4);
  color: $lightGray;
  font-size: 10px;
  line-height: 13px;
  font-weight: normal;
  letter-spacing: .18em;
  text-transform: uppercase;
  border-radius: 4px;
  z-index: 3;
  overflow: hidden;
  transition: background .2s linear;

  &:hover {
    background: $black;
  }

  @media (min-width: $tablet-break) {
    display: block;
  }

  &:before {
    content: 'Framework';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: .75em 1em .65em;
    border-right: 1px solid $darkGray;
  }

  .name {
    display: block;
    position: relative;
    color: $green;
    text-align: left;
    overflow: hidden;

    span {
      white-space: nowrap;
    }
  }
}

.hero-big .tot_tip {
  border-bottom: 2px solid #626262;
  transition: border .2s linear;

  &:after {
    width: 350px;
    padding: 15px;
    white-space: normal;
  }

  &:hover {
    border-color: $green;
  }
}

.screen-mask {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45px;
  background-image: -o-linear-gradient(-90deg, rgba(45,45,45,.00) 0%, #2d2d2d 100%);
  background-image: -moz-linear-gradient(-90deg, rgba(45,45,45,.00) 0%, #2d2d2d 100%);
  background-image: -ms-linear-gradient(-90deg, rgba(45,45,45,.00) 0%, #2d2d2d 100%);
  background-image: linear-gradient(-180deg, rgba(45,45,45,.00) 0%, #2d2d2d 100%);
  opacity: .15;
  z-index: 2;

  @media (min-width: $tablet-break) {
    display: block;
  }
}
// END: Intro Section


// Content Assist callout

#assist-callout {
  margin: -10px 0;

  &.is-inline {
    position: relative;
    width: auto;
    margin: 2rem;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid $green;

    @media (min-width: $tablet-break) {
      width: 100%;
    }

    .assist-logo {
      img {
        width: 100%;
        max-width: 400px;
        height: auto;
      }
    }

    .assist-content {
      background: $green;
      padding: 3rem 2.5rem;

      @media (min-width: $tablet-break) {
        padding: 4rem;
      }
    }
  }

  .assist-bg {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 1px;
    width: 55%;
    background: transparent url('/assets/img/ca-callout-bg-2.svg') right center no-repeat;
    background-size: auto 100%;

    @media (min-width: $tablet-break) {
      display: block;
    }
  }

  .assist-logo {
    padding: 4rem;

    @media (min-width: $tablet-break) {
      flex-basis: 55%;
      max-width: 55%;
      padding: 4rem 12rem 4rem 4rem;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 400px;
      height: 80px;
    }
  }

  .assist-content {
    padding: 4rem;
    text-align: center;
    background: $green;
    background: linear-gradient(85deg, $green 30%, #3ed3cf 100%);

    @media (min-width: $tablet-break) {
      flex-basis: 45%;
      max-width: 45%;
      padding: 4rem 4rem 4rem 0;
    }

    p {
      max-width: 26em;
      margin: 0 auto 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      color: $green;
    }
  }
}

// END: Content Assist callout


// Featured Banner Section
#featured-banner {
  text-align: center;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1400px;

  @media (min-width: $tablet-break) {
    padding: 0 40px 0 30px;
  }

  h3 {
    color: rgba($gray, .6);
  }
}

.featured-logo {
  min-width: 0;

  @media (min-width: $tablet-break) {
    padding: 20px 5px;
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -webkit-backface-visibility: hidden;
    opacity: .8;
    transition: opacity .3s linear;

    &:hover {
      opacity: 1;
    }
  }
}

// END: Featured Banner Section

// Features List Section
#features-list {
  text-align: center;

  .feature {
    margin: 20px 0;
    padding: 0 30px;

    @media (min-width: $tablet-break) {
      margin: 30px 0;
    }

    img {
      display: block;
      width: 180px;
      height: 180px;
      margin: 0 auto;
    }
  }
}
// END: Features List Section

// Preview Sections
.preview-caption {
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 700px;

  @media (min-width: $tablet-break) {
    padding: 0 5rem;
  }
}

.home-screenshot {
  margin: 4rem 0;
  max-width: 680px;

  @media (min-width: $desktop-break) {
    max-width: 760px;
  }

  &.to-left {
    margin-right: auto;
    padding-right: 3rem;

    @media (min-width: $tablet-break) {
      padding-right: 0;
    }
  }

  &.to-right {
    margin-left: auto;
    padding-left: 3rem;

    @media (min-width: $tablet-break) {
      padding-left: 0;
    }
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.browser-gui {
  position: relative;
  padding-top: 30px;
  background: $lightGray;
  border-radius: 4px;
  overflow: hidden;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  .to-left & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 20px 30px 70px rgba($black, .2);

    img {
      border-bottom-right-radius: 2px;
    }
  }

  .to-right & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: -20px 30px 70px rgba($black, .2);

    img {
      border-bottom-left-radius: 2px;
    }
  }

  .browser-bar {
    position: absolute;
    top: 5px;
    left: 7px;

    span.browser-dot {
      display: block;
      float: left;
      margin: 5px 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgba($gray, .4);
    }
  }
}

.learn-more {
  text-align: center;
}
// END: Preview Sections


// Callout
.callout {
  position: relative;
  background: $green;
  background: linear-gradient(120deg, #08aeea 0%, $green 100%);
  color: $white;
  padding: 4rem 0;
  text-align: center;

  .wrapper {
    &.is-inline {
      @media (min-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
      }

      p {
        margin: 0;
        flex: 1;
        max-width: 22em;
      }
    }
  }

  h1 {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 .5em;
  }

  .btn {
    mix-blend-mode: screen;
  }
}
// END: Callout


/*----- Page Overlays -----*/

.page-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 99;

  .overlay-content {
    padding: 2em 0;

    @media (min-width: $tablet-break) {
      margin: 0;
      padding: 4em 0;
    }

    &.has-note {
      margin: 0 0 4em;

      @media (min-width: $tablet-break) {
        margin: 4em 0 0;
      }
    }

    &.contain {
      position: relative;
      height: 100%;
      padding: 6rem 20px;

      .image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100%;
        padding-bottom: 3em;
        animation: 1.8s linear .45s both zoomIn;
        backface-visibility: hidden;

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
          border-radius: 4px;
          box-shadow: 0 0 20px 0 rgba($darkGray, .2);
        }

        .caption {
          position: relative;
          height: 0;
          top: 2rem;
          text-align: center;

          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.login,
.register {
  .overlay-content {
    padding: 2em 0;

    @media (min-width: $tablet-break) {
      padding: 4em 0;
    }

    &.has-note {
      margin: 0 0 130px;

      @media (min-width: $tablet-break) {
        margin: 100px 0 0;
      }
    }
  }

  .copyright {
    margin: 0 0 10px;
    text-align: center;
    color: rgba($gray, .8);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  .social-links {
    padding: 0;
    text-align: center;
  }

  &.day {
    .social-links a {
      color: $lightGray;

      &:hover {
        color: $green;
      }
    }
  }

  &.night {
    .social-links a {
      color: $gray;

      &:hover {
        color: $green;
      }
    }
  }
}

.page-overlay,
.login,
.register {
  &.day {
    background: $offWhite;
    color: $darkGray;
  }

  &.night {
    background: $darkGray;
    color: $white;
  }

  .legacy-note {
    position: fixed;
    bottom: 0;
    left: 20px;
    right: 20px;
    margin-bottom: -20px;
    padding: 20px 20px 40px;
    text-align: center;
    background: darken($darkGray, 5%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    z-index: 2;

    @media (min-width: $tablet-break) {
      top: 0;
      bottom: auto;
      left: 50%;
      width: 390px;
      margin-top: -20px;
      margin-left: -195px;
      padding: 40px 20px 20px;
      font-size: 16px;
      border-radius: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.show {
      animation: 1s linear .7s both bounceInUp;

      @media (min-width: $tablet-break) {
        animation: 1s linear .7s both bounceInDown;
      }
    }

    .btn {
      margin: 10px 0 0 !important;
    }

    & + .overlay-logo {
      margin: 6em auto 10px;
    }
  }

  .overlay-logo {
    display: block;
    margin: 0 auto 2rem;
    width: 4em;
    height: 4em;

    a {
      display: block;
    }

    img {
      display: block;
      width: 4em;
      height: 4em;
    }
    &.insurmark {
      margin: 0 auto;
      margin-bottom: 1rem;
      width: 15em;
      height: auto;
      img {
        width: 15em;
        height: auto;
      }
    }
  }



  .row {
    height: 100%;
  }

  .form-container {
    width: 80%;
    max-width: 390px;
    margin: 0 auto;
    animation: 1.8s linear .45s both zoomIn;

    form {
      position: relative;
      padding-top: 10px;
    }

    .wrapper {
      width: auto;

      @media (min-width: $tablet-break) {
        padding: 0 40px;
      }
    }

    .form-note {
      margin: 1rem -4rem;
      color: $lightGray;

      @media (min-width: $tablet-break) {
        margin: 2rem -6rem;
        font-size: 1.25em;
        line-height: 1.5;
      }

      strong {
        color: $white;
      }
    }

    .form-tab {
      display: none;

      &.active {
        display: block;
      }
    }

    .account-error {
      display: none;
      padding: 0;

      span {
        display: block;
        margin: 0;
        padding: 1.5em;

      }
    }

    .success-message {
      display: none;

      p {
        margin-bottom: 0;

        span {
          display: block;
          font-weight: normal;
        }
      }
    }
  }

  .select-wrapper {
    margin: 0;
    padding: 0 0 34px;

    .select-control {
      margin: 0;
    }
  }

  .btn.primary {
    margin-bottom: 10px;
  }

  .btn-group {
    margin: 15px 0 30px;
    text-align: center;
  }
}


.page-header {
  position: relative;
  margin: 0 auto 5rem;
  max-width: 55em;
  text-align: center;
  z-index: 2;

  &.flush-bottom {
    margin-bottom: 0;
  }
}

.section-header {
  text-align: center;
  margin: 0 auto 30px;
}


.page-intro {
  position: relative;
  background: $offWhite;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('/assets/img/bg-guilloche.svg') center center repeat;
    background-size: 2800px;
    opacity: .05;
  }

  &.simple {
    &::before {
      display: none;
    }
  }

  &.clean {
    background: $white;

    &::before {
      display: none;
    }
  }
}


.showcase-intro {
  background: $white;

  &::before {
    display: none;
  }
}

.showcase-item {
  display: block;
  position: relative;
  margin: 1rem 1rem 5rem;

  @media (min-width: $tablet-break) {
    margin: 40px;

    &:hover {
      .screenshot {
        box-shadow: 0 5px 20px 0 rgba($darkGray, .3);
        transform: translateY(-10px);
        transition: all .4s ease;
      }

      .credit {
        opacity: 1;
        transform: none;
        transition: opacity .2s .2s linear, transform .4s .2s ease;
      }
    }
  }

  .screenshot {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 15px 0 rgba($darkGray, .1);
    transition: all .4s ease;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .credit {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    transition: opacity .2s linear, transform .4s ease;

    @media (min-width: $tablet-break) {
      opacity: 0;
      transform: translateY(-3px);
    }

    h4 {
      margin: 10px 0 0;
    }
  }
}

.showcase-more {
  padding: 1rem;

  @media (min-width: $tablet-break) {
    padding: 40px;
  }

  .note {
    position: relative;
    height: 0;
    padding-top: 58%;

    .note-content {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $lightGray;
      border-radius: 4px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url('/assets/img/bg-guilloche.svg') center center no-repeat;
        background-size: 1200px;
        opacity: .075;
      }
    }

    h3 {
      margin: auto;
      color: rgba($gray, .8);
    }
  }
}


.pricing-intro {
  background: $white;

  &::before {
    display: none;
  }
}

.pricing-compare {
  text-align: center;
  margin: 4rem 0;
  font-weight: normal;
  line-height: 2.5;

  br {
    @media (min-width: $tablet-break) {
      display: none;
    }
  }

  .btn {
    @media (min-width: $tablet-break) {
      margin-left: .5rem;
    }
  }
}

.pricing-bg {
  padding: 0 0 7vw;
  background: $white url(/assets/img/bg-wave-up-gray.svg) 0 102% no-repeat;
  background-size: 100% auto;

  &::after {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 5.8888%;
  }
}

.ca-faq,
.pricing-faq {
  padding: 0 0 4rem;
  background: #e3e3e4;

  .faq-list {
    max-width: 820px;
    margin: 0 auto;
    padding: 4rem 0 6rem;

    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .faq {
    padding: 1.5rem 0;
    border-top: 2px solid rgba($gray, .2);

    &:last-child {
      border-bottom: 2px solid rgba($gray, .2);
    }

    a.faq-title {
      margin: .25em 0;
      padding: .5em 0;
      padding-right: 50px;
      font-size: 1.25em;
    }

    .faq-content {
      padding-bottom: 1rem;

      p {
        color: $darkGray;
      }
    }
  }

  .toggle-reveal {
    .reveal-icon {
      width: 30px;
      height: 30px;
      border: 2px solid $gray;
      left: auto;
      right: 0;
      top: 50%;
      opacity: .6;
      transform: translateY(-50%);

      &::before,
      &::after {
        background: $gray;
      }

      &::before {
        width: 10px;
        height: 2px;
      }

      &::after {
        width: 2px;
        height: 10px;
      }
    }
  }
}

.ca-faq {
  background: none;
}

.pricing-footer {
  background: #e3e3e4 !important;
  padding: 0 !important;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background: $darkGray;
    height: 25%;
    width: 100%;
    bottom: 0;
    left: 0;

    @media (min-width: $desktop-break) {
      height: 50%;
    }
  }

  .split-callout .callout-mini a {
    padding: 4rem;
    background: $white url(/assets/img/bg-wave-grayscale.svg) center top no-repeat;
    background-size: cover;
  }
}

.billed-toggle,
.compare-toggle {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  .toggle-switch.tab-switch input + label {
    border-color: transparent;
    background: $gray;
  }

  .toggle-switch input + label::after {
    box-shadow: none;
  }

  p {
    margin: 0 10px;
    color: rgba($gray, .8);
    font-size: .75em;
    line-height: 30px;
    font-weight: normal;
    min-width: 90px;
    transition: color .3s linear;

    &:first-child {
      text-align: right;
    }

    &.active {
      color: $darkGray;
    }
  }
}

.pricing-subscription {
  position: relative;
  margin: 0 auto;
  padding: 20px 15px;
  max-width: 490px;
  background: $darkGray;
  border-radius: 8px;
  // box-shadow: 0 10px 40px rgba($black, .2);

  @media (min-width: $desktop-break) {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: -260px;
    // padding-bottom: 230px;
    max-width: 580px;
  }

  .rate {
    margin: 0 0 30px;
    padding: 0 0 2rem;
    text-align: center;
    line-height: 50px;
    border-bottom: 2px solid rgba($gray, .8);
    min-width: 14em;

    @media (min-width: $desktop-break) {
      border-bottom: 0;
      margin: 0 1rem 0 0;
      padding: 0 35px 0 0;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      color: $white;

      &.currency {
        margin-right: 5px;
        font-size: 18px;
      }

      &.amount {
        font-size: 60px;
        font-weight: 400;
        letter-spacing: -.05em;
        text-align: left;
      }

      &.frequency {
        margin-left: 5px;
        font-size: 20px;
        color: rgba($lightGray, .4);
      }

      &.applies {
        display: block;
        line-height: 1;
        margin-top: 1rem;
        text-align: center;

        span {
          display: inline-block;
          font-size: .55em;
          background: darken($darkGray, 5%);
          padding: .85em 1em .75em;
          border-radius: 4px;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: .2em;
          color: rgba($lightGray, .8);
        }
      }
    }
  }

  .pricing-overview {
    position: relative;
    padding: .5em 0;
    height: 9em;
    overflow: hidden;
    color: $lightGray;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: .75em;
      z-index: 2;
    }

    &::before {
      top: 0;
      background: linear-gradient(to top, rgba($darkGray, 0) 0%, $darkGray 85%);
    }

    &::after {
      bottom: 0;
      background: linear-gradient(to bottom, rgba($darkGray, 0) 0%, $darkGray 85%);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      &.moving {
        animation: ticker 12s cubic-bezier(1, 0, .5, 0) both;
      }

      li {
        margin: 0;
        line-height: 2;
        white-space: nowrap;

        &::before {
          top: .65em;
        }
      }
    }
  }
}

// Split Options Section
#pricing-options {
  padding-top: 0;

  .split {
    position: relative;
    padding: 0 30px;
    max-width: 550px;
    margin: 0 auto;
    z-index: 4;

    @media (min-width: $desktop-break) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1200px;
      padding: 3rem 0;
    }
  }

  .option {
    text-align: center;
    position: relative;
    margin: 0 0 30px;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid transparent;
    overflow: hidden;
    transition: all .4s ease;
    background: $white;
    box-shadow: 0 0 3rem 0 rgba($black, .15);

    &:last-child {
      margin-bottom: 0;
    }

    &.is-featured {
      border-color: $green;

      &::before {
        content: 'Fast Turnaround';
        position: absolute;
        top: 35px;
        left: -30px;
        transform: rotate(-45deg);
        width: 150px;
        line-height: 20px;
        background: $green;
        color: $white;
        font-size: .5em;
        font-weight: normal;
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: center;
      }
    }

    @media (min-width: $tablet-break) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      // flex: 0 1 45%;
      // margin: 0 2px;
      flex: 0 1 calc(33.3333% - 4px);
      margin: 0 -2px;
      padding: 3rem;

      &.is-middle {
        margin-top: -3rem;
        margin-bottom: -3rem;
        margin-left: -5px;
        margin-right: -5px;
        padding-top: 6rem;
        padding-bottom: 6rem;
        z-index: 3;
      }

      &.is-featured {
        .option-header {
          p {
            color: rgba($green, .6);
          }
        }

        .detail-price {
          color: $green;
        }
      }
    }

    .option-header {
      margin-bottom: 2rem;

      h1 {
        font-size: 1.5em;
      }

      p {
        margin: 0;
        font-weight: 700;
        font-size: .875em;
        color: rgba($gray, .6);
      }
    }

    .option-details {
      max-width: 380px;
      min-height: 4.5em;
      margin: 0 auto;
      text-align: left;
      counter-reset: section;
      text-align: center;
      margin-bottom: 3rem;
    }

    .detail-title {
      position: relative;
      margin-bottom: 5px;
      padding-left: 40px;
      color: $white;
      text-transform: none;
      letter-spacing: .05em;
      font-size: 16px;
      font-weight: 600;

      &::before {
        counter-increment: section;
        content: counter(section);
        display: block;
        left: 0;
        top: 50%;
        position: absolute;
        width: 25px;
        height: 25px;
        line-height: 24px;
        text-align: center;
        border: 1px solid rgba($white, .3);
        border-radius: 50%;
        letter-spacing: 0;
        transform: translateY(-50%);
        margin-top: -1px;
        font-size: .65em;
      }
    }

    .detail-price {
      font-size: 3.5em;
      letter-spacing: normal;
      font-weight: normal;
      line-height: 1;
      color: $darkGray;

      span {
        font-size: .35em;
        vertical-align: middle;
        display: inline-block;
        line-height: 1;
        margin: 0 .25em .25em -.65em;
      }
    }

    .detail-desc {
      margin: 0 auto 2rem;
      max-width: 23em;
      color: rgba($gray, .8);
      font-size: .85em;
      line-height: 1.65;
    }

    .option-actions {
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      padding: 2rem 4rem;
      // background: rgba($black, .1);

      .btn {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
// END: Split Options Section


.pricing-fineprint {
  text-align: center;
  margin: 4rem 0 10rem;
}


.pricing-help {
  margin: 4rem 0 8rem;
  text-align: center;
}


.bundle-item {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
  border-top: 2px solid $lightGray;

  &:last-child {
    border-bottom: 2px solid $lightGray;
  }

  span.note {
    color: rgba($gray, .8);
  }

  &.social-media {
    background: $lightGray;

    &::before {
      background: linear-gradient(to top, rgba($offWhite,0) 0%, rgba($offWhite,.25) 50%, rgba($offWhite,.5) 100%);
    }
  }

  &.branding {
    background: $darkGray;

    &::before {
      background: linear-gradient(to top, rgba($gray,0) 0%, rgba($gray,.25) 50%, rgba($gray,.5) 100%);
    }

    span.note {
      color: rgba($lightGray, .8);
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: auto;
    padding: 0 4rem;
  }
}

.bundle-title {
  max-width: 300px;

  h2 {
    margin-top: 5px;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: 3px;
    font-weight: 300;
    text-transform: uppercase;

    small {
      display: block;
      font-size: 16px;
      font-weight: 600;
      text-transform: none;
      color: rgba($darkGray, .8);

      .night & {
        color: $offWhite;
      }
    }
  }
}

ul.price-includes {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: rgba($gray, .8);

  .night & {
    color: rgba($lightGray, .8);
  }
}

.bundle-desc {
  margin-top: 25px;
  font-size: 12px;
  line-height: 18px;
  color: $gray;

  .night & {
    color: rgba($lightGray, .8);
  }

  p {
    margin: 0;
    padding-bottom: 1rem;
    font-size: 18px;
    line-height: 24px;
    color: $darkGray;

    .night & {
      color: $white;
    }
  }
}

.bundle-extras {
  padding-bottom: 20px;

  .extras-content {
    padding: 30px;
    border-radius: 4px;
    background: $offWhite;

    .night & {
      background: rgba($gray, .4);
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        display: block;
      }
    }
  }
}

.bundle-cost {
  // display: flex;
  // align-items: center;
  position: relative;
  max-width: 240px;

  .price {
    position: relative;
    font-size: 42px;
    line-height: 80px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $green;
    text-align: right;

    @media (min-width: $tablet-break) {
      margin: 30px 0 30px 40px;
      padding-left: 50px;
    }

    @media (min-width: $tablet-break) {
      margin: 30px 0 30px 50px;
    }

    &::before {
      content: ' ';
      display: none;
      position: absolute;
      top: 0;
      left: -1px;
      height: 100%;
      width: 2px;
      background: $lightGray;
      border-radius: 2px;

      @media (min-width: $tablet-break) {
        display: block;
      }

      .social-media & {
        background: rgba($gray, .2);
      }

      .branding & {
        background: $gray;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      line-height: 1;

      &.currency {
        font-size: 15px;
        margin-right: 5px;
      }

      &.note {
        position: absolute;
        right: 3px;
        font-size: 12px;
        top: 100%;
        letter-spacing: .5px;
        white-space: nowrap;
      }
    }
  }
}

.split-callout {
  position: relative;

  @media (min-width: $desktop-break) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2rem;
  }

  .callout-mini {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 0 1 50%;
    max-width: 520px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    padding: 1rem 0;

    @media (min-width: $desktop-break) {
      margin: 0;
      padding: 0 2rem;
    }

    a {
      display: block;
      position: relative;
      height: 100%;
      padding: 30px;
      text-align: left;
      background: $white;
      box-shadow: 0 0 40px rgba($black, .1);
      border-radius: 6px;
      transition: transform .2s ease;

      &:hover {
        transform: translateY(-.5rem);

        .callout-title {
          img {
            transform: translate3d(10px,0,0);
          }
        }

        p {
          color: $darkGray;
        }
      }

      .callout-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;

        img {
          display: inline-block;
          height: .75em;
          transition: all .2s ease;
        }
      }

      p {
        color: rgba($darkGray, .8);
        transition: all .2s linear;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}


// Company

.company-intro {
  padding: 150px 0 200px !important;

  &::before {
    background-image: url('/assets/img/bg-etch.svg');
    // content: ' ';
    // display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // width: 100%;
    // height: 100%;
    //
    // background-size: 100px;
    // opacity: .05;
  }

  .page-header {
    max-width: 800px;
  }
}

#our-team {
  position: relative;
  margin-top: -210px;
  z-index: 1;

  .members-grid {
    position: relative;
    padding: 0 3rem;
    background: $white;
    border-radius: 4px;

    @media (min-width: $tablet-break) {
      padding: 3rem 5rem;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      box-shadow: 0 10px 40px rgba($black, .2);
      z-index: -1;
      border-radius: 4px;
    }
  }

  .team-member {
    padding: 2rem 0;
  }

  .team-thumbnail {
    position: relative;
    margin: 0 0 1rem;
    max-width: 350px;

    @media (min-width: $tablet-break) {
      margin: 0;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
      backface-visibility: hidden;

      &.vandalism {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all .1s linear;
      }
    }

    &:hover {
      img.vandalism {
        opacity: 1;
      }
    }
  }
}

.team-bio {
  margin: auto;

  @media (min-width: $tablet-break) {
    padding-left: 5rem;
  }

  h1 {
    margin-bottom: 5px;
  }

  h4 {
    margin-top: 0;
  }

  p {
    margin: 0 0 5px;
  }
}


.featured-grid {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;

  .row {
    justify-content: center;
  }

  .featured-logo {
    padding: 1rem 0;

    @media (min-width: $tablet-break) {
      padding: 2rem;
    }
  }
}


// Faq

.faq-intro {
  &::before {
    background-image: url('/assets/img/bg-maze.svg');
  }
}

#faq-nav {
  ul {
    li {
      a {
        display: block;
        margin: 0 0 5px;
        background: rgba($lightGray, .4);
        color: $darkGray;
        border-radius: 4px;
        padding: 5px 10px;
        line-height: 28px;
        transition: all .2s linear;

        &:hover {
          background: $lightGray;
        }
      }

      &.active {
        a {
          background: $green;
          color: $white;
        }
      }
    }
  }

  @media (min-width: $tablet-break) {
    float: left;

    ul {
      padding: 60px 0;
      width: 280px;
    }

    &.stuck {
      ul {
        position: fixed;
        top: 0;
        padding-top: 120px;
      }
    }

    &.stuck-bottom {
      ul {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.faq-wrapper {
  padding-top: 60px;

  @media (min-width: $tablet-break) {
    margin-left: 350px;
  }
}

.faq {
  padding: 10px 0;

  a.faq-title {
    display: block;
    position: relative;
    margin-bottom: 5px;
    color: $darkGray;
    font-size: 20px;
    line-height: 1.2;
  }

  .faq-content {
    p {
      margin: 0 0 10px;
      color: rgba($gray, .8);

      &:last-child {
        margin: 0;
      }
    }

    .premium--note {
      margin: 0 0 15px;
    }

    // .premium--note p {
    //   color: $green;
    // }

    .premium--note.is-important p {
      color: $errorColor;
    }
  }
}


.webinars-intro {
  &::before {
    background-image: url('/assets/img/bg-etch.svg');
  }
}

.webinars-list {
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 65em;
  justify-content: center;

  .webinar {
    position: relative;
    padding: 2rem 0;
    text-align: center;

    @media (min-width: $tablet-break) {
      padding: 2rem;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      padding: 0;
      background: $white;
      border-radius: 8px;
      box-shadow: 0 0 3rem rgba($black, .1);
      transition: all .2s linear;
    }

    &--content {
      flex: 1;
      padding: 3rem;
    }

    &--actions {
      padding: 3rem;
      border-top: 1px solid $offWhite;
      width: 100%;
      text-align: center;
      line-height: 0;
      transition: all .2s linear;
    }

    &--icon {
      display: block;
      position: relative;
      margin: 0 auto 2rem;
      width: 3em;
      height: 3em;
      background: rgba($gray, .15);
      color: $gray;
      text-align: center;
      font-size: 2em;
      line-height: 3em;
      border-radius: 50%;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1em 0 0 -1em;
      }
    }

    &--date {
      display: inline-block;
      margin-bottom: 1rem;
      padding: .2em .5em;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.2;
      color: $green;
      background: rgba($green, .2);
    }

    h1 {
      margin-top: 0;
      font-size: 2.65em;
      line-height: 1.2;
    }

    h4.webinar--time {
      margin-top: .5em;
      font-size: .875em;
      font-weight: 700;
    }

    span.timezone {
      display: inline-block;
      margin-left: 1.5em;
      padding: 2px 5px;
      border-radius: 4px;
      background: $lightGray;
      font-weight: normal;
      font-size: .65em;
      letter-spacing: .15em;
      color: $white;
    }

    &--desc {
      position: relative;

      p {
        margin: 0;
      }
    }
  }
}


.contact-avenue {
  text-align: center;
  padding: 0 2rem;

  @media (min-width: $tablet-break) {
    padding: 0 4rem;
  }
}

.newsletter-wrapper {
  text-align: center;

  h1 {
    margin-top: 0;
  }
}

.newsletter-signup {
  position: relative;
  max-width: 450px;
  margin: 0 auto 20px;

  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-start;
  }

  .form-item {
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 1em;
    margin-bottom: 1.5rem;

    @media (min-width: 600px) {
      margin-bottom: 0;
    }
  }

  .error-msg {
    position: absolute;
    top: 100%;
    left: 1.25em;
    margin-top: .5em;
    font-size: .75em;
    font-weight: 700;
    color: $errorColor;
    opacity: 0;
    transition: all .2s linear;

    &.show {
      opacity: 1;
    }
  }
}

.job-item {
  margin: 5rem 0;
  border: 2px solid rgba($lightGray, .8);
  border-radius: 4px;
  transition: all .2s linear;

  &:hover {
    border-color: $darkGray;

    .job-title {
      img {
        transform: translate3d(10px,0,0);
      }
    }

    .job-item-callout {
      border-color: $darkGray;
    }
  }

  &:focus {
    border-color: $darkGray;
    outline: none;

    .job-item-callout {
      border-color: $darkGray;
    }
  }

  .job-title {
    padding: 2rem;
    cursor: pointer;

    @media (min-width: $tablet-break) {
      padding: 2rem 3rem;
    }

    img {
      display: inline-block;
      height: 22px;
      margin-top: -5px;
      margin-left: 5px;
      transition: all .2s ease;
    }

    h2 {
      position: relative;

      @media (min-width: $tablet-break) {
        padding-right: 200px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .job-type {
    line-height: 0;
    margin-top: 5px;
    margin-bottom: 20px;

    @media (min-width: $tablet-break) {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
    }

    .chip {
      margin: 0 5px 0 0;

      @media (min-width: $tablet-break) {
        margin: 0 0 0 5px;
      }
    }
  }

  .job-desc {
    padding: 0 2rem;

    @media (min-width: $tablet-break) {
      padding: 0 3rem;
    }

    h3 {
      margin: 3em 0 1em;
      font-size: .875em;
    }

    ul.show-bullets {
      margin-bottom: 30px;
    }
  }

  &-callout {
    position: relative;
    overflow: hidden;
    margin: 3rem -2rem 0;
    padding: 2rem 2rem 1rem;
    text-align: center;
    border-top: 2px solid rgba($lightGray, .8);
    background: rgba($lightGray, .1);
    transition: all .2s linear;

    @media (min-width: $tablet-break) {
      margin: 3rem -3rem 0;
      padding: 2rem 15% 1rem;
    }
  }

  ol {
    margin: 40px;
    li {
      margin: 0 0 10px 0;
    }
  }
}

.front #intercom-container {
  z-index: 9 !important;

  .intercom-launcher-frame,
  .intercom-launcher-discovery-frame {
    bottom: 35px !important;
    right: 25px !important;
  }
}
